import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Holidays } from '../models/holidays.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class HolidaysService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.holidaysEndPoint; //'https://localhost:44325/api/holidays';

    getAll(page: number, search: string, startDate: Date, endDate: Date, orderBy?: Boolean) {
        if (search == undefined) {
            search = "";
        }

        if (orderBy == undefined) {
            orderBy = false;
        }

        var Period = '';
        if (startDate != null && endDate != null) {
            Period += ('&startDate=' + startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + '&' + 'endDate=' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate());
        }
        return this.http.get<ResponseData>(this.URI + "?page=" + page + "&pageSize=" + AppSettings.pageSize + "&search="+ search + Period + '&' + 'orderBy=' + orderBy).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<Holidays>(this.URI + '/' + Id).pipe(
            map(data => new Holidays().deserialize(data))
        );
    }

    add(entHolidays: Holidays) {
        return this.http.post<Holidays>(this.URI, entHolidays);
    }

    update(entHolidays: Holidays) {
        return this.http.put<Holidays>(this.URI + '/' + entHolidays.id, entHolidays);
    }

    delete(Id: number) {
        return this.http.delete<Holidays>(this.URI + '/' + Id);
    }
}