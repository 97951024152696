import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {


  constructor() { }

  exportExcelMonthReport(excelData ) {

    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;
    const dataProj = excelData.dataProject;
    const dataProjHeader = excelData.headersProject;

    let workbook = new Workbook();
    let worksheetProjects = workbook.addWorksheet('Projects');
    let worksheet = workbook.addWorksheet('MonthDetails');

    worksheet.mergeCells('A1', 'F4');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells('G1:H4');
    let d = new Date();
    let date = d.toLocaleDateString('pt-BR');
    let dateCell = worksheet.getCell('G1');
    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    }
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.addRow([]);

    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })


    data.forEach(d => {
      let row = worksheet.addRow(d);

      let sales = row.getCell(1);
      let color = 'FF99FF99';

      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
    }
    );

    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 10;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 80;
    worksheet.addRow([]);

    let headerRowProj = worksheetProjects.addRow(dataProjHeader);
    headerRowProj.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    dataProj.forEach(dd => {
      worksheetProjects.addRow(dd);
    });

    worksheetProjects.getColumn(1).width = 15;
    worksheetProjects.getColumn(2).width = 25;
    worksheetProjects.getColumn(3).width = 35;
    worksheetProjects.getColumn(4).width = 40;
    worksheetProjects.getColumn(5).width = 15;

    //Footer Row
    let footerRow = worksheet.addRow(['Employee Month Report Generated at ' + d.toLocaleString('pt-BR')]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFB050' }
    };

    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    })

  }
}
