import { Deserializable } from './deserializable.model';
import { Client } from './client.model';

export class Project implements Deserializable {
  public id: number;
  public projectName: string;
  public estimatedStartDate: Date;
  public estimatedEndDate: Date;
  public clientId: number;
  public timesheet: any[];
  public active: boolean;
  public startedDate: Date;
  public client: Client;
  public hoursPackage: number;

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    newObj.estimatedStartDate = new Date(Number(this.estimatedStartDate.toString().substr(0, 4)), Number(this.estimatedStartDate.toString().substr(5, 2)) - 1, Number(this.estimatedStartDate.toString().substr(8, 2)));
    newObj.estimatedEndDate = new Date(Number(this.estimatedEndDate.toString().substr(0, 4)), Number(this.estimatedEndDate.toString().substr(5, 2)) - 1, Number(this.estimatedEndDate.toString().substr(8, 2)));
    newObj.startedDate = new Date(Number(this.startedDate.toString().substr(0, 4)), Number(this.startedDate.toString().substr(5, 2)) - 1, Number(this.startedDate.toString().substr(8, 2)));

    return newObj;
  }

}
