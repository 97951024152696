import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

import { Notification } from 'src/app/models/notification.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'hyla-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  title: string = '';

  @Output('menuEvent') menuEvent: EventEmitter<any> = new EventEmitter();

  userId: number;
  notificationBadge: number = 0;

  public lstNotification: Notification[];

  constructor(private router: Router,
    private notificationData: NotificationService) {
    router.events.subscribe(x => {
      this.routerChange();
    });
  }

  ngOnInit(): void {
    this.userId = jwt_decode(localStorage.getItem('currentUser')).userId;

    this.notificationData.getAllByUserId(this.userId).subscribe(response => {
      this.lstNotification = response;
      this.notificationBadge = response.filter(x => x.readDate == undefined).length;

    });
  }

  notificationClosed() {
    if (this.notificationBadge > 0) {
      this.notificationData.updateByUserId(this.userId).subscribe(data => {
        this.notificationData.getAllByUserId(this.userId).subscribe(response => {
          this.lstNotification = response;
          this.notificationBadge = response.filter(x => x.readDate == undefined).length;
  
        });
      });
    }

  }

  menuClick() {
    this.menuEvent.emit();
  }

  routerChange() {
    var param = this.router.url.substr(this.router.url.lastIndexOf('/') + 1, this.router.url.length);

    switch (this.router.url) {
      case '/manager': {
        this.router.navigate(['/manager/dashboard']);
        break;
      }
      case '/manager/dashboard': {
        this.title = 'Dashboard';
        break;
      }
      case '/manager/annualreport': {
        this.title = 'Annual Report';
        break;
      }
      case '/manager/bill': {
        this.title = 'Bill';
        break;
      }
      case '/manager/bill/new': {
        this.title = 'New Bill';
        break;
      }
      case '/manager/bill/edit/' + param: {
        this.title = 'Edit Bill';
        break;
      }
      case '/manager/bulkadd': {
        this.title = 'Bulk Add';
        break;
      }
      case '/manager/customer': {
        this.title = 'Customer';
        break;
      }
      case '/manager/customer/new': {
        this.title = 'New Customer';
        break;
      }
      case '/manager/customer/edit/' + param: {
        this.title = 'Edit Customer';
        break;
      }
      case '/manager/holidays': {
        this.title = 'Holidays';
        break;
      }
      case '/manager/holidays/new': {
        this.title = 'New Holidays';
        break;
      }
      case '/manager/holidays/edit/' + param: {
        this.title = 'Edit Holidays';
        break;
      }
      case '/manager/invoice': {
        this.title = 'Invoice';
        break;
      }
      case '/manager/invoice/new': {
        this.title = 'New Invoice';
        break;
      }
      case '/manager/invoice/edit/' + param: {
        this.title = 'Edit Invoice';
        break;
      }
      case '/manager/location': {
        this.title = 'Location';
        break;
      }
      case '/manager/location/new': {
        this.title = 'New Location';
        break;
      }
      case '/manager/location/edit/' + param: {
        this.title = 'Edit Location';
        break;
      }
      case '/manager/monthreport': {
        this.title = 'Month Report';
        break;
      }
      case '/manager/monthlyclosure': {
        this.title = 'Monthly Closure';
        break;
      }
      case '/manager/mytask': {
        this.title = 'My Tasks';
        break;
      }
      case '/manager/notification': {
        this.title = 'Notification';
        break;
      }
      case '/manager/project': {
        this.title = 'Project';
        break;
      }
      case '/manager/project/new': {
        this.title = 'New Project';
        break;
      }
      case '/manager/project/edit/' + param: {
        this.title = 'Edit Project';
        break;
      }
      case '/manager/project/effortreport/' + param: {
        this.title = 'Effort Report';
        break;
      }
      case '/manager/projectactivities': {
        this.title = 'Project Activities';
        break;
      }
      case '/manager/profile': {
        this.title = 'Profile';
        break;
      }
      case '/manager/purchaseorder': {
        this.title = 'Purchase Order';
        break;
      }
      case '/manager/purchaseorder/new': {
        this.title = 'New Purchase Order';
        break;
      }
      case '/manager/purchaseorder/edit/' + param: {
        this.title = 'Edit Purchase Order';
        break;
      }
      case '/manager/resourceeffort': {
        this.title = 'Resource Effort ';
        break;
      }
      case '/manager/supplier': {
        this.title = 'Supplier';
        break;
      }
      case '/manager/supplier/new': {
        this.title = 'New Supplier';
        break;
      }
      case '/manager/supplier/edit/' + param: {
        this.title = 'Edit Supplier';
        break;
      }
      case '/manager/timesheet': {
        this.title = 'Timesheet';
        break;
      }
      case '/manager/timesheet/new/' + param: {
        this.title = 'New Timesheet';
        break;
      }
      case '/manager/timesheet/edit/' + param: {
        this.title = 'Edit Timesheet';
        break;
      }
      case '/manager/user': {
        this.title = 'User';
        break;
      }
      case '/manager/user/new': {
        this.title = 'New User';
        break;
      }
      case '/manager/user/edit/' + param: {
        this.title = 'Edit User';
        break;
      }
    }
  }

}
