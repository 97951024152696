import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

//Location
import { Location } from '../../../models/location.model';
import { LocationService } from '../../../services/location.service'

// User 
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public validationForm: FormGroup;
  confirmPassword: String;
  userId: any;
  responseError: any;

  constructor(private locationData: LocationService,
    private userData: UserService,
    private router: Router) { }

  // Location Variables
  public lstLocation: Location[];

  // User Variables 
  public entUser: User;

  ngOnInit(): void {
    this.userId = jwt_decode(localStorage.getItem('currentUser')).userId;
    this.getData();
    this.validation();
  }

  getData() {
    this.userData.getById(this.userId).subscribe(response => {
      this.entUser = response;

      this.locationData.getAll(-1, '').subscribe(response => {
        this.lstLocation = response.data.map(data => new Location().deserialize(data));

      });
    });
  }

  validation() {
    this.validationForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(10)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
      birthDate: new FormControl('', [Validators.required]),
      admissionDate: new FormControl('', [Validators.required]),
      locationId: new FormControl('', [Validators.required]),
      workingHours: new FormControl('', [Validators.required])
    });

    this.validationForm.get('name').disable({ onlySelf: true })
    this.validationForm.get('email').disable({ onlySelf: true })
    this.validationForm.get('type').disable({ onlySelf: true })
    this.validationForm.get('role').disable({ onlySelf: true })
    this.validationForm.get('birthDate').disable({ onlySelf: true })
    this.validationForm.get('admissionDate').disable({ onlySelf: true })
    this.validationForm.get('locationId').disable({ onlySelf: true })
    this.validationForm.get('workingHours').disable({ onlySelf: true })
  }

  onKey(event: any) {
    if (this.confirmPassword != this.entUser.password) {
      this.validationForm.get('confirmPassword').setErrors([{ 'PasswordMisMatch': true }]);
    }
  }

  onSubmit() {
    if (this.validationForm.invalid || this.confirmPassword != this.entUser.password) {
      return;
    }

    this.save();
  }

  save() {
    this.responseError = '';
    this.userData.update(this.entUser).subscribe(response => {
      this.router.navigate(['/manager/dashboard']);

    }, error => this.responseError = error.error.message);
  }

}
