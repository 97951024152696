import { Deserializable } from './deserializable.model';


export class Holidays implements Deserializable {
  public id: number;
  public date: Date;
  public name: string;
  public locationId: number;

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
      newObj.date = new Date(Number(this.date.toString().substr(0,4)), Number(this.date.toString().substr(5,2)) -1, Number(this.date.toString().substr(8,2)));
      //newObj.registeredDate = this.registeredDate.toString().substr(8,2)+'/'+this.registeredDate.toString().substr(5,2)+'/'+this.registeredDate.toString().substr(0,4);
      return newObj;
  }
}
