import { Deserializable } from './deserializable.model';

// Issue
import { Issue } from './issue.model';

// User
import { User } from './user.model';

export class History implements Deserializable {
  public id: number;
  public issueId: number;
  public userId: number;
  public date: Date;
  public description: string;
  public issue: Issue[];
  public user: User[];

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
