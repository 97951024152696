<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body" *ngIf="entSupplier">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" [(ngModel)]="entSupplier.name">
                    </mat-form-field>
                </div>
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Fiscal Name</mat-label>
                        <input matInput formControlName="fiscalName" [(ngModel)]="entSupplier.fiscalName">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Tax Number</mat-label>
                        <input matInput formControlName="taxNumber" [(ngModel)]="entSupplier.taxNumber">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Municipal Tax Number</mat-label>
                        <input matInput formControlName="municipalTaxNumber"
                            [(ngModel)]="entSupplier.municipalTaxNumber">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>State Tax Number</mat-label>
                        <input matInput formControlName="stateTaxNumber" [(ngModel)]="entSupplier.stateTaxNumber">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="address" [(ngModel)]="entSupplier.address">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Number</mat-label>
                        <input matInput formControlName="number" [(ngModel)]="entSupplier.number" type="number">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Complement</mat-label>
                        <input matInput formControlName="complement" [(ngModel)]="entSupplier.complement">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput formControlName="city" [(ngModel)]="entSupplier.city">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>State</mat-label>
                        <input matInput formControlName="state" [(ngModel)]="entSupplier.state">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>ZipCode</mat-label>
                        <input matInput formControlName="zipCode" [(ngModel)]="entSupplier.zipCode"
                            (keyup)="zipCodeMask($event)">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" [(ngModel)]="entSupplier.email">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Contact</mat-label>
                        <input matInput formControlName="contact" [(ngModel)]="entSupplier.contact">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4 col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Phone</mat-label>
                        <input matInput formControlName="phone" [(ngModel)]="entSupplier.phone">
                        <mat-icon matSuffix>phone</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Observation</mat-label>
                        <textarea matInput formControlName="observation" [(ngModel)]="entSupplier.observation"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()" *ngIf="userType == 1">Save</button>
                    <button mat-button (click)="cancelClick()">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>