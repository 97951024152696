import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Epic } from "src/app/models/epic.model";
import { EpicService } from "src/app/services/epic.service";

import { Issue } from 'src/app/models/issue.model';

import { Project } from 'src/app/models/project.model';
import { ProjectService } from 'src/app/services/project.service';

import { Client } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-effort-report',
  templateUrl: './effort-report.component.html',
  styleUrls: ['./effort-report.component.scss']
})
export class EffortReportComponent implements OnInit {
  public validationForm: FormGroup;
  modelStartDate;
  modelEndDate;
  lstReport = [];

  constructor(private epicData: EpicService,
    private projectData: ProjectService,
    private clientData: ClientService,
    private route: ActivatedRoute) { }

  public lstEpic: Epic[];

  public entProject: Project;

  public entClient: Client;

  ngOnInit(): void {
    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
    });
  }

  getData() {
    this.lstReport = [];
    this.epicData.getAll(this.route.snapshot.paramMap.get('id'), false, -1, this.modelStartDate, this.modelEndDate).subscribe(response => {

      var epic;
      response.forEach(entEpic => {
        epic = entEpic;
        epic.epic = true;
        this.lstReport.push(epic);
        epic.issue.forEach(entIssue => {
          entIssue.epic = false;
          this.lstReport.push(entIssue);
        });
      });

      this.projectData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entProject = response;

        this.clientData.getById(this.entProject.clientId).subscribe(response => {
          this.entClient = response;

        });
      });
    });
  }

  getStyles() {
    var card = document.getElementById("card");
    document.getElementById("div-card").style.height = card.offsetHeight + 'px';
  }

  epicEffort(type: number, lstIssue: Issue[]) {
    var information;
    let estimatedEffort = 0, actualEffort = 0;

    lstIssue.forEach(entIssue => {
      estimatedEffort += entIssue.effort;
      actualEffort += entIssue.actualEffort;
    });

    if (type == 1) {
      information = estimatedEffort;
    } else if (type == 2) {
      information = actualEffort;
    } else {
      information = this.accuracyCalc(estimatedEffort, actualEffort);
    }

    return information;
  }

  accuracyCalc(estimatedEffort: number, actualEffort: number) {
    let x, y;
    if (estimatedEffort >= actualEffort) {
      x = estimatedEffort;
      y = actualEffort;
    } else {
      y = estimatedEffort;
      x = actualEffort;
    }
    return ((y * 100) / x).toFixed(0);
  }

}
