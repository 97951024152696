import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Sprint } from '../models/sprint.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class SprintService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.sprintEndPoint; //'https://localhost:44325/api/sprint';

    /**
     * Get All By Project Id
     * @param projectId Id of some project that you want to see the sprints
     * @returns List of sprint of the selected project
     */
    getAll(projectId: number) {
        return this.http.get<Sprint[]>(this.URI + '?projectId=' + projectId).pipe(
            map(data => data.map(data => new Sprint().deserialize(data)))
        );
    }

    getById(Id: number) {
        return this.http.get<Sprint>(this.URI + '/' + Id).pipe(
            map(data => new Sprint().deserialize(data))
        );
    }

    add(entSprint: Sprint) {
        return this.http.post<Sprint>(this.URI, entSprint);
    }

    update(entSprint: Sprint) {
        return this.http.put<Sprint>(this.URI + '/' + entSprint.id, entSprint);
    }
    
    updateActualDate(sprintId: number, type: number) {
        return this.http.put<Sprint>(this.URI + '/UpdateActualDate/' + sprintId + '/' + type, '');
    }

    delete(Id: number) {
        return this.http.delete<Sprint>(this.URI + '/' + Id);
    }
}