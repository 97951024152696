<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="locationName" [(ngModel)]="entLocation.locationName">
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="address" [(ngModel)]="entLocation.address">
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Number</mat-label>
                        <input matInput type="number" formControlName="number" [(ngModel)]="entLocation.number">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput formControlName="city" [(ngModel)]="entLocation.city">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>State</mat-label>
                        <input matInput formControlName="state" [(ngModel)]="entLocation.state">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Complement</mat-label>
                        <input matInput formControlName="complement" [(ngModel)]="entLocation.complement">
                    </mat-form-field>
                </div>
                <div class="col col-lg-3 col-md-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Zip-Code</mat-label>
                        <input matInput formControlName="zipCode" [(ngModel)]="entLocation.zipCode" (keyup)="zipCodeMask($event)">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button (click)="onSubmit()" color="primary" style="float: right;" *ngIf="userRole == 1">Save</button>
                    <button mat-button (click)="cancelClick()" style="float: right;">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>