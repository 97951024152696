import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

//Dialog
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HylaDialogComponent } from 'src/app/components/hyla-dialog/hyla-dialog.component'

// Holidays
import { Holidays } from 'src/app/models/holidays.model';
import { HolidaysService } from 'src/app/services/holidays.service';

// Timesheet
import { Timesheet } from 'src/app/models/timesheet.model';
import { TimesheetService } from 'src/app/services/timesheet.service';

// Timesheet Balance
import { TimesheetBalance } from 'src/app/models/timesheetBalance.model';
import { TimesheetBalanceService } from 'src/app/services/timesheetBalance.service';

// User
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

// User Task
import { UserTask } from 'src/app/models/userTask.model';
import { UserTaskService } from 'src/app/services/userTask.service';
import { AppSettings } from 'src/app/utils/settings';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userID: any;
  userType: any;

  // Week Dates
  Sunday: Date;
  Saturday: Date;
  Arr = Array;

  // Hours Type
  NormalHours: number = 0;
  ExtraHours: number = 0;
  WarningHours: number = 0;
  BasketHours: number = 0;
  LicenseHours: number = 0;
  VacationHours: number = 0;

  WorkedHours: number = 0;

  WeekDaysRegisteredHours = [0, 0, 0, 0, 0, 0, 0];
  WeekDaysOtherHours = [0, 0, 0, 0, 0, 0, 0];
  MaxRegisteredHours: number = 0;

  TimesheetView: any[] = [];

  // Projects
  Projects = [];
  ProjectsHours = [];
  entUserTask: any;
  lstWeekHolidays: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private holidaysData: HolidaysService,
    private timesheetData: TimesheetService,
    private timesheetBalanceData: TimesheetBalanceService,
    private userData: UserService,
    private userTaskData: UserTaskService) { }

  // Holidays Variables
  public lstHolidays: Holidays[];
  colNames = [{ prop: 'date', name: 'Date', pipe: 'date' }, { prop: 'date', name: 'Weekday', pipe: 'weekday' }, { prop: 'name', name: 'Holiday Name' }];
  displayedColumns = ['Date', 'Weekday', 'Holiday Name'];

  // Timesheet Variables
  public lstTimesheet: Timesheet[];

  // Timesheet Balance Variables
  public entTimesheetBalance: TimesheetBalance;

  // User Variables
  public entUser: User;

  public lstUserTask: UserTask[];

  // Hours By Type Chart
  canvasHoursByTypeChart: any;
  ctxHoursByTypeChart: any;
  HoursByTypeChart: any;
  HoursByTypeChartConfig: any;

  // Hours By Project Chart
  canvasHoursByProjectChart: any;
  ctxHoursByProjectChart: any;
  HoursByProjectChart: any;
  HoursByProjectChartConfig: any;

  ngOnInit(): void {
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userType == 2) {
      this.router.navigate(['/manager/project']);
    }

    this.userID = jwt_decode(localStorage.getItem('currentUser')).userId;
    this.userData.getById(this.userID).subscribe(response => {
      this.entUser = response;
      this.getData();
    });
  }

  getData() {
    this.entTimesheetBalance = new TimesheetBalance();
    this.lstHolidays = [];
    var date = new Date();
    let diaDaSemana = date.getDay();

    if (diaDaSemana != 0) {
      date.setDate(date.getDate() - diaDaSemana);
    }

    this.Sunday = new Date(date);

    date.setDate(date.getDate() + 6);
    this.Saturday = new Date(date);

    this.timesheetBalanceData.getAll(this.userID, date.getMonth() == 0 ? date.getFullYear() - 1 : date.getFullYear(), date.getMonth() == 0 ? 12 : date.getMonth() - 1).subscribe(response => {
      if (response.length > 0) {
        this.entTimesheetBalance = response[0];
      } else {
        this.entTimesheetBalance.hoursExtraTotal = 0;
      }

      this.holidaysData.getAll(-1, '', this.Sunday, new Date(date.getFullYear(), 11, 31), true).subscribe(response => {
        this.lstHolidays = response.data.map(data => new Holidays().deserialize(data)).filter(x => x.locationId == 0 || x.locationId == this.entUser.locationId);
        var holidaysSort = [];
        var holidays = this.lstHolidays.length > 5 ? 5 : this.lstHolidays.length;

        for (var i = 0; i < holidays; i++) {
          holidaysSort.push(response.data.map(data => new Holidays().deserialize(data)).filter(x => x.locationId == 0 || x.locationId == this.entUser.locationId)[i])
        }

        this.lstHolidays = holidaysSort;//.sort((a, b) => b.date - a.date);
        this.lstWeekHolidays = this.lstHolidays.filter(x => (x.date > this.Sunday) && (x.date < this.Saturday));
        // console.log(this.lstHolidays, this.Sunday, this.Saturday);
        // console.log(this.lstHolidays.filter(x => (x.date.getDate() > this.Sunday.getDate() && x.date.getMonth() >= this.Sunday.getMonth()) && (x.date.getDate() < this.Saturday.getDate() && x.date.getMonth() <= this.Saturday.getMonth())));
        // console.log(this.lstHolidays.filter(x => (x.date > this.Sunday) && (x.date < this.Saturday)));

        //this.timesheetData.getAll(-1, '', this.Sunday, this.Saturday, 0).subscribe(response => {
        this.timesheetData.getAll(-1, '', new Date(this.Sunday.getFullYear(), this.Sunday.getMonth(), 1), this.Saturday, 0).subscribe(response => {
          this.lstTimesheet = response.data.map(data => new Timesheet().deserialize(data));

          this.loadTaskList();
          this.SumOfHours();

          for (var i = 0; i < 7; i++) {
            var date = new Date(this.Sunday);
            date.setDate(date.getDate() + i);
            this.TimesheetView[i] = this.lstTimesheet.filter(x => x.registeredDate.getMonth() == date.getMonth() && x.registeredDate.getDate() == date.getDate());
          }

          // Update Hours By Type Chart
          var lstHours = [{ hourType: 'Normal', value: this.NormalHours }, { hourType: 'Extra', value: this.ExtraHours }, { hourType: 'Warning', value: this.WarningHours }, { hourType: 'Basket', value: this.BasketHours }, { hourType: 'License', value: this.LicenseHours }, { hourType: 'Vacation', value: this.VacationHours }];
          for (var i = 0; i < lstHours.length; i++) {
            if (lstHours[i].value > 0) {
              this.HoursByTypeChartConfig.data.labels.push(lstHours[i].hourType);
              this.HoursByTypeChartConfig.data.datasets[0].data.push(lstHours[i].value);
            }
          }

          this.HoursByTypeChart.update();

          // Update Hours By Project Chart
          for (i = 0; i < this.Projects.length; i++) {
            this.HoursByProjectChartConfig.data.labels.push(this.Projects[i]);
            this.HoursByProjectChartConfig.data.datasets.forEach((dataset) => {
              dataset.data.push(this.ProjectsHours[i]);
            });
          }

          this.HoursByProjectChart.update();

        });
      });
    });

  }

  TimesheetOverView(type: number, weekDay: number) {
    var information = '';
    var date = new Date(this.Sunday)
    date.setDate(date.getDate() + weekDay);
    var registeredHours = 0;
    this.lstTimesheet.filter(x => x.registeredDate.getMonth() == date.getMonth() && x.registeredDate.getDate() == date.getDate()).forEach(entTimesheet => {
      registeredHours += entTimesheet.registeredHours;
    });

    if (this.lstHolidays.filter(x => x.date.getDate() == date.getDate() && x.date.getMonth() == date.getMonth()).length == 0) {
      if (type == 1) {
        information = registeredHours + '/' + (this.entUser.workingHours / 5);
      } else if (date.getDay() > 0 && date.getDay() < 6 && (date.getMonth() < new Date().getMonth() || (date.getMonth() == new Date().getMonth() && date.getDate() < new Date().getDate())) && registeredHours < (this.entUser.workingHours / 5)) {
        information = "font-red";
      }
    } else {
      information = "Holiday"
    }

    return information;
  }

  redirectAddTimesheet(weekDay: number) {
    var currentWeekDay = new Date().getDay();
    var date = new Date();
    date.setDate(date.getDate() + (weekDay - currentWeekDay));
    date.setHours(0, 0, 0, 0);

    this.router.navigate(['/manager/timesheet/new/' + date], { queryParams: { returnUrl: '/manager/dashboard' } });
  }

  redirectEditTimesheet(id: number) {
    this.router.navigate(['/manager/timesheet/edit/' + id], { queryParams: { returnUrl: '/manager/dashboard' } });
  }

  lastDayMonth(date: Date) {
    var data = date;
    var Month = data.getMonth();

    while (data.getMonth() == Month) {
      data.setDate(data.getDate() + 1);
    }

    data.setDate(data.getDate() - 1);

    return data;
  }

  SumOfHours() {
    this.lstTimesheet.forEach(entTimesheet => {
      if (entTimesheet.registeredDate >= this.Sunday) {
        switch (entTimesheet.hourType) {
          case 1: {
            this.NormalHours += entTimesheet.registeredHours;
            this.WorkedHours += entTimesheet.registeredHours;
            this.WeekDaysRegisteredHours[entTimesheet.registeredDate.getDay()] += entTimesheet.registeredHours;
            break;
          }
          case 2: {
            this.ExtraHours += entTimesheet.registeredHours;
            this.WeekDaysOtherHours[entTimesheet.registeredDate.getDay()] += entTimesheet.registeredHours;

            this.entTimesheetBalance.hoursExtraTotal += entTimesheet.registeredHours;
            break;
          }
          case 3: {
            this.WarningHours += entTimesheet.registeredHours;
            this.WeekDaysOtherHours[entTimesheet.registeredDate.getDay()] += entTimesheet.registeredHours;
            break;
          }
          case 4: {
            this.BasketHours += entTimesheet.registeredHours;
            this.WorkedHours += entTimesheet.registeredHours;
            this.WeekDaysRegisteredHours[entTimesheet.registeredDate.getDay()] += entTimesheet.registeredHours;

            this.entTimesheetBalance.hoursExtraTotal -= entTimesheet.registeredHours;
            break;
          }
          case 5: {
            this.LicenseHours += entTimesheet.registeredHours;
            this.WorkedHours += entTimesheet.registeredHours;
            this.WeekDaysRegisteredHours[entTimesheet.registeredDate.getDay()] += entTimesheet.registeredHours;
            break;
          }
          case 6: {
            this.VacationHours += entTimesheet.registeredHours;
            this.WorkedHours += entTimesheet.registeredHours;
            this.WeekDaysRegisteredHours[entTimesheet.registeredDate.getDay()] += entTimesheet.registeredHours;
            break;
          }
        }

        if (this.WeekDaysRegisteredHours[entTimesheet.registeredDate.getDay()] > this.MaxRegisteredHours) {
          this.MaxRegisteredHours = this.WeekDaysRegisteredHours[entTimesheet.registeredDate.getDay()] + 1;
        }

        this.AddProjects(entTimesheet.project.projectName, entTimesheet.registeredHours);
      }
      else {
        switch (entTimesheet.hourType) {
          case 2: {
            this.entTimesheetBalance.hoursExtraTotal += entTimesheet.registeredHours;
            break;
          }
          case 4: {
            this.entTimesheetBalance.hoursExtraTotal -= entTimesheet.registeredHours;
            break;
          }
        }
      }


    });
  }

  AddProjects(project: string, hours: number) {
    var indice = -1;

    indice = this.Projects.indexOf(project);

    if (indice < 0) {
      this.Projects.push(project);
      indice = (this.Projects.length - 1);
    }

    this.AddProjectsHours(indice, hours);
    return indice;
  }

  AddProjectsHours(indice: number, hours: number) {
    if (this.ProjectsHours[indice] === undefined) {
      this.ProjectsHours.push(0);
    }

    this.ProjectsHours[indice] += hours;
  }

  round(value: number) {
    return Math.round(value);
  }

  taskDetail(task?: UserTask) {
    if (task == undefined) {
      task = new UserTask();
      task.userId = this.userID
    }


    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        userTask: true,
        entUserTask: JSON.parse(JSON.stringify(task)),
        saveButton: 'Save',
        cancelButton: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe((entUserTask: any) => {
      // entEpic.projectId = this.modelProject;
      if (entUserTask != '' && entUserTask != undefined) {
        if (entUserTask.id > 0) {
          this.userTaskData.update(entUserTask).subscribe(data => {
            this.loadTaskList();
          }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
        } else {
          this.userTaskData.add(entUserTask).subscribe(data => {
            this.loadTaskList();
          }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
        }
      }
    });
  }

  loadTaskList() {
    this.userTaskData.getAllByUserId(this.userID).subscribe(response => {
      this.lstUserTask = response.data.map(data => new UserTask().deserialize(data));
    });
  }

  taskDone(taskId: number) {
    this.userTaskData.updateStatus(taskId).subscribe(data => {
      var index = this.lstUserTask.indexOf(this.lstUserTask.find(x => x.id == taskId));
      this.lstUserTask.splice(index, 1);
    });
  }

  tag(date: Date) {
    var backgroundColor = null;
    const diffDays = this.tooltip(date);


    if (diffDays > 0) {
      backgroundColor = 'bg-primary-green';
    } else if (diffDays == 0) {
      backgroundColor = 'bg-orange';
    } else if (diffDays < 0) {
      backgroundColor = 'bg-red';
    }

    return backgroundColor
  }

  tooltip(date: Date) {
    var currentDate = new Date();
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const diffDays = Math.ceil((date.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  ngAfterViewInit() {
    // Start Hours By Type Chart
    this.canvasHoursByTypeChart = document.getElementById('HoursByTypeChart');
    this.ctxHoursByTypeChart = this.canvasHoursByTypeChart.getContext('2d');

    this.HoursByTypeChartConfig = {
      type: 'pie',
      plugins: [ChartDataLabels],
      data: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524'],
          borderColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524'],
          datalabels: {
            color: 'white'
          }
        }]
      },
      options: {
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'start',
            font: {
              size: 12,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        legend: {
          position: 'right',
          labels: {
            fontColor: "black",
            boxWidth: 6,
            padding: 15,
            usePointStyle: true
          }
        },
      }
    };

    this.HoursByTypeChart = new Chart(this.ctxHoursByTypeChart, this.HoursByTypeChartConfig);
    // End Hours By Type Chart

    // Start Hours By Project Chart
    this.canvasHoursByProjectChart = document.getElementById('HoursByProjectChart');
    this.ctxHoursByProjectChart = this.canvasHoursByProjectChart.getContext('2d');

    this.HoursByProjectChartConfig = {
      type: 'pie',
      plugins: [ChartDataLabels],
      data: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524'],
          borderColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524'],
          datalabels: {
            color: 'white'
          }
        }]
      },
      options: {
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'start',
            font: {
              size: 12,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        legend: {
          position: 'right',
          labels: {
            fontColor: "black",
            boxWidth: 6,
            padding: 15,
            usePointStyle: true
          }
        },
      }
    };

    this.HoursByProjectChart = new Chart(this.ctxHoursByProjectChart, this.HoursByProjectChartConfig);
    // End Hours By Project Chart
  }

}
