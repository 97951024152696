import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { UserTask } from '../models/userTask.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class UserTaskService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.userTaskEndPoint; //'https://localhost:44325/api/usertask';

    getAll() {
        return this.http.get<UserTask[]>(this.URI).pipe(
            map(data => data.map(data => new UserTask().deserialize(data)))
        );
    }

    getAllByUserId(userId: number, page?: number, search?: string, done?: number) {
        if (page == null || page == undefined) { page = -1 };
        if (search == null || search == undefined) { search = '' };
        if (done == null || done == undefined) { done = -1 };

        return this.http.get<ResponseData>(this.URI + '/GetByUser/' + userId + '?page=' + page + "&pageSize=" + AppSettings.pageSize + '&search=' + search + '&done=' + done).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<UserTask>(this.URI + '/' + Id).pipe(
            map(data => new UserTask().deserialize(data))
        );
    }

    add(entUserTask: UserTask) {
        return this.http.post<UserTask>(this.URI, entUserTask);
    }

    update(entUserTask: UserTask) {
        return this.http.put<UserTask>(this.URI + '/' + entUserTask.id, entUserTask);
    }

    updateStatus(id: number) {
        return this.http.put<UserTask>(this.URI + '/UpdateStatus/' + id, '');
    }

    delete(Id: number) {
        return this.http.delete<UserTask>(this.URI + '/' + Id);
    }
}