import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Timesheet } from '../models/timesheet.model';

import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class TimesheetService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.timesheetEndPoint; //'https://localhost:44325/api/timesheet';

    getAll(page: number, search: string, startDate: Date, endDate: Date, getAllTimesheet: number, projectId?: number, userId?: number) {
        var Period = '';

        if (search == undefined) {
            search = "";
        } else {
            search = search.trim();
        }

        if (projectId == undefined) { projectId = 0 }
        if (userId == undefined) { userId = 0 }

        if (startDate != null && endDate != null && getAllTimesheet != null) {
            Period = ('&startDate=' + startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + '&' + 'endDate=' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate() + '&' + 'getAllTimesheet=' + getAllTimesheet);
        } else if (getAllTimesheet != null && startDate == null && endDate == null) {
            Period = ('&getAllTimesheet=' + getAllTimesheet);
        } else {
            Period = ('&startDate=' + startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + '&' + 'endDate=' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate());
        }

        return this.http.get<ResponseData>(this.URI + '?page=' + page + '&pageSize=' + AppSettings.pageSize + '&search=' + search + Period + '&projectId=' + projectId + '&userId=' + userId).pipe(
            //map(data => data.map(data => new ResponseData().deserialize(data)))
            map(data => new ResponseData().deserialize(data))
        );
    }

    getAllByProjectUser(projectId: number, userId: number, startDate: Date, endDate: Date) {
        return this.http.get<Timesheet[]>(this.URI + '/GetAllByProjectUser?projectId=' + projectId + '&userId=' + userId + '&startDate=' + startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + '&' + 'endDate=' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate()).pipe(
            map(data => data.map(data => new Timesheet().deserialize(data))))
    }

    getById(Id: number) {
        return this.http.get<Timesheet>(this.URI + '/' + Id).pipe(
            map(data => new Timesheet().deserialize(data))
        );
    }

    add(entTimesheet: Timesheet) {
        return this.http.post<Timesheet>(this.URI, entTimesheet);
    }

    update(entTimesheet: Timesheet) {
        return this.http.put<Timesheet>(this.URI + '/' + entTimesheet.id, entTimesheet);
    }

    delete(Id: number) {
        return this.http.delete<Timesheet>(this.URI + '/' + Id);
    }
}
