import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Common Template
import { CommonComponent } from './template/common/common.component';

// No Template
import { NoTemplateComponent } from './template/no-template/no-template.component';

// Login
import { LoginComponent } from './pages/login/login.component';

// Annual Report
import { AnnualReportComponent } from './pages/annual-report/annual-report.component';

// Annual Report
import { BulkAddComponent } from './pages/bulk-add/bulk-add.component';

// Bill
import { BillListComponent } from './pages/bill/bill-list/bill-list.component';
import { BillDetailComponent } from './pages/bill/bill-detail/bill-detail.component';

// Customer
import { CustomerListComponent } from './pages/customer/customer-list/customer-list.component';
import { CustomerDetailComponent } from './pages/customer/customer-detail/customer-detail.component';

// Dashboard
import { DashboardComponent } from './pages/dashboard/dashboard.component';

// Holidays
import { HolidaysListComponent } from './pages/holidays/holidays-list/holidays-list.component';
import { HolidaysDetailComponent } from './pages/holidays/holidays-detail/holidays-detail.component';

// Invoice
import { InvoiceListComponent } from './pages/invoice/invoice-list/invoice-list.component';
import { InvoiceDetailComponent } from './pages/invoice/invoice-detail/invoice-detail.component';

// Location
import { LocationListComponent } from './pages/location/location-list/location-list.component';
import { LocationDetailComponent } from './pages/location/location-detail/location-detail.component';

// Month Report
import { MonthReportComponent } from './pages/month-report/month-report.component';

// Monthly Closure
import { MonthlyClosureComponent } from './pages/monthly-closure/monthly-closure.component';

// Project
import { ProjectListComponent } from './pages/project/project-list/project-list.component';
import { ProjectDetailComponent } from './pages/project/project-detail/project-detail.component';
import { ProjectActivitiesComponent } from './pages/project/project-activities/project-activities.component';
import { EffortReportComponent } from './pages/project/effort-report/effort-report.component';

// Purchase Order
import { PurchaseOrderListComponent } from './pages/purchase-order/purchase-order-list/purchase-order-list.component';
import { PurchaseOrderDetailComponent } from './pages/purchase-order/purchase-order-detail/purchase-order-detail.component';

// Profile
import { ProfileComponent } from './pages/user/profile/profile.component';

// Resource Effort
import { ResourceEffortComponent } from './pages/project/resource-effort/resource-effort.component';

// Supplier
import { SupplierListComponent } from './pages/supplier/supplier-list/supplier-list.component';
import { SupplierDetailComponent } from './pages/supplier/supplier-detail/supplier-detail.component';

// Timesheet
import { TimesheetListComponent } from './pages/timesheet/timesheet-list/timesheet-list.component';
import { TimesheetDetailComponent } from './pages/timesheet/timesheet-detail/timesheet-detail.component';

// User
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { UserDetailComponent } from './pages/user/user-detail/user-detail.component';

import { AuthGuard } from './helpers/auth.guard';
import { NotificationDetailComponent } from './pages/notification/notification-detail/notification-detail.component';
import { MyTaskListComponent } from './pages/my-task/my-task-list/my-task-list.component';
import { MyTaskDetailComponent } from './pages/my-task/my-task-detail/my-task-detail.component';

const routes: Routes = [
  { path: '', component: NoTemplateComponent },
  {
    path: 'manager',
    component: CommonComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'annualreport',
        component: AnnualReportComponent
      },
      {
        path: 'bill',
        component: BillListComponent
      },
      {
        path: 'bill/new',
        component: BillDetailComponent
      },
      {
        path: 'bill/edit/:id',
        component: BillDetailComponent
      },
      {
        path: 'bulkadd',
        component: BulkAddComponent
      },
      {
        path: 'customer',
        component: CustomerListComponent
      },
      {
        path: 'customer/new',
        component: CustomerDetailComponent
      },
      {
        path: 'customer/edit/:id',
        component: CustomerDetailComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'holidays',
        component: HolidaysListComponent
      },
      {
        path: 'holidays/new',
        component: HolidaysDetailComponent
      },
      {
        path: 'holidays/edit/:id',
        component: HolidaysDetailComponent
      },
      {
        path: 'invoice',
        component: InvoiceListComponent
      },
      {
        path: 'invoice/new',
        component: InvoiceDetailComponent
      },
      {
        path: 'invoice/edit/:id',
        component: InvoiceDetailComponent
      },
      {
        path: 'location',
        component: LocationListComponent
      },
      {
        path: 'location/new',
        component: LocationDetailComponent
      },
      {
        path: 'location/edit/:id',
        component: LocationDetailComponent
      },
      {
        path: 'monthreport',
        component: MonthReportComponent
      },
      {
        path: 'monthlyclosure',
        component: MonthlyClosureComponent
      },
      {
        path: 'mytask',
        component: MyTaskListComponent
      },
      {
        path: 'mytask/new',
        component: MyTaskDetailComponent
      },
      {
        path: 'mytask/edit/:id',
        component: MyTaskDetailComponent
      },
      {
        path: 'notification',
        component: NotificationDetailComponent
      },
      {
        path: 'project',
        component: ProjectListComponent
      },
      {
        path: 'project/new',
        component: ProjectDetailComponent
      },
      {
        path: 'project/edit/:id',
        component: ProjectDetailComponent
      },
      {
        path: 'project/effortreport/:id',
        component: EffortReportComponent
      },
      {
        path: 'projectactivities',
        component: ProjectActivitiesComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'purchaseorder',
        component: PurchaseOrderListComponent
      },
      {
        path: 'purchaseorder/new',
        component: PurchaseOrderDetailComponent
      },
      {
        path: 'purchaseorder/edit/:id',
        component: PurchaseOrderDetailComponent
      },
      {
        path: 'resourceeffort',
        component: ResourceEffortComponent
      },
      {
        path: 'supplier',
        component: SupplierListComponent
      },
      {
        path: 'supplier/new',
        component: SupplierDetailComponent
      },
      {
        path: 'supplier/edit/:id',
        component: SupplierDetailComponent
      },
      {
        path: 'timesheet',
        component: TimesheetListComponent
      },
      {
        path: 'timesheet/new/:date',
        component: TimesheetDetailComponent
      },
      {
        path: 'timesheet/edit/:id',
        component: TimesheetDetailComponent
      },
      {
        path: 'user',
        component: UserListComponent
      },
      {
        path: 'user/new',
        component: UserDetailComponent
      },
      {
        path: 'user/edit/:id',
        component: UserDetailComponent
      }
    ]
  },
  {
    path: 'manager',
    component: NoTemplateComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }