import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'activeStatus' })
export class ActiveStatusPipe implements PipeTransform {
    transform(value: number): string {
        var information;
        switch (value) {
            case 0: {
                information = 'To Do';
                break;
            }
            case 1: {
                information = 'Doing';
                break;
            }
            case 2: {
                information = 'Review';
                break;
            }
            case 3: {
                information = 'Redo';
                break;
            }
            case 4: {
                information = 'Done';
                break;
            }
        }

        return information;
    }
}