<div class="row">
    <div class="col col-md-2">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Emploeey</mat-label>
            <mat-select [(value)]="modelUser" [disabled]="userRole != 1 && userRole != 2">
                <mat-option *ngFor="let entUser of lstUser" [value]="entUser.id">{{ entUser.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-2 col-sm-8">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Year</mat-label>
            <mat-select [(value)]="modelYear">
                <mat-option *ngFor="let year of year" [value]="year">{{ year }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-4">
        <button mat-flat-button color="primary" class="button" (click)="getData()">Search</button>
    </div>
</div>
<div class="row">
    <div class="col col-md-2" *ngFor="let month of lstMonths">
        <mat-card class="card-header">
            <h3>{{ month | month }}</h3>
            <label><small (click)="openDialog(month)">View</small></label>
        </mat-card>
        <mat-card class="card-body" style="padding: 0px">
            <table>
                <tbody>
                    <tr>
                        <th>Previous Extra Hours</th>
                        <td colspan="2">{{ extraHourInformation(1, month) }}</td>
                    </tr>
                    <tr>
                        <th>Normal Hours:</th>
                        <td>{{ gridInformation(1, month) }}</td>
                        <td rowspan="4">{{ gridInformation(7, month) }}</td>
                    </tr>
                    <tr>
                        <th>Basket Hours:</th>
                        <td>{{ gridInformation(4, month) }}</td>
                    </tr>
                    <tr>
                        <th>License Hours:</th>
                        <td>{{ gridInformation(5, month) }}</td>
                    </tr>
                    <tr>
                        <th>Vacation Hours:</th>
                        <td>{{ gridInformation(6, month) }}</td>
                    </tr>
                    <tr>
                        <th>Extra Hours:</th>
                        <td colspan="2">{{ gridInformation(2, month) }}</td>
                    </tr>
                    <tr>
                        <th>Warning Hours:</th>
                        <td colspan="2">{{ gridInformation(3, month) }}</td>
                    </tr>
                    <tr>
                        <th>Total Extra Hours</th>
                        <td colspan="2">{{ extraHourInformation(2, month) }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card>
    </div>
</div>