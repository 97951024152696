import { HttpEventType } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Chart from 'chart.js';

// Dialog
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

// App Settings
import { AppSettings } from 'src/app/utils/settings';

// Epic
import { Epic } from 'src/app/models/epic.model';

// Issue
import { Issue } from 'src/app/models/issue.model';

// History
import { History } from 'src/app/models/history.model';

// Project Users
import { ProjectUsers } from 'src/app/models/projectUsers.model';

// Sprint
import { Sprint } from 'src/app/models/sprint.model';

// File Storage
import { FileStorageService } from 'src/app/services/fileStorage.service';
import { FileStorage } from 'src/app/models/fileStorage.model';

// File Management
import { FileManagementService } from 'src/app/services/fileManagement.service';

// User Task
import { UserTask } from 'src/app/models/userTask.model';

@Component({
  selector: 'app-hyla-dialog',
  templateUrl: './hyla-dialog.component.html',
  styleUrls: ['./hyla-dialog.component.scss']
})
export class HylaDialogComponent {
  public validationForm: FormGroup;
  responseError: string;

  title: string = "";
  message: string = "";
  saveButton: string = "";
  cancelButton: string = "";

  table: boolean = false;

  receipt: boolean = false;
  entReceipt: any;
  dataSource: any[] = [];
  lstColName: any[] = [];

  epic: boolean = false;
  entEpic: Epic = new Epic();

  issue: boolean = false;
  entIssue: Issue = new Issue();
  lstProjectUsers: ProjectUsers[];
  lstSprints: Sprint[];
  issueHistory: History[];
  initialEffort: number = 0;

  // chart
  chart: boolean = false;
  canvasMyChart: any;
  ctxMyChart: any;
  myChart: any;
  myChartConfig: any;

  userTask: boolean = false;
  entUserTask: UserTask = new UserTask();
  entUserTaskValidation: UserTask = new UserTask();

  fileStorageData: FileStorageService;
  entFileStorage: FileStorage;
  file: any;
  lstNewFileStorage: FileStorage[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialog: MatDialog,
    private fileManagementData: FileManagementService,
    private dialogRef: MatDialogRef<HylaDialogComponent>) {
    if (data) {
      this.title = data.title || this.title;
      this.message = data.message || this.message;
      this.saveButton = data.saveButton || this.saveButton;
      this.cancelButton = data.cancelButton || this.cancelButton;
      this.table = data.table || this.table;
      this.receipt = data.receipt || this.receipt;
      this.entReceipt = data.entReceipt || this.entReceipt;
      this.dataSource = data.dataSource || this.dataSource;
      this.epic = data.epic || this.epic;
      this.entEpic = data.entEpic || this.entEpic;
      this.issue = data.issue || this.issue;
      this.entIssue = data.entIssue || this.entIssue;
      this.userTask = data.userTask || this.userTask;
      this.entUserTask = data.entUserTask || this.entUserTask;
      this.entUserTaskValidation = data.entUserTask != undefined ? JSON.parse(JSON.stringify(data.entUserTask)) : undefined || this.entUserTaskValidation
      this.lstProjectUsers = data.projectUsers || this.lstProjectUsers;
      this.chart = data.chart || this.chart;
      this.myChartConfig = data.myChartConfig || this.myChartConfig;
      this.lstSprints = data.sprints || this.lstSprints;
      this.issueHistory = data.issueHistory || this.issueHistory;
      this.fileStorageData = data.fileStorageData || this.fileStorageData

      if (this.epic) {
        if (this.entEpic.id > 0) {
          this.title = 'Edit Epic';
        } else {
          this.title = 'New Epic';
          this.entEpic.id = 0;
          this.entEpic.status = 0;
        }

        this.validators(0);
      } else if (this.issue) {
        if (this.entIssue.id > 0) {
          this.title = 'Edit Issue';
          if (this.issueHistory.length > 0) {
            this.issueHistory.forEach(entHistory => {
              entHistory.description = entHistory.description.replace(/\n/g, "<br />")
            });
          }
          this.initialEffort = this.entIssue.effort;
        } else {
          this.title = 'New Issue';
          this.entIssue.id = 0;
          this.entIssue.status = 0;
        }

        this.validators(1);
      } else if (this.userTask) {
        if (this.entUserTask.id > 0) {
          this.title = 'Edit Task';
        } else {
          this.title = 'New Task';
          this.entUserTask.id = 0;
          this.entUserTask.done = false;
        }

        this.validators(2);
      }
    }
  }

  validators(type: number) {
    if (type == 0) {
      this.validationForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        description: new FormControl('', null),
        startDate: new FormControl('', null),
        endDate: new FormControl('', null),
        status: new FormControl('', null),
      });
    } else if (type == 1) {
      this.validationForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        description: new FormControl('', null),
        priority: new FormControl('', [Validators.required]),
        effort: new FormControl('', [Validators.required]),
        actualEffort: new FormControl('', null),
        startDate: new FormControl('', null),
        endDate: new FormControl('', null),
        status: new FormControl('', null),
        responsible: new FormControl('', null),
        sprint: new FormControl('', null)
      });

      if (this.entIssue.status != 0) {
        this.validationForm.get('effort').disable({ onlySelf: true });
        this.validationForm.get('sprint').disable({ onlySelf: true });
      }

      if (this.entIssue.sprintId != null && this.lstSprints.find(x => x.id == this.entIssue.sprintId).actualEndDate != null) {
        this.validationForm.get('sprint').disable({ onlySelf: true });
      }

      this.validationForm.get('actualEffort').disable({ onlySelf: true });
    } else if (type == 2) {
      this.validationForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        description: new FormControl('', null),
        date: new FormControl('', null),
        done: new FormControl('', null)
      });
    }
  }

  viewFile(Id: number) {
    AppSettings.viewFileById(this.fileStorageData, HttpEventType, Id);
  }

  openDeleteIssueDialog(entFileStorage: FileStorage) {
    // this.entIssue = this.lstEpic.find(x => x.id == parentId).issue.find(x => x.id == Id);

    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        title: 'Confirm deletion',
        message: 'Do you want to delete the issue ' + entFileStorage.fileName + '?',
        saveButton: 'Yes',
        cancelButton: 'No'
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      // entEpic.projectId = this.modelProject;
      if (confirmed) {

        if (this.lstNewFileStorage.find(x => x == entFileStorage) != undefined) {
          this.dataSource.splice(this.dataSource.indexOf(entFileStorage), 1);
        } else {
          this.fileStorageData.delete(entFileStorage.id).subscribe(data => {
            this.dataSource.splice(this.dataSource.indexOf(entFileStorage), 1);
          });
        }
      }
    });
  }

  upload(files) {
    if (files.length === 0)
      return;

    if (files[0].type != 'application/pdf') {
      this.responseError = 'Only pdf is allowed';
      return;
    } else {
      this.responseError = '';
    }

    this.entFileStorage = new FileStorage();
    this.entFileStorage.registerType = 3;
    this.entFileStorage.referenceId = this.entIssue.id;
    this.entFileStorage.fileName = files[0].name;
    this.entFileStorage.fileMime = files[0].type;

    this.dataSource.push(this.entFileStorage);
    this.lstNewFileStorage.push(this.entFileStorage);

    this.file = files;
  }

  onConfirmClick(): void {
    var information;
    var validation = true;
    this.responseError = '';
    var currentDate = new Date();
    var issue;

    if (this.epic || this.issue || this.userTask) {
      if (this.validationForm.invalid) {
        validation = false;
      }
      if (this.issue) {
        issue = this.entIssue;
        issue.plannedEffortDifference = this.entIssue.effort - this.initialEffort;

        if (issue.sprintId == 0) {
          issue.sprintId = null;
          issue.plannedEffortDifference = -this.entIssue.effort;
        }

        if (!Number.isInteger(this.entIssue.effort / 0.5)) {
          this.responseError = "It is not possible to register this fraction of hour. The only fraction accepted is .5";
          validation = false;
        }

        for (let entFileStorage of this.lstNewFileStorage) {
          this.fileStorageData.add(entFileStorage).subscribe(data => {
            const formData = new FormData();

            for (let file of this.file)
              formData.append(file.name, file);

            this.fileManagementData.add(data.id, formData).subscribe(data => {
            });
          });
        }
      } else if ((this.userTask && this.entUserTask.id == 0 && this.entUserTask.dueDate != undefined && this.entUserTask.dueDate < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) || (this.userTask && this.entUserTask.id > 0 && this.entUserTask.dueDate != undefined && this.entUserTask.dueDate != this.entUserTaskValidation.dueDate && this.entUserTask.dueDate < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))) {
        validation = false;
        this.responseError = "The due date cannot be less than the current date."
      }

      information = this.epic ? this.entEpic : this.issue ? issue : this.entUserTask;
    } else {
      information = true;
    }

    if (validation) {
      this.dialogRef.close(information);
    }
  }

  ngAfterViewInit() {
    // Start Hours By Type Chart
    this.canvasMyChart = document.getElementById('myChart');
    this.ctxMyChart = this.canvasMyChart.getContext('2d');

    this.myChart = new Chart(this.ctxMyChart, this.myChartConfig);
    // End Hours By Type Chart
  }
}
