<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Title</mat-label>
                        <input matInput formControlName="title" [(ngModel)]="entNotification.title">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="type" [(ngModel)]="modelType" (selectionChange)="onTypeChange()">
                            <mat-option [value]="0">Location</mat-option>
                            <mat-option [value]="1">Project</mat-option>
                            <mat-option [value]="2">Custom</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>{{ parentTitle }}</mat-label>
                        <mat-select formControlName="parent" [(ngModel)]="modelParent"
                            [disabled]="modelType == undefined" *ngIf="modelType != 2">
                            <mat-option *ngIf="modelType == 0" [value]="0">All</mat-option>
                            <mat-option *ngFor="let entModelParent of lstModelParent" [value]="entModelParent.id">
                                {{ modelType == 0 ? entModelParent.locationName : modelType == 1 ?
                                entModelParent.projectName : entModelParent.name }}</mat-option>
                        </mat-select>
                        <mat-select formControlName="parent" [(ngModel)]="modelParentList"
                            [disabled]="modelType == undefined" *ngIf="modelType == 2" multiple>
                            <mat-option *ngFor="let entModelParent of lstModelParent" [value]="entModelParent.id">
                                {{ entModelParent.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description"
                            [(ngModel)]="entNotification.description"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()">Save</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>