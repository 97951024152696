import { Deserializable } from './deserializable.model';
import { Project } from './project.model';
import { User } from './user.model';

// export class Timesheet.Model {
// }

export class Timesheet implements Deserializable {
  public id: number;
  public userId: number;
  public projectId: number;
  public registeredDate: Date;
  public registeredHours: number;
  public activityType: number;
  public hourType: number;
  public comment: string;
  public project: Project;
  public user: User;

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    newObj.registeredDate = new Date(Number(this.registeredDate.toString().substr(0, 4)), Number(this.registeredDate.toString().substr(5, 2)) - 1, Number(this.registeredDate.toString().substr(8, 2)));
    //newObj.registeredDate = this.registeredDate.toString().substr(8,2)+'/'+this.registeredDate.toString().substr(5,2)+'/'+this.registeredDate.toString().substr(0,4);
    return newObj;
  }
}
