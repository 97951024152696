import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Bill } from '../models/bill.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class BillService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.billEndPoint; //'https://localhost:44325/api/bill';

    getAll(page: number, search: string) {
        if (search == undefined) { search = ""; }

        return this.http.get<ResponseData>(this.URI + "?page=" + page + "&pageSize=" + AppSettings.pageSize + "&search=" + search).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<Bill>(this.URI + '/' + Id).pipe(
            map(data => new Bill().deserialize(data))
        );
    }

    add(entSupplier: Bill) {
        return this.http.post<Bill>(this.URI, entSupplier);
    }

    update(entSupplier: Bill) {
        return this.http.put<Bill>(this.URI + '/' + entSupplier.id, entSupplier);
    }

    delete(Id: number) {
        return this.http.delete<Bill>(this.URI + '/' + Id);
    }
}