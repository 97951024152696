import { Deserializable } from './deserializable.model';
import { Epic } from './epic.model';

export class Issue implements Deserializable {
  public id: number;
  public title: string;
  public description: string;
  public priority: number;
  public effort: number;
  public actualEffort: number;
  public startDate: Date;
  public endDate: Date;
  public status: number;
  public hasFile: boolean;
  public epicId: number;
  public responsible: number;
  public sequence: number;
  public sprintId: number;

  public epic: Epic;

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    if (this.startDate != null) {
      newObj.startDate = new Date(Number(this.startDate.toString().substr(0, 4)), Number(this.startDate.toString().substr(5, 2)) - 1, Number(this.startDate.toString().substr(8, 2)));
    }

    if (this.endDate != null) {
      newObj.endDate = new Date(Number(this.endDate.toString().substr(0, 4)), Number(this.endDate.toString().substr(5, 2)) - 1, Number(this.endDate.toString().substr(8, 2)));
    }
    //newObj.registeredDate = this.registeredDate.toString().substr(8,2)+'/'+this.registeredDate.toString().substr(5,2)+'/'+this.registeredDate.toString().substr(0,4);
    return newObj;
  }
}
