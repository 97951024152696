import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { ProjectUsers } from '../models/projectUsers.model';

@Injectable({ providedIn: 'root' })
export class ProjectUsersService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.projectUsersEndPoint; //'https://localhost:44325/api/projectusers';

    getAll(projectId: number, userId: number) {
        return this.http.get<ProjectUsers[]>(this.URI + '?projectId=' + projectId + '&userId=' + userId).pipe(
            map(data => data.map(data => new ProjectUsers().deserialize(data)))
        );
    }

    getById(id: number) {
        return this.http.get<ProjectUsers>(this.URI + '/' + id).pipe(
            map(data => new ProjectUsers().deserialize(data))
        );
    }

    add(projectId: number, lstUserId: number[]) {
        return this.http.post<ProjectUsers>(this.URI + '/' + projectId, lstUserId);
    }

    delete(projectId: number, lstUserId: number[]) {
        return this.http.put<ProjectUsers>(this.URI + '/' + projectId, lstUserId);
    }

    /*update(projectId: number, lstUserId: number[]) {
        return this.http.delete<ProjectUsers>(this.URI + '/' + projectId, lstUserId);
    }*/
}