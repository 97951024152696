import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { HylaSpinnerService } from './hyla-spinner.service';

@Component({
  selector: 'hyla-spinner',
  templateUrl: './hyla-spinner.component.html',
  styleUrls: ['./hyla-spinner.component.scss']
})
export class HylaSpinnerComponent implements OnInit {

  constructor(public spinnerService: HylaSpinnerService) { }

  isLoading: Subject<boolean> = this.spinnerService.isLoading;

  ngOnInit(): void {
  }

}
