import { Deserializable } from './deserializable.model';
import { Issue } from './issue.model';

// export class Location.Model {
// }

export class Epic implements Deserializable {
  public id: number;
  public title: string;
  public description: string;
  public startDate: Date;
  public endDate: Date;
  public status: number;
  public projectId: number;
  public sequence: number;
  public issue: Issue[];

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
