import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

//Dialog
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HylaDialogComponent } from '../hyla-dialog/hyla-dialog.component'

// Location
import { LocationService } from '../../services/location.service';
import { Location } from '../../models/location.model';

@Component({
  selector: 'hyla-grid',
  templateUrl: './hyla-grid.component.html',
  styleUrls: ['./hyla-grid.component.scss']
})
export class HylaGridComponent implements OnInit {
  public searchForm: FormGroup;

  @Input('page') page: number;
  @Input('totalRecords') totalRecords: number;
  @Input('data') data: any[];
  @Input('searchText') searchText: string;
  @Input('colNames') colNames: any[];
  @Input('dialogDelete') dialogDelete: any;
  @Input('newButton') newButton: boolean = true;
  @Input('editButton') editButton: boolean = true;
  @Input('deleteButton') deleteButton: boolean = true;
  @Input('downloadButton') downloadButton: boolean = false;
  @Input('downloadProperty') downloadProperty: string;
  @Input('receiptButton') receiptButton: boolean = false;
  @Input('effortReportButton') effortReportButton: boolean = false;
  @Input('activitiesButton') activitiesButton: boolean = false;
  @Input('menuPermission') menuPermission: boolean = true;
  @Input('activeToggle') activeToggle: boolean = false;
  @Input('toggleTitle') toggleTitle: string = 'Only active';

  @Output('newEvent') newEvent: EventEmitter<any> = new EventEmitter();
  @Output('editEvent') editEvent: EventEmitter<any> = new EventEmitter();
  @Output('deleteEvent') deleteEvent: EventEmitter<any> = new EventEmitter();
  @Output('downloadEvent') downloadEvent: EventEmitter<any> = new EventEmitter();
  @Output('receiptEvent') receiptEvent: EventEmitter<any> = new EventEmitter();
  @Output('effortReportEvent') effortReportEvent: EventEmitter<any> = new EventEmitter();
  @Output('setPageEvent') setPageEvent: EventEmitter<any> = new EventEmitter();
  @Output('activitiesEvent') activitiesEvent: EventEmitter<any> = new EventEmitter();
  @Output('activeToggleEvent') activeToggleEvent: EventEmitter<any> = new EventEmitter();

  constructor(private locationData: LocationService,
    public dialog: MatDialog) { }

  public lstLocation: Location[];

  lstColName: any[];

  modelActiveToggle: boolean = false;

  ngOnInit(): void {
    this.lstColName = [];

    if (this.menuPermission) {
      this.lstColName.push('Menu');
    }

    if (this.activitiesButton) {
      this.lstColName.push('Activities');
    }

    for (let index = 0; index < this.colNames.length; index++) {
      this.lstColName.push(this.colNames[index].name);
    }

    if (this.colNames.find(x => x.pipe == 'location')) {
      this.locationData.getAll(-1, '').subscribe(response => {
        this.lstLocation = response.data.map(data => new Location().deserialize(data));

        this.setPage({ pageIndex: this.page });
      });
    } else {
      this.setPage({ pageIndex: this.page });
    }

    this.validators();
  }

  validators() {
    this.searchForm = new FormGroup({
      search: new FormControl('', null)
    });
  }

  openDialog(value) {
    var Value;
    if (typeof value[this.dialogDelete.col] == 'object') {
      Value = value[this.dialogDelete.col].toLocaleDateString('pt');
    } else {
      Value = value[this.dialogDelete.col];
    }

    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        title: this.dialogDelete.data.title,
        message: this.dialogDelete.data.message + ' ' + Value + '?',
        saveButton: this.dialogDelete.data.saveButton,
        cancelButton: this.dialogDelete.data.cancelButton
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteClick(value.id)

      }
    });
  }

  searchClick() {
    this.setPage({ pageIndex: 0 });
  }

  newClick() {
    this.newEvent.emit();
  }

  editClick(value: any) {
    this.editEvent.emit(value);
  }

  deleteClick(value: any) {
    this.deleteEvent.emit(value);
  }

  downloadClick(value: any) {
    this.downloadEvent.emit(value);
  }

  receiptClick(value: any) {
    this.receiptEvent.emit(value);
  }

  effortReportClick(value: any) {
    this.effortReportEvent.emit(value);
  }

  setPage(value: any) {
    value.search = this.searchText;
    this.setPageEvent.emit(value);
  }

  activitiesClick(value: any) {
    this.activitiesEvent.emit(value);
  }

  activeToggleClick(value: any) {
    this.activeToggleEvent.emit(this.modelActiveToggle);
    this.modelActiveToggle = value;
  }

  columnStyle(colunm): Object {
    return {
        'width.%': colunm.width != undefined ? colunm.width : null,
        'max-width.%': colunm.maxWidth != undefined ? colunm.maxWidth : null,
        'min-width.%': colunm.minWidth != undefined ? colunm.minWidth : null,
      }
  }
}
