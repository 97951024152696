import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

@Injectable({ providedIn: 'root' })
export class LoginService {
    constructor(private http: HttpClient
        ) { }

    login(email: string, password: string) {
        return this.http.post<any>(AppSettings.baseURL + AppSettings.userEndPoint + '/authenticate', { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}