<div class="header-container project-activities">
    <mat-card>
        <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="onTabChanged($event);"
            style="justify-content: end;">
            <mat-tab label="Backlog"></mat-tab>
            <mat-tab label="Board" [disabled]="boardDisabled()"></mat-tab>
        </mat-tab-group>
        <span class="spacer"></span>

        <form [formGroup]="validationHeader">
            <mat-slide-toggle [(ngModel)]="modelActiveToggle" formControlName="modelActiveToggle"
                style="padding: 5px 20px; margin-top: 12px;" *ngIf="tabIndex == 0"
                (toggleChange)="activeToggleClick(!modelActiveToggle)" labelPosition="after">
                All Issues</mat-slide-toggle>

            <mat-form-field appearance="legacy" [ngStyle]="{ 'padding': tabIndex == 0 ? '5px 10px' : '5px 0' }">
                <mat-label>Select Sprint</mat-label>
                <mat-select [(ngModel)]="modelSprint"
                    (selectionChange)="tabIndex == 0 ? onSprintChange($event) : getBoardData()"
                    formControlName="sprint">
                    <mat-option [value]="0" *ngIf="tabIndex == 0">All</mat-option>
                    <mat-option *ngFor="let sprint of lstSprint" [value]="sprint.id">
                        {{ sprint.name }}
                    </mat-option>
                    <mat-option class="font-primary-green" [value]="-1" *ngIf="tabIndex == 0">
                        <mat-icon>add</mat-icon>New Sprint
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <mat-label *ngIf="tabIndex == 1 && entSprint" matTooltip="Sprint Effort" style="margin: auto; padding: 0 25px;">
            {{ entSprint.plannedEffort }} / {{ entSprint.actualEffort }}</mat-label>

        <span style="padding-top: 5px; padding-right: 10px;">
            <button mat-mini-fab color="primary" class="header-add-button" (click)="openBurnDownChartDialog()" *ngIf="tabIndex == 1">
                <mat-icon>
                    show_chart
                </mat-icon>
            </button>
        </span>
        <span style="padding-top: 5px; padding-right: 5px;">
            <button mat-mini-fab color="primary" class="header-add-button"
                (click)="projectActivitiesButton(1) == 'add' ? openEpicDialog() : projectActivitiesButton(1) == 'play_arrow' ? sprintButtonClick(0) : sprintButtonClick(1)"
                [disabled]="projectActivitiesButton(0)">
                <mat-icon>
                    {{ projectActivitiesButton(1) }}
                </mat-icon>
            </button>
        </span>
    </mat-card>
</div>
<div class="card-container">

    <!-- Backlog -->
    <mat-card *ngIf="tabIndex == 0" style="padding: 15px;">
        <div class="row backlogHeader">
            <label class="backlogTitle">Title</label>
            <label class="backlogSprint" *ngIf="!modelSprintForm">Sprint</label>
            <label class="backlogEffort" *ngIf="!modelSprintForm">Effort</label>
            <label class="backlogStatus" *ngIf="!modelSprintForm">Status</label>
            <label class="backlogResponsible" *ngIf="!modelSprintForm">Responsible</label>
            <label class="backlogStartDate" *ngIf="!modelSprintForm">Start Date</label>
            <label class="backlogEndDate" *ngIf="!modelSprintForm">End Date</label>
        </div>
        <div class="row" *ngIf="lstEpic">
            <mat-label class="backlog-no-data" *ngIf="lstEpic.length == 0">This Sprint has no Issue...</mat-label>

            <div class="col" id="backlog" style="width: calc(100% - 30px);">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" cdkDropList
                    (cdkDropListDropped)="drop($event)" *ngIf="lstEpic.length != 0">
                    <!-- <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding cdkDrag [cdkDragData]="node"
                        (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()"
                        (cdkDragReleased)="dragEnd()" style="padding-left: 15px !important;"> -->
                    <mat-tree-node *matTreeNodeDef="let node"
                        style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                        <div id="title" style="padding-left: 40px !important;"
                            [ngStyle]="{ 'width': columnWidth('100%', '30%') }">
                            <button mat-icon-button class="ontreeHover addIssue" (click)="onIssueClick(node)"
                                *ngIf="issueClickValidator(node.dbId) && (userRole == 1 || userRole == 2) && modelSprintForm"
                                style="position: absolute;">
                                <mat-icon class="mat-icon-rtl-mirror"
                                    style="font-size: 15px !important; padding-bottom: 39px !important;">
                                    add
                                </mat-icon>
                            </button>
                            <mat-icon style="margin-right: 5px; margin-left: 40px;">
                                layers
                            </mat-icon>
                            <label class="editIssue" (click)="openIssueDialog(node.parentId, 0, node.dbId)"
                                style="max-width: calc(100% - 110px); text-overflow: ellipsis; overflow: hidden;">{{node.title}}</label>
                        </div>

                        <label id="sprint" style="width: 19%; font-size: 12px;"
                            *ngIf="!modelSprintForm">{{sprintName(node.sprintId)}}</label>

                        <label id="effort" style="width: 5.8%; font-size: 12px;"
                            *ngIf="!modelSprintForm">{{node.effort}}</label>

                        <label id="status" style="width: 8%; font-size: 12px;" *ngIf="!modelSprintForm">{{node.status |
                            activeStatus}}</label>

                        <label id="responsible" style="width: 19.2%; font-size: 12px;"
                            *ngIf="!modelSprintForm">{{userName(node.responsible)}}</label>

                        <label id="startDate" style="width: 8.2%; font-size: 12px;"
                            *ngIf="!modelSprintForm">{{node.startDate | date:
                            'dd/MM/yyyy'}}</label>

                        <div id="endDate" style="width: 9%;">
                            <label style="font-size: 12px;" *ngIf="!modelSprintForm">{{node.endDate | date:
                                'dd/MM/yyyy'}}</label>

                            <span class="spacer"></span>
                            <button mat-icon-button class="ontreeHover" [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu" style="float: right;">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="openIssueDialog(node.parentId, 0, node.dbId)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item (click)="openDeleteIssueDialog(node.parentId, node.dbId)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </div>
                    </mat-tree-node>

                    <!-- <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding cdkDrag [cdkDragData]="node"
                        (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()"
                        (cdkDragReleased)="dragEnd()"> -->
                    <mat-tree-node *matTreeNodeDef="let node;when: hasChild"
                        style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                        <div id="title" style="display: flex; align-items: center;"
                            [ngStyle]="{ 'width': columnWidth('100%', '54.7%') }">
                            <button mat-icon-button class="ontreeHover addIssue" (click)="openIssueDialog(node.dbId, 0)"
                                [disabled]="issueformValidation()">
                                <mat-icon class="mat-icon-rtl-mirror"
                                    style="font-size: 15px !important; padding-bottom: 39px !important;">
                                    add
                                </mat-icon>
                            </button>
                            <button mat-icon-button matTreeNodeToggle (click)="expansionModel.toggle(node.id)"
                                [attr.aria-label]="'toggle ' + node.filename" style="width: 25px; height: 25px;"
                                *ngIf="node.expandable">
                                <mat-icon class="mat-icon-rtl-mirror"
                                    style="font-size: 12px !important; padding-bottom: 39px !important;">
                                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                </mat-icon>
                            </button>
                            <mat-icon style="margin-right: 5px" [ngClass]="{ 'epicSpacer': !node.expandable }">
                                landscape
                            </mat-icon>
                            <label class="editEpic" (click)="openEpicDialog(node.dbId)"
                                style="max-width: calc(100% - 110px); text-overflow: ellipsis; overflow: hidden;">{{node.title}}</label>
                        </div>

                        <label id="status" style="width: 27.3%; font-size: 12px;" *ngIf="!modelSprintForm">{{
                            node.status |
                            activeStatus }}</label>

                        <label id="startDate" style="width: 8.2%; font-size: 12px;" *ngIf="!modelSprintForm">{{
                            node.startDate | date:
                            'dd/MM/yyyy' }}</label>

                        <div id="endDate" style="width: 9%;">
                            <label style="font-size: 12px;" *ngIf="!modelSprintForm">{{ node.endDate | date:
                                'dd/MM/yyyy' }}</label>
                            <span class="spacer"></span>
                            <button mat-icon-button class="ontreeHover" [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu" style="float: right;">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="openIssueDialog(node.dbId, 0)"
                                    [disabled]="issueformValidation()">
                                    <mat-icon>add</mat-icon>
                                    <span>New Issue</span>
                                </button>
                                <button mat-menu-item (click)="openEpicDialog(node.dbId)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item (click)="openDeleteEpicDialog(node.dbId)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </div>
                    </mat-tree-node>
                </mat-tree>
            </div>

            <div style="width: 1px; background-color: rgba(112, 112, 112, .3); margin-top: 15px;"
                *ngIf="modelSprintForm"></div>

            <div class="col" id="sprint" *ngIf="modelSprintForm">
                <h2 style="margin: 10px 15px 5px; font-size: 18px; font-weight: normal;">New Sprint</h2>
                <form id="form" [formGroup]="validationForm">
                    <div class="row">
                        <div class="col">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Planned Start Date</mat-label>
                                <input matInput [matDatepicker]="dp1" formControlName="plannedStartDate"
                                    [(ngModel)]="entSprint.plannedStartDate"
                                    (dateChange)="entSprint.name == undefined ? onPlannedStartDateChange() : entSprint.name == '' ? onPlannedStartDateChange() : null">
                                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                                <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Planned End Date</mat-label>
                                <input matInput [matDatepicker]="dp2" formControlName="plannedEndDate"
                                    [(ngModel)]="entSprint.plannedEndDate">
                                <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                                <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Name</mat-label>
                                <input matInput type="text" formControlName="name" [(ngModel)]="entSprint.name">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <h3 style="margin: 0 2px 0;">Sprint Backlog</h3>
                            <mat-list class="sprint-backlog" role="list" *ngIf="lstSprintIssueView.length > 0">
                                <mat-list-item role="listitem" *ngFor="let issue of lstSprintIssueView">
                                    <div class="bg-primary-green" matTooltip="Epic: {{ getEpicName(issue.parentId) }}">
                                        {{ getEpicName(issue.parentId) }}</div>
                                    <p matTooltip="Issue: {{ issue.title }}">
                                        &nbsp;&nbsp;&nbsp;&nbsp;{{ issue.title }}</p>
                                    <span class="spacer"></span>
                                    <button mat-icon-button (click)="onSprintIssueDelete(issue)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </mat-list-item>
                            </mat-list>
                            <label style="margin-left: 2px; color: #707070; opacity: 0.7;"
                                *ngIf="lstSprintIssueView.length == 0">No issues
                                selected...</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="error" *ngIf="responseError" style="padding: 15px 0;">
                                <mat-label>{{ responseError }}</mat-label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <button mat-flat-button color="primary" (click)="onSubmit()">Save</button>
                            <button mat-button (click)="modelSprintForm = false">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </mat-card>

    <!-- Board -->
    <mat-card *ngIf="tabIndex == 1" style="padding: 15px; min-height: 69vh;">
        <div id="BoardRow" class="row boardRow">
            <div class="col-2 example-container">
                <div class="status">
                    <h2>To Do</h2>
                </div>

                <div id="ToDo" cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo"
                    [cdkDropListConnectedTo]="[doingList, reviewList, redoList, doneList]" class="example-list"
                    (cdkDropListDropped)="boardDrop($event, 0)">
                    <div class="example-box" *ngFor="let item of todo" cdkDrag>
                        <div class="row" style="width: 100%;">
                            <div class="col-12 issuePriority"
                                matTooltip="Priority: {{ item.priority | activePriority }}"
                                [ngClass]="{ 'bg-green': item.priority == 3, 'bg-blue': item.priority == 2, 'bg-orange': item.priority == 1, 'bg-red': item.priority == 0 }">
                            </div>
                            <div class="col-sm-10 min-height issue">
                                <p class="issueTitle editIssue" matTooltip="Issue title"
                                    (click)="openIssueDialog(item.epic.id, 1, item.id)">{{item.title}}</p>
                                <label class="epicTitle" matTooltip="Epic title">{{item.epic.title}}</label>
                                <p class="responsibleName" matTooltip="Responsible">{{userName(item.responsible)}}</p>
                            </div>
                            <div class="col-sm-2" style="padding: 6px;">
                                <div class="effort" matTooltip="Issue Effort">{{item.effort}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-2 example-container">
                <div class="status">
                    <h2>Doing</h2>
                </div>

                <div id="Doing" cdkDropList #doingList="cdkDropList" [cdkDropListData]="doing"
                    [cdkDropListConnectedTo]="[todoList, reviewList, redoList, doneList]" class="example-list"
                    (cdkDropListDropped)="boardDrop($event, 1)" cdkDropListSortingDisabled>
                    <div class="example-box" *ngFor="let item of doing" cdkDrag>
                        <div class="row" style="width: 100%;">
                            <div class="col-12 issuePriority"
                                matTooltip="Priority: {{ item.priority | activePriority }}"
                                [ngClass]="{ 'bg-green': item.priority == 3, 'bg-blue': item.priority == 2, 'bg-orange': item.priority == 1, 'bg-red': item.priority == 0 }">
                            </div>
                            <div class="col-sm-10 min-height issue">
                                <p class="issueTitle editIssue" matTooltip="Issue title"
                                    (click)="openIssueDialog(item.epic.id, 1, item.id)">{{item.title}}</p>
                                <label class="epicTitle" matTooltip="Epic title">{{item.epic.title}}</label>
                                <p class="responsibleName" matTooltip="Responsible">{{userName(item.responsible)}}</p>
                            </div>
                            <div class="col-sm-2" style="padding: 6px;">
                                <div class="effort" matTooltip="Issue Effort">{{item.effort}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-2 example-container">
                <div class="status">
                    <h2>Review</h2>
                </div>

                <div id="Review" cdkDropList #reviewList="cdkDropList" [cdkDropListData]="review"
                    [cdkDropListConnectedTo]="[todoList, doingList, redoList, doneList]" class="example-list"
                    (cdkDropListDropped)="boardDrop($event, 2)" cdkDropListSortingDisabled>
                    <div class="example-box" *ngFor="let item of review" cdkDrag>
                        <div class="row" style="width: 100%;">
                            <div class="col-12 issuePriority"
                                matTooltip="Priority: {{ item.priority | activePriority }}"
                                [ngClass]="{ 'bg-green': item.priority == 3, 'bg-blue': item.priority == 2, 'bg-orange': item.priority == 1, 'bg-red': item.priority == 0 }">
                            </div>
                            <div class="col-sm-10 min-height issue">
                                <p class="issueTitle editIssue" matTooltip="Issue title"
                                    (click)="openIssueDialog(item.epic.id, 1, item.id)">{{item.title}}</p>
                                <label class="epicTitle" matTooltip="Epic title">{{item.epic.title}}</label>
                                <p class="responsibleName" matTooltip="Responsible">{{userName(item.responsible)}}</p>
                            </div>
                            <div class="col-sm-2" style="padding: 6px;">
                                <div class="effort" matTooltip="Issue Effort">{{item.effort}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-2 example-container">
                <div class="status">
                    <h2>Redo</h2>
                </div>

                <div id="Redo" cdkDropList #redoList="cdkDropList" [cdkDropListData]="redo"
                    [cdkDropListConnectedTo]="[todoList, doingList, reviewList, doneList]" class="example-list"
                    (cdkDropListDropped)="boardDrop($event, 3)" cdkDropListSortingDisabled>
                    <div class="example-box" *ngFor="let item of redo" cdkDrag>
                        <div class="row" style="width: 100%;">
                            <div class="col-12 issuePriority"
                                matTooltip="Priority: {{ item.priority | activePriority }}"
                                [ngClass]="{ 'bg-green': item.priority == 3, 'bg-blue': item.priority == 2, 'bg-orange': item.priority == 1, 'bg-red': item.priority == 0 }">
                            </div>
                            <div class="col-sm-10 min-height issue">
                                <p class="issueTitle editIssue" matTooltip="Issue title"
                                    (click)="openIssueDialog(item.epic.id, 1, item.id)">{{item.title}}</p>
                                <label class="epicTitle" matTooltip="Epic title">{{item.epic.title}}</label>
                                <p class="responsibleName" matTooltip="Responsible">{{userName(item.responsible)}}</p>
                            </div>
                            <div class="col-sm-2" style="padding: 6px;">
                                <div class="effort" matTooltip="Issue Effort">{{item.effort}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-2 example-container">
                <div class="status">
                    <h2>Done</h2>
                </div>

                <div id="Done" cdkDropList #doneList="cdkDropList" [cdkDropListData]="done"
                    [cdkDropListConnectedTo]="[todoList, doingList, reviewList, redoList]" class="example-list"
                    (cdkDropListDropped)="boardDrop($event, 4)" cdkDropListSortingDisabled>
                    <div class="example-box" *ngFor="let item of done" cdkDrag>
                        <div class="row" style="width: 100%;">
                            <div class="col-12 issuePriority"
                                matTooltip="Priority: {{ item.priority | activePriority }}"
                                [ngClass]="{ 'bg-green': item.priority == 3, 'bg-blue': item.priority == 2, 'bg-orange': item.priority == 1, 'bg-red': item.priority == 0 }">
                            </div>
                            <div class="col-sm-8 min-height issue">
                                <p class="issueTitle editIssue" matTooltip="Issue title"
                                    (click)="openIssueDialog(item.epic.id, 1, item.id)">{{item.title}}</p>
                                <label class="epicTitle" matTooltip="Epic title">{{item.epic.title}}</label>
                                <p class="responsibleName" matTooltip="Responsible">{{userName(item.responsible)}}</p>
                            </div>
                            <div class="col-sm-4" style="padding: 6px;">
                                <div class="effort" matTooltip="Issue Effort">{{item.effort}} / {{item.actualEffort}}
                                </div>
                                <!-- <div class="actualEffort" matTooltip="Actual Effort">{{item.actualEffort}}</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="totalRecords > 3">
                        <p class="issue-paging" (click)="issuePaging(totalRecords > issueNumber ? 1 : 2 )">{{
                            totalRecords > issueNumber ? 'See more' : 'See less' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>