import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// No Template
import { NoTemplateComponent } from './no-template.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    NoTemplateComponent
  ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [
    NoTemplateComponent
  ]
})
export class NoTemplateModule { }
