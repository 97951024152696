import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Holidays
import { Holidays } from '../../../models/holidays.model';
import { HolidaysService } from 'src/app/services/holidays.service';

// Location
import { Location } from '../../../models/location.model';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-holidays-detail',
  templateUrl: './holidays-detail.component.html',
  styleUrls: ['./holidays-detail.component.scss']
})
export class HolidaysDetailComponent implements OnInit {
  public validationForm: FormGroup;
  userRole: any;
  responseError: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private holidaysData: HolidaysService,
    private locationData: LocationService) { }

  // Holidays Variables
  public entHolidays: Holidays;

  // Location Variables
  public lstLocation: Location[];

  ngOnInit(): void {
    this.entHolidays = new Holidays();

    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;

    this.getData();
    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      date: new FormControl('', [Validators.required]),
      holidayName: new FormControl('', [Validators.required]),
      locationId: new FormControl('', [Validators.required])
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.holidaysData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entHolidays = response;

      });
    } else {
      this.entHolidays.id = 0;
    }


    this.locationData.getAll(-1, '').subscribe(response => {
      this.lstLocation = response.data.map(data => new Location().deserialize(data));

    });
  }

  onSubmit() {
    if (this.validationForm.invalid) {
      return;
    }

    this.save();
  }

  save() {
    if (this.entHolidays.id == 0) {
      this.holidaysData.add(this.entHolidays).subscribe(data => {

        this.redirect();
      }, error => this.responseError = error.error.message);
    } else {
      this.holidaysData.update(this.entHolidays).subscribe(data => {
        this.redirect();
      }, error => this.responseError = error.error.message);
    }
  }

  redirect() {
    SessionManager.SetSession('holidaysToast', '1');
    this.router.navigate(['/manager/holidays']);
  }

  cancelClick() {
    this.router.navigate(['/manager/holidays']);
  }

}
