import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { HttpEventType } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

// File Storage
import { FileStorageService } from 'src/app/services/fileStorage.service';

// Bill
import { Bill } from '../../../models/bill.model';
import { BillService } from '../../../services/bill.service';

@Component({
  selector: 'app-bill-list',
  templateUrl: './bill-list.component.html',
  styleUrls: ['./bill-list.component.scss']
})
export class BillListComponent implements OnInit {
  columns = [
    { prop: 'number', name: 'Number', maxWidth: 8 },
    { prop: 'supplier.name', name: 'Supplier', pipe: 'suppliername', minWidth: 30 },
    { prop: 'emissionDate', name: 'Emission Date', pipe: 'date' },
    { prop: 'totalValue', name: 'Total Value', pipe: 'currency' },
    { prop: 'netValue', name: 'Net Value', pipe: 'currency' },
    { prop: 'paymentForecast', name: 'Payment Forecast', pipe: 'date' },
    { prop: 'paid', name: 'Paid', pipe: 'active', maxWidth: 12 }
  ];

  dialogDelete = {
    col: 'number', data: {
      title: 'Confirm deletion', message: 'Do you want to delete the bill', saveButton: 'Yes', cancelButton: 'No'
    }
  };

  userRole: number;
  userType: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private fileStorageData: FileStorageService,
    private billData: BillService) { }

  // Bill Variables
  public lstBill: Bill[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole == 3 && this.userType == 1) {
      this.router.navigate(['/manager']);
    }

    if (SessionManager.SessionExist('billPage')) {
      this.gridPaging[0] = SessionManager.GetSession('billPage');
    }

    if (SessionManager.SessionExist('billSearch')) {
      this.gridSearch = SessionManager.GetSession('billSearch');
    }

    if (SessionManager.SessionExist('billToast')) {
      SessionManager.DeleteSession('billToast');
      AppSettings.openSnackBar(this._snackBar, 'Bill successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }

    this.billData.getAll(pageInfo.pageIndex, pageInfo.search).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstBill = response.data.map(data => new Bill().deserialize(data));
    });
  }

  addClick() {
    this.router.navigate(['/manager/bill/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('billPage', this.gridPaging[0].toString());
    SessionManager.SetSession('billSearch', this.gridSearch);
    this.router.navigate(['/manager/bill/edit/' + value])
  }

  onDelete(value: any) {
    this.billData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); },
      error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

  downloadClick(entBill: Bill) {
    AppSettings.viewFirstFile(this.fileStorageData, HttpEventType, 4, entBill);
  }

}
