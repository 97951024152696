import { first } from 'rxjs/internal/operators/first';
import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as jwt_decode from 'jwt-decode';
import * as $ from 'jquery';

// Project
import { Project } from 'src/app/models/project.model';
import { ProjectService } from 'src/app/services/project.service';

// Timesheet
import { Timesheet } from 'src/app/models/timesheet.model';
import { TimesheetService } from 'src/app/services/timesheet.service';

// User
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { DatePipe } from '@angular/common';
import { WeekdayPipe } from 'src/app/utils/pipes/weekday.pipe';
import { HourTypePipe } from 'src/app/utils/pipes/hourType.pipe';
import { ActivityTypePipe } from 'src/app/utils/pipes/activityType.pipe';
import { ClientService } from 'src/app/services/client.service';
import { Client } from 'src/app/models/client.model';

@Component({
  selector: 'app-month-report',
  templateUrl: './month-report.component.html',
  styleUrls: ['./month-report.component.scss']
})
export class MonthReportComponent implements OnInit {
  modelUser: any;
  userID: any;
  userRole: any;
  userControl: boolean = true;
  modelMonth: any = new Date().getMonth() == 0 ? 12 : new Date().getMonth();
  modelYear: any = new Date().getMonth() == 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();
  year: number[] = [(new Date().getFullYear() - 1), new Date().getFullYear(), (new Date().getFullYear() + 1)];
  modelProject: number[] = [];
  projectControl: boolean = false;
  Project = [];
  Projects = [];
  ProjectsHours = [];
  businessDay: number;

  // Hours Type
  NormalHours: number = 0;
  ExtraHours: number = 0;
  WarningHours: number = 0;
  BasketHours: number = 0;
  LicenseHours: number = 0;
  VacationHours: number = 0;

  // Actvities Type
  ProjectManagement: number = 0;
  PreSales: number = 0;
  Design: number = 0;
  Development: number = 0;
  InternalTesting: number = 0;
  SAT: number = 0;
  AssitedOperation: number = 0;
  MaintenanceSupport: number = 0;
  Meeting: number = 0;
  Other: number = 0;

  // Hours By Type Chart
  canvasHoursByTypeChart: any;
  ctxHoursByTypeChart: any;
  hoursByTypeChart: any;
  hoursByTypeChartConfig: any;

  // Hours By Project Chart
  canvasHoursByProjectChart: any;
  ctxHoursByProjectChart: any;
  hoursByProjectChart: any;
  hoursByProjectChartConfig: any;

  // Hours By Activities Chart
  canvasHoursByActivitiesChart: any;
  ctxHoursByActivitiesChart: any;
  hoursByActivitiesChart: any;
  hoursByActivitiesChartConfig: any;

  constructor(private projectData: ProjectService,
    private timesheetData: TimesheetService,
    private userData: UserService,
    private ete: ExportExcelService,
    private datePipe: DatePipe,
    private weekdayPipe: WeekdayPipe,
    private hourTypePipe: HourTypePipe,
    private activityTypePipe: ActivityTypePipe,
    private clientService: ClientService) { }

  // Project Variables
  public lstProject: Project[];

  // Timesheet Variables
  public lstTimesheet: Timesheet[];

  // User Variables
  public lstUser: User[];
  public entUser: User;

  ngOnInit(): void {
    this.userID = jwt_decode(localStorage.getItem('currentUser')).userId;
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;

    if (this.userRole == 1 || this.userRole == 2) {
      this.userControl = false;
    }

    this.modelUser = parseInt(this.userID);

    this.getUser();
  }

  getUser() {
    this.userData.getAll(-1, '').subscribe(response => {
      this.lstUser = response.data.map(data => new User().deserialize(data)).filter(x => x.type == 1 && (x.dismissDate == undefined || x.dismissDate.getFullYear() >= this.year[0]));
    });
  }

  getData() {
    // this.modelProject = [];
    this.Projects = [];
    this.lstProject = []
    this.lstTimesheet = []
    if (this.modelProject.length == 0) this.Project = [];
    this.ProjectsHours = [];
    var startDate = new Date(this.modelYear, this.modelMonth - 1, 1);
    var endDate = new Date(this.modelYear, this.modelMonth - 1, 1);

    this.entUser = this.lstUser.find(x => x.id == this.modelUser);

    this.projectData.getAll(-1, '', false, this.entUser.id).subscribe(response => {
      this.lstProject = response.data.map(data => new Project().deserialize(data));

      this.timesheetData.getAll(-1, '', startDate, this.lastDayMonth(endDate), this.modelUser).subscribe(response => {
        this.lstTimesheet = response.data.map(data => new Timesheet().deserialize(data));

        if (this.modelProject.length > 0) {
          var timesheet = [];
          this.modelProject.forEach(projectId => {
            this.lstTimesheet.filter(x => x.projectId == projectId).forEach(entTimesheet => {
              timesheet.push(entTimesheet);
            });
          });

          this.lstTimesheet = timesheet;
          // this.entProject = this.lstTimesheet[0].project;
        }

        if (this.userRole == 1) {
          this.projectControl = true;
        }

        this.HoursType();
        this.Activities();

        for (var i = 0; i < this.lstTimesheet.length; i++) {
          this.AddProjects(this.lstTimesheet[i].project.projectName, this.lstTimesheet[i].registeredHours);
          if (this.modelProject.length == 0) {
            this.AddProjectsOnSelect(this.lstTimesheet[i].project.projectName);
          }
        }

        this.Projects.sort(function (a, b) {
          return (a[0] > b[0]) ? 1 : ((b[0] > a[0]) ? -1 : 0);
        });

        this.Project.sort(function (a, b) {
          return (a > b) ? 1 : ((b > a) ? -1 : 0);
        });

        if (this.modelProject.length == 0) {
          this.Project.forEach(project => {
            this.modelProject.push(this.lstProject.find(x => x.projectName == project).id);
          });
        }

        // Update Hours By Type Chart
        var lstHours = [{ hourType: 'Normal', value: this.NormalHours }, { hourType: 'Extra', value: this.ExtraHours }, { hourType: 'Warning', value: this.WarningHours }, { hourType: 'Basket', value: this.BasketHours }, { hourType: 'License', value: this.LicenseHours }, { hourType: 'Vacation', value: this.VacationHours }];

        this.hoursByTypeChartConfig.data.labels = [];

        while (this.hoursByTypeChartConfig.data.datasets[0].data.length > 0) {
          this.hoursByTypeChartConfig.data.datasets[0].data.pop();

        }

        for (var i = 0; i < lstHours.length; i++) {

          if (lstHours[i].value > 0) {
            this.hoursByTypeChartConfig.data.labels.push(lstHours[i].hourType);
            this.hoursByTypeChartConfig.data.datasets[0].data.push(lstHours[i].value);
          }
        }

        this.hoursByTypeChart.update();

        // Update Hours By Project Chart
        this.hoursByProjectChartConfig.data.labels = [];

        while (this.hoursByProjectChartConfig.data.datasets[0].data.length > 0) {
          this.hoursByProjectChartConfig.data.datasets[0].data.pop();

        }

        for (i = 0; i < this.Projects.length; i++) {
          this.hoursByProjectChartConfig.data.labels.push(this.Projects[i][0]);
          this.hoursByProjectChartConfig.data.datasets[0].data.push(this.Projects[i][1]);
        }

        this.hoursByProjectChart.update();

        // Update Hours By Activities Chart
        var lstActivities = [{ activityType: 'Project Management', value: this.ProjectManagement }, { activityType: 'Pre-Sales', value: this.PreSales }, { activityType: 'Design', value: this.Design }, { activityType: 'Development', value: this.Development }, { activityType: 'Internal Testing', value: this.InternalTesting }, { activityType: 'SAT', value: this.SAT }, { activityType: 'Assited Operation', value: this.AssitedOperation }, { activityType: 'Maintenance Support', value: this.MaintenanceSupport }, { activityType: 'Meeting', value: this.Meeting }, { activityType: 'Other', value: this.Other }];

        this.hoursByActivitiesChartConfig.data.labels = [];

        while (this.hoursByActivitiesChartConfig.data.datasets[0].data.length > 0) {
          this.hoursByActivitiesChartConfig.data.datasets[0].data.pop();

        }

        for (var i = 0; i < lstActivities.length; i++) {
          if (lstActivities[i].value > 0) {
            this.hoursByActivitiesChartConfig.data.labels.push(lstActivities[i].activityType);
            this.hoursByActivitiesChartConfig.data.datasets[0].data.push(lstActivities[i].value);
          }
        }

        this.hoursByActivitiesChart.update();
      });
    });

    $('#print').removeClass('display-none');
  }

  getDataExcel(){


      this.clientService.getAll(-1,'').subscribe(response => {

        let lstClient = response.data.map(data => new Client().deserialize(data));
        let empLocal = this.lstUser.find(x => x.id == this.modelUser);

        let monthName = getMonthName(this.modelMonth);
        let dataProjectsForExcel=[];
        let dataProjects = [];
        let dataForExcel = [];
        let empPerformance = [];

        this.lstTimesheet.forEach(timesheet => {
          empPerformance.push({
            Date: this.datePipe.transform(timesheet.registeredDate,'dd/MM/yyyy'),
            Day: this.weekdayPipe.transform(timesheet.registeredDate),
            Hours: timesheet.registeredHours,
            Type: this.hourTypePipe.transform(timesheet.hourType),
            Project: this.projectName(timesheet.projectId),
            Activity: this.activityTypePipe.transform(timesheet.activityType),
            Comment: timesheet.comment
          });
        });

        empPerformance.forEach((row: any) => {
          dataForExcel.push(Object.values(row))
        })

        this.Projects.forEach(project => {
          let timeshIdx = this.lstTimesheet.findIndex(x=>x.project.projectName == project[0]);
          let localProject = this.lstTimesheet[timeshIdx].project;
          dataProjects.push({
             Month:monthName,
             Employee:empLocal.name,
             Client: lstClient.find(x => x.id == localProject.clientId).name,
             Project: project[0],
             Hours:project[1]
          });
        });

        dataProjects.forEach((row: any) => {
          dataProjectsForExcel.push(Object.values(row))
        })

        let reportData = {
          title: `Month Report - Employee: ${this.entUser.name} - ${monthName} - ${this.modelYear}`,
          data: dataForExcel,
          dataProject: dataProjectsForExcel,
          headersProject:Object.keys(dataProjects[0]),
          headers: Object.keys(empPerformance[0])
        }

        this.ete.exportExcelMonthReport(reportData);
      });


  }

  lastDayMonth(date: Date): Date {
    var data = date;
    var Month = data.getMonth();

    this.businessDay = 0;

    while (data.getMonth() == Month) {
      data.setDate(data.getDate() + 1);

      if (data.getDay() >= 2 && data.getDay() <= 6) {
        this.businessDay++;
      }
    }

    data.setDate(data.getDate() - 1);

    return data;
  }

  HoursType() {
    this.NormalHours = 0;
    this.ExtraHours = 0;
    this.WarningHours = 0;
    this.BasketHours = 0;
    this.LicenseHours = 0;
    this.VacationHours = 0;

    for (var i = 0; i < this.lstTimesheet.length; i++) {
      switch (this.lstTimesheet[i].hourType) {
        case 1: {
          this.NormalHours += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 2: {
          this.ExtraHours += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 3: {
          this.WarningHours += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 4: {
          this.BasketHours += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 5: {
          this.LicenseHours += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 6: {
          this.VacationHours += this.lstTimesheet[i].registeredHours;
          break;
        }
      }
    }
  }

  Activities() {
    this.ProjectManagement = 0;
    this.PreSales = 0;
    this.Design = 0;
    this.Development = 0;
    this.InternalTesting = 0;
    this.SAT = 0;
    this.AssitedOperation = 0;
    this.MaintenanceSupport = 0;
    this.Meeting = 0;
    this.Other = 0;

    for (var i = 0; i < this.lstTimesheet.length; i++) {
      switch (this.lstTimesheet[i].activityType) {
        case 1: {
          this.ProjectManagement += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 2: {
          this.PreSales += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 3: {
          this.Design += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 4: {
          this.Development += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 5: {
          this.InternalTesting += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 6: {
          this.SAT += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 7: {
          this.AssitedOperation += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 8: {
          this.MaintenanceSupport += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 9: {
          this.Other += this.lstTimesheet[i].registeredHours;
          break;
        }
        case 10: {
          this.Meeting += this.lstTimesheet[i].registeredHours;
        }
      }
    }
  }

  AddProjects(project: string, hours: any) {
    var indiceProjects = -1;

    for (var i = 0; i < this.Projects.length; i++) {
      if (this.Projects[i][0] === project) {
        indiceProjects = i;
        break;
      }
    }

    if (indiceProjects < 0) {
      this.Projects.push([project, hours]);
      indiceProjects = (this.Projects.length - 1);
    } else {
      this.Projects[indiceProjects][1] += hours;
    }
  }

  AddProjectsOnSelect(project: string) {
    var index = -1;
    index = this.Project.indexOf(project);

    if (index < 0) {
      this.Project.push(project);
      index = (this.Project.length - 1);
    }
  }

  projects(Project: string, type: number) {
    var Information = 0;
    if (this.lstProject.length > 0) {
      if (type == 1) {
        var index = this.Projects.indexOf(Project);

        if (index != -1) {
          Information = this.ProjectsHours[index];
        }
      } else {
        Information = this.lstProject.find(x => x.projectName == Project).id;
      }
      return Information;
    }
  }

  projectName(projectId: number) {
    if (this.lstProject.length > 0)
    return this.lstProject.find(x => x.id == projectId).projectName;
  }

  onUserChange() {
    this.Project = [];
    this.modelProject = [];
    this.projectControl = false;
    $('#print').addClass('display-none');
  }

  onProjectChange() {
    $('#print').addClass('display-none');
  }

  ngAfterViewInit() {
    // Start Hours By Type Chart
    this.canvasHoursByTypeChart = document.getElementById('hoursByTypeChart');
    this.ctxHoursByTypeChart = this.canvasHoursByTypeChart.getContext('2d');
    this.hoursByTypeChartConfig = {
      type: 'pie',
      plugins: [ChartDataLabels],
      data: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524'],
          borderColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524'],
          datalabels: {
            color: 'white'
          }
        }]
      },
      options: {
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'start',
            font: {
              size: 12,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        legend: {
          position: 'right',
          labels: {
            fontColor: "black",
            boxWidth: 6,
            padding: 15,
            usePointStyle: true
          }
        },
      }
    };
    this.hoursByTypeChart = new Chart(this.ctxHoursByTypeChart, this.hoursByTypeChartConfig);
    // End Hours By Type Chart

    // Start Hours By Project Chart
    this.canvasHoursByProjectChart = document.getElementById('hoursByProjectChart');
    this.ctxHoursByProjectChart = this.canvasHoursByProjectChart.getContext('2d');

    this.hoursByProjectChartConfig = {
      type: 'pie',
      plugins: [ChartDataLabels],
      data: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524'],
          borderColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524'],
          datalabels: {
            color: 'white'
          }
        }]
      },
      options: {
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'start',
            font: {
              size: 12,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        legend: {
          position: 'right',
          labels: {
            fontColor: "black",
            boxWidth: 6,
            padding: 15,
            usePointStyle: true
          }
        },
      }
    };

    this.hoursByProjectChart = new Chart(this.ctxHoursByProjectChart, this.hoursByProjectChartConfig);
    // End Hours By Project Chart

    // Start Hours By Activities Chart
    this.canvasHoursByActivitiesChart = document.getElementById('HoursByActivitiesChart');
    this.ctxHoursByActivitiesChart = this.canvasHoursByActivitiesChart.getContext('2d');

    this.hoursByActivitiesChartConfig = {
      type: 'pie',
      plugins: [ChartDataLabels],
      data: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524', '#6abd47', '#bad631', '#f3ec18'],
          borderColor: ['#6abd47', '#bad631', '#f3ec18', '#f7cf1c', '#faa51b', '#eb4524', '#6abd47', '#bad631', '#f3ec18'],
          datalabels: {
            color: 'white'
          }
        }]
      },
      options: {
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'start',
            font: {
              size: 12,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        legend: {
          position: 'right',
          labels: {
            fontColor: "black",
            boxWidth: 6,
            padding: 15,
            usePointStyle: true
          }
        },
      }
    };

    this.hoursByActivitiesChart = new Chart(this.ctxHoursByActivitiesChart, this.hoursByActivitiesChartConfig);
    // End Hours By Activities Chart
  }

}
function getMonthName(month: number) {
 switch (month) {
    case 1:
      return "January"
    case 2:
      return "February"
    case 3:
      return "March"
    case 4:
      return "April"
    case 5:
      return "May"
    case 6:
      return "June"
    case 7:
      return "July"
    case 8:
      return "August"
    case 9:
      return "September"
    case 10:
      return "October"
    case 11:
      return "November"
    case 12:
      return "December"
    default:
      return month;
 }
}

