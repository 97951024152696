import {Deserializable} from './deserializable.model';

// export class Location.Model {
// }

export class Client implements Deserializable {
    public id: number;
    public name: string;
    public fiscalName: string;
    public taxNumber: string;
    public providerCode: string;
    public address: string;
    public number: number;
    public complement: string;
    public city: string;
    public state: string;
    public zipCode: string;
  
    deserialize(input: any): this {
      return Object.assign(this, input);
    }
  }
