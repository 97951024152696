import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Epic } from '../models/epic.model';

@Injectable({ providedIn: 'root' })
export class EpicService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.epicEndPoint; //'https://localhost:44325/api/epic';

    getAll(projectId, active, sprintId, startDate?: Date, endDate?: Date) {
        if (active == null || active == undefined) { active = true }
        var period = '';
        if (startDate != undefined && endDate != undefined) {
            period = '&startDate=' + startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + '&endDate=' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
        }

        return this.http.get<Epic[]>(this.URI + '?projectId=' + projectId + period + '&active=' + active + '&sprintId=' + sprintId).pipe(
            map(data => data.map(data => new Epic().deserialize(data)))
        );
    }

    getById(Id: number) {
        return this.http.get<Epic>(this.URI + '/' + Id).pipe(
            map(data => new Epic().deserialize(data))
        );
    }

    add(entEpic: Epic) {
        return this.http.post<Epic>(this.URI, entEpic);
    }

    update(entEpic: Epic) {
        return this.http.put<Epic>(this.URI + '/' + entEpic.id, entEpic);
    }

    updateSequence(id: number, newSequence: number) {
        return this.http.put<Epic>(this.URI + '/UpdateSequence' + '/' + id + '/' + newSequence, '');
    }

    delete(Id: number) {
        return this.http.delete<Epic>(this.URI + '/' + Id);
    }
}