import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Customer
import { Client } from '../../../models/client.model';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  public validationForm: FormGroup;
  userRole: any;
  responseError: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private clientData: ClientService) { }

  // Customer Variables
  public entClient: Client;

  ngOnInit(): void {
    this.entClient = new Client();

    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;

    if(this.userRole != 1 && this.userRole != 2) {
      this.router.navigate(['/manager']);
    }

    this.getData();
    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      fiscalName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      taxNumber: new FormControl('', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]),
      providerCode: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required, Validators.minLength(5)]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl('', null),
      city: new FormControl('', [Validators.required, Validators.minLength(6)]),
      state: new FormControl('', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]),
      zipCode: new FormControl('', [Validators.required])
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.clientData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entClient = response;

      });
    } else {
      this.entClient.id = 0;
    }
  }

  zipCodeMask(event: any) {
    if (this.entClient.zipCode != undefined) {
      if (this.entClient.zipCode.length == 5) {
        this.entClient.zipCode = this.entClient.zipCode + '-';
      } else if (this.entClient.zipCode.length > 9) {
        this.entClient.zipCode = this.entClient.zipCode.substr(0, 9);
      }
    }
  }

  onSubmit() {
    this.responseError = "";
    if (this.validationForm.invalid) {
      return;
    }

    if (isNaN(this.entClient.number)) {
      this.responseError = "The number must be an integer";
      return;
    }

    this.save();
  }

  save() {
    this.entClient.zipCode = this.entClient.zipCode.substr(0, 5)+this.entClient.zipCode.substr(6, this.entClient.zipCode.length);
    if (this.entClient.id == 0) { // Add
      this.clientData.add(this.entClient).subscribe(data => {

        this.redirect();
      }, error => this.responseError = error.error.message);
    } else { // Edit
      this.clientData.update(this.entClient).subscribe(data => {
        this.redirect();
      }, error => this.responseError = error.error.message);
    }
  }

  redirect() {
    SessionManager.SetSession('customerToast', '1');
    this.router.navigate(['/manager/customer']);
  }

  cancelClick() {
    this.router.navigate(['/manager/customer']);
  }

}
