import { Deserializable } from './deserializable.model';
import { PurchaseOrder } from './purchaseOrder.model';

export class Invoice implements Deserializable {
  public id: number;
  public number: number;
  public emissionDate: Date;
  public invoiceType: number;
  public poid: number;
  public totalValue: number;
  public netValue: number;
  public received: boolean;
  public receivedDate: Date;
  public hasFile: boolean;
  public receivedForecast: Date;
  public po: PurchaseOrder;

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    newObj.emissionDate = new Date(Number(this.emissionDate.toString().substr(0, 4)), Number(this.emissionDate.toString().substr(5, 2)) - 1, Number(this.emissionDate.toString().substr(8, 2)));

    if (this.receivedDate != null) {
      newObj.receivedDate = new Date(Number(this.receivedDate.toString().substr(0, 4)), Number(this.receivedDate.toString().substr(5, 2)) - 1, Number(this.receivedDate.toString().substr(8, 2)));
    }

    if (this.receivedForecast != null) {
      newObj.receivedForecast = new Date(Number(this.receivedForecast.toString().substr(0, 4)), Number(this.receivedForecast.toString().substr(5, 2)) - 1, Number(this.receivedForecast.toString().substr(8, 2)));
    }

    return newObj;
  }
}
