import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hyla-calendar',
  templateUrl: './hyla-calendar.component.html',
  styleUrls: ['./hyla-calendar.component.scss']
})
export class HylaCalendarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}
