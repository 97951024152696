import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

// User
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  columns = [
    { prop: 'name', name: "Name" },
    { prop: 'email', name: "E-mail", minWidth: 30 },
    { prop: 'admissionDate', name: "Admission Date", pipe: 'date' },
    { prop: 'birthDate', name: "Birth Date", pipe: 'date' },
    { prop: 'locationId', name: "Location", pipe: 'location' }
  ];

  dialogDelete = {
    col: 'name',
    data: {
      title: 'Confirm deletion', message: 'Do you want to delete the employee', saveButton: 'Yes', cancelButton: 'No'
    }
  };

  userRole: any;

  modelActive: boolean = true;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private userData: UserService) { }

  // User Variables
  public lstUser: User[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;

    if (this.userRole != 1) {
      this.router.navigate(['/manager']);
    }

    if (SessionManager.SessionExist('userPage')) {
      this.gridPaging[0] = SessionManager.GetSession('userPage');
    }

    if (SessionManager.SessionExist('userSearch')) {
      this.gridSearch = SessionManager.GetSession('userSearch');
    }

    if (SessionManager.SessionExist('userToast')) {
      SessionManager.DeleteSession('userToast');
      AppSettings.openSnackBar(this._snackBar, 'User successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }

    this.userData.getAll(pageInfo.pageIndex, pageInfo.search, this.modelActive).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstUser = response.data.map(data => new User().deserialize(data));
    });
  }

  addClick() {
    this.router.navigate(['/manager/user/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('userPage', this.gridPaging[0].toString());
    SessionManager.SetSession('userSearch', this.gridSearch);
    this.router.navigate(['/manager/user/edit/' + value])
  }

  onDelete(value: any) {
    this.userData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }
  
  onActiveToggleChange(value: boolean) {
    this.modelActive = value;
    this.getData();
  }
  
}