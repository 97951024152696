<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body" *ngIf="lstLocation">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Full Name</mat-label>
                        <input matInput formControlName="name" [(ngModel)]="entUser.name">
                    </mat-form-field>
                </div>
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email" [(ngModel)]="entUser.email">
                    </mat-form-field>
                </div>
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Location</mat-label>
                        <mat-select formControlName="locationId" [(ngModel)]="entUser.locationId">
                            <mat-option *ngFor="let location of lstLocation" [value]="location.id">{{ location.locationName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Weekly Working Hours</mat-label>
                        <input matInput formControlName="workingHours" [(ngModel)]="entUser.workingHours">
                    </mat-form-field>
                </div>
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Birth Date</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="birthDate"
                            [(ngModel)]="entUser.birthDate">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1 disabled="true"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Admission Date</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="admissionDate"
                            [(ngModel)]="entUser.admissionDate">
                        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker #dp2 disabled="true"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Password <small>*Minimum character: 6</small></mat-label>
                        <input matInput type="password" formControlName="password" [(ngModel)]="entUser.password">
                    </mat-form-field>
                </div>
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Confirm Password</mat-label>
                        <input matInput type="password" formControlName="confirmPassword" [(ngModel)]="confirmPassword" (keyup)="onKey($event)">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()" style="float: right;">save</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>