import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'active' })
export class ActivePipe implements PipeTransform {
    transform(value: number): string {
        var information;
        switch (value) {
            case 1: {
                information = 'Yes';
                break;
            }
            case 0: {
                information = 'No';
                break;
            }
        }

        return information;
    }
}