<mat-toolbar>
    <mat-toolbar-row class="mat-elevation-z5"
        style="position: relative; background-image: url(../../../../assets/images/header_gradient.png); background-position: 203px 0px; background-repeat: no-repeat; height: 63px !important;">
        <div style="height: 100%; background: #ffffff; padding-left: 16px; padding-right: 16px; cursor: pointer;"
            (click)="menuClick()">
            <img alt="Atomos Hyla Logo" src="assets\images\atomosHylaLogo.svg" height="42" style="margin-top: 10px;">
        </div>
        <span class="spacer"></span>
        <span class="toolbar-notification">
            <button mat-icon-button [matMenuTriggerFor]="notifications" (menuClosed)="notificationClosed()">
                <mat-icon [matBadge]="notificationBadge > 0 ? notificationBadge : null" matBadgeColor="warn">
                    notifications
                </mat-icon>
            </button>
            <mat-menu #notifications="matMenu" style="padding: 0;">
                <div *ngIf="lstNotification != undefined && lstNotification.length > 0">
                    <div class="notification-content" *ngFor="let notification of lstNotification"
                        [ngClass]="{ 'read': notification.readDate != undefined }">
                        <div class="row" style="font-weight: 500;">{{ notification.title }}</div>
                        <div class="row" style="font-size: 12px; color: #808080; overflow-wrap: anywhere;">{{
                            notification.description }}</div>
                    </div>
                </div>
                <div *ngIf="lstNotification != undefined && lstNotification.length == 0">
                    <div class="notification-content read" style="width: 100%;">
                        <div class="row" style="font-size: 12px; color: #808080; overflow-wrap: anywhere; justify-content: center;">
                            <p style="margin: 12px 0px 12px;">No notifications to you.</p>
                        </div>
                    </div>
                </div>
            </mat-menu>
        </span>
        <span>
            <button mat-icon-button (click)="menuClick()">
                <mat-icon>
                    menu
                </mat-icon>
            </button>
        </span>
    </mat-toolbar-row>
    <mat-toolbar-row class="subtoolbar bg-primary-gray">
        <div class="page-title">
            {{ title }}
        </div>
        <div class="page-backgroud-image" style="background-image: url(../../../../assets/images/abstract_back.png);">
        </div>
    </mat-toolbar-row>
</mat-toolbar>