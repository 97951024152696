import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppSettings } from '../utils/settings';

import { VacationControl } from '../models/vacationControl.model';

import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class VacationControlService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.vacationControlEndPoint; //'https://localhost:44325/api/vacationcontrol';

    getAll(userId: number, registeredDate?: Date, allPeriods?: boolean) {
        var Period = '';

        if (registeredDate != undefined) {
            Period = '&registeredDate=' + registeredDate.getFullYear() + '-' + (registeredDate.getMonth() + 1) + '-' + registeredDate.getDate();
        }

        if (allPeriods == undefined) {
            allPeriods = false;
        }

        return this.http.get<VacationControl[]>(this.URI + '?userId=' + userId + Period + '&allPeriods=' + allPeriods).pipe(
            map(data => data.map(data => new VacationControl().deserialize(data)))
        );
    }

    getById(Id: number) {
        return this.http.get<VacationControl>(this.URI + '/' + Id).pipe(
            map(data => new VacationControl().deserialize(data))
        );
    }

    add(entVacationControl: VacationControl) {
        return this.http.post<VacationControl>(this.URI, entVacationControl);
    }

    update(entVacationControl: VacationControl) {
        return this.http.put<VacationControl>(this.URI + '/' + entVacationControl.id, entVacationControl);
    }

    delete(Id: number) {
        return this.http.delete<VacationControl>(this.URI + '/' + Id);
    }
}
