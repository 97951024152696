import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'activePriority' })
export class ActivePriorityPipe implements PipeTransform {
    transform(value: number): string {
        var information;
        switch (value) {
            case 0: {
                information = 'Critical';
                break;
            }
            case 1: {
                information = 'High';
                break;
            }
            case 2: {
                information = 'Medium';
                break;
            }
            case 3: {
                information = 'Low';
                break;
            }
        }

        return information;
    }
}