import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { HttpEventType } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

// File Storage
import { FileStorageService } from 'src/app/services/fileStorage.service';

// Invoice
import { Invoice } from '../../../models/invoice.model';
import { InvoiceService } from '../../../services/invoice.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  columns = [
    { prop: 'number', name: 'Number', maxWidth: 8 },
    { prop: 'po.project.projectName', name: 'Project', pipe: 'poproject', minWidth: 30 },
    { prop: 'po.number', name: 'Purchase Order', pipe: 'purchaseorder' },
    { prop: 'emissionDate', name: 'Emission Date', pipe: 'date' },
    { prop: 'totalValue', name: 'Total Value', pipe: 'currency' },
    { prop: 'netValue', name: 'Net Value', pipe: 'currency' },
    { prop: 'received', name: 'Received', pipe: 'active', maxWidth: 12 }
  ];

  dialogDelete = {
    col: 'number',
    data: {
      title: 'Confirm deletion', message: 'Do you want to delete the invoice', saveButton: 'Yes', cancelButton: 'No'
    }
  };

  userRole: number;
  userType: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private fileStorageData: FileStorageService,
    private invoiceData: InvoiceService) { }

  // Invoice Variables
  public lstInvoice: Invoice[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole == 3 && this.userType == 1) {
      this.router.navigate(['/manager']);
    }

    if (SessionManager.SessionExist('invoicePage')) {
      this.gridPaging[0] = SessionManager.GetSession('invoicePage');
    }

    if (SessionManager.SessionExist('invoiceSearch')) {
      this.gridSearch = SessionManager.GetSession('invoiceSearch');
    }

    if (SessionManager.SessionExist('invoiceToast')) {
      SessionManager.DeleteSession('invoiceToast');
      AppSettings.openSnackBar(this._snackBar, 'Invoice successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }

    this.invoiceData.getAll(pageInfo.pageIndex, pageInfo.search).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstInvoice = response.data.map(data => new Invoice().deserialize(data));
    });
  }

  addClick() {
    this.router.navigate(['/manager/invoice/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('invoicePage', this.gridPaging[0].toString());
    SessionManager.SetSession('invoiceSearch', this.gridSearch);
    this.router.navigate(['/manager/invoice/edit/' + value])
  }

  onDelete(value: any) {
    this.invoiceData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

  downloadClick(entInvoice: Invoice) {
    AppSettings.viewFirstFile(this.fileStorageData, HttpEventType, 2, entInvoice);
  }

}
