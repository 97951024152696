import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/utils/settings';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NotificationService } from 'src/app/services/notification.service';
import { Notification } from 'src/app/models/notification.model'

import { ProjectService } from 'src/app/services/project.service';
import { Project } from 'src/app/models/project.model';

import { ProjectUsers } from 'src/app/models/projectUsers.model';

import { LocationService } from 'src/app/services/location.service';
import { Location } from 'src/app/models/location.model';

import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss']
})
export class NotificationDetailComponent implements OnInit {
  public validationForm: FormGroup;

  modelType: number;
  modelParent: number;
  modelParentList: number[];
  parentTitle: string;
  lstModelParent: any[];

  responseError: string;

  constructor(private notificationData: NotificationService,
    private projectData: ProjectService,
    private locationData: LocationService,
    private userData: UserService,
    private _snackBar: MatSnackBar) { }

  public entNotification: Notification;

  public lstProject: Project[];

  public lstProjectUser: ProjectUsers[];

  public lstLocation: Location[];

  public lstUser: User[];

  ngOnInit(): void {
    this.entNotification = new Notification;

    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      parent: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required, Validators.maxLength(300)])
    });
  }

  onTypeChange() {
    this.lstModelParent = [];
    this.modelParentList = [];
    this.modelParent = undefined;
    this.modelParentList = undefined;

    if (this.modelType == 0) { // Location
      this.parentTitle = 'Location';
      this.locationData.getAll(-1, '').subscribe(response => {
        this.lstModelParent = response.data.map(data => new Location().deserialize(data));
      });
    } else if (this.modelType == 1) { // Project
      this.parentTitle = 'Project';
      this.projectData.getAll(-1, '', true).subscribe(response => {
        this.lstModelParent = response.data.map(data => new Project().deserialize(data));
      });
    } else { // Custom
      this.parentTitle = 'User';
      this.userData.getAll(-1, '').subscribe(response => {
        this.lstModelParent = response.data.map(data => new User().deserialize(data));
        this.lstModelParent = this.lstModelParent.filter(x => (x.dismissDate == undefined || x.dismissDate > new Date()) && x.type == 1);
      });
    }
  }

  onSubmit() {
    this.responseError = '';

    if (this.entNotification.description != undefined && this.entNotification.description.length > 300) {
      this.responseError = 'The description is too big. The limit is 300 characters.';
    }

    if (this.validationForm.invalid) {
      return;
    }

    this.save();
  }

  save() {
    if (this.modelType != 2) { // Location || Project
      this.modelParentList = [];
      this.modelParentList.push(this.modelParent);
    }

    this.notificationSave();
  }

  notificationSave() {
    this.notificationData.addByType(this.modelType, this.modelParentList, this.entNotification).subscribe(data => {
      this.entNotification = new Notification;
      this.modelType = undefined;
      this.modelParent = undefined;
      this.modelParentList = undefined;
      AppSettings.openSnackBar(this._snackBar, 'Notification sent', 5, AppSettings.Toast_Color_Success);
      this.validators();
    }, error => this.responseError = error.error.message)
  }

}
