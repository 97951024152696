import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as jwt_decode from 'jwt-decode';
import moment from 'moment';

// AppSettings
import { AppSettings } from 'src/app/utils/settings';

// Session Storage
import { SessionManager } from 'src/app/utils/session-manager';

// Customer
import { Client } from 'src/app//models/client.model';
import { ClientService } from 'src/app/services/client.service';

// File Storage
import { FileStorage } from 'src/app//models/fileStorage.model';
import { FileStorageService } from 'src/app/services/fileStorage.service';

// File Management 
import { FileManagementService } from 'src/app/services/fileManagement.service';

// Invoice
import { Invoice } from 'src/app//models/invoice.model';
import { InvoiceService } from 'src/app/services/invoice.service';

// Project
import { Project } from 'src/app/models/project.model';
import { ProjectService } from 'src/app/services/project.service';

// Purchase Order
import { PurchaseOrder } from 'src/app/models/purchaseOrder.model';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder.service';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {
  public validationForm: FormGroup;
  responseError: string;
  file: any;
  modelClient: number;
  modelProject: number;

  userRole: any;
  userType: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private clientData: ClientService,
    private fileStorageData: FileStorageService,
    private fileManagementData: FileManagementService,
    private invoiceData: InvoiceService,
    private projectData: ProjectService,
    private purchaseOrderData: PurchaseOrderService) { }

  // Customer Variables
  public lstClient: Client[];

  // File Storage Variables
  public entFileStorage: FileStorage;

  // Purchase Order Variables
  public lstPurchaseOrder: PurchaseOrder[];

  // Project Variables
  public lstProject: Project[];

  // Invoice Variables
  public entInvoice: Invoice;

  ngOnInit(): void {
    this.entInvoice = new Invoice();
    this.entFileStorage = new FileStorage();
    this.entFileStorage.id = 0;

    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole == 3 && this.userType == 1) {
      this.router.navigate(['/manager']);
    }

    this.validation();
    this.getData();
  }

  validation() {
    this.validationForm = new FormGroup({
      clientId: new FormControl('', [Validators.required]),
      projectId: new FormControl('', [Validators.required]),
      poId: new FormControl('', [Validators.required]),
      emissionDate: new FormControl('', [Validators.required]),
      invoiceType: new FormControl('', [Validators.required]),
      totalValue: new FormControl('', [Validators.required]),
      netValue: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      receivedDate: new FormControl('', null),
      receivedForecast: new FormControl('', [Validators.required])
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.invoiceData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entInvoice = response;

        this.purchaseOrderData.getAll(-1, '', false, this.entInvoice.id).subscribe(response => {
          this.lstPurchaseOrder = response.data.map(data => new PurchaseOrder().deserialize(data));
          this.modelClient = this.lstPurchaseOrder.find(x => x.id == this.entInvoice.poid).project.clientId;
          this.onCustomerChange(true);

          if (this.entInvoice.hasFile) {
            this.fileStorageData.getAll(2, this.entInvoice.id).subscribe(response => {
              if (response.length > 0) {
                this.entFileStorage = response[0];
              }
            });
          }
        });
      });
    } else {
      this.entInvoice.id = 0;
      this.entInvoice.received = false;
    }

    this.clientData.getAll(-1, '').subscribe(response => {
      this.lstClient = response.data.map(data => new Client().deserialize(data));

    });
  }

  onCustomerChange(edit?: boolean) {
    
    this.projectData.getAll(-1, '', false, null, this.modelClient).subscribe(response => {
      this.lstProject = response.data.map(data => new Project().deserialize(data));

      if (edit) {
        var project = this.lstProject.find(x => x.id == this.lstPurchaseOrder.find(x => x.id == this.entInvoice.poid).projectId);
        this.modelProject = project.id;
        if (!project.active) {
          this.validationForm.get('projectId').disable({ onlySelf: true });
        }
      } else {
        this.lstProject = this.lstProject.filter(x => x.active);
      }

    });
  }

  onProjectChange() {
    this.purchaseOrderData.getAll(-1, '', false, null, this.modelProject).subscribe(response => {
      this.lstPurchaseOrder = response.data.map(data => new PurchaseOrder().deserialize(data));

      if (!(this.entInvoice.id > 0)) {
        this.lstPurchaseOrder = this.lstPurchaseOrder.filter(x => x.active);
      }
    });
  }

  upload(files) {
    if (files.length === 0)
    return;
    
    if (files[0].type != 'application/pdf') {
      this.responseError = 'Only pdf is allowed';
      return;
    } else {
      this.responseError = '';
    }
    
    this.file = files;
    for (let file of this.file) {
      document.getElementById('fileName').innerHTML = file.name;
    }
    this.entFileStorage.registerType = 2;
  }

  downloadClick() {
    AppSettings.viewFirstFile(this.fileStorageData, HttpEventType, 2, this.entInvoice);
  }

  onSubmit() {
    this.responseError = '';

    if (this.responseError != '' || this.validationForm.invalid) {
      return;
    }

    if (this.entInvoice.invoiceType == 1) {
      if (this.entInvoice.totalValue <= this.entInvoice.netValue) {
        this.responseError = "When invoice type equal to service the net value must be less than the total value";
        return;
      }
    } else {
      if (this.entInvoice.totalValue != this.entInvoice.netValue) {
        this.responseError = "When invoice type equal to debit the net value must be equal to the total value";
        return;
      }
    }

    if (this.file != undefined) {
      this.entInvoice.hasFile = true;
    }

    this.save();
  }

  save() {
    if (this.entInvoice.receivedDate != undefined || this.entInvoice.receivedDate != null) {
      this.entInvoice.received = true;
    } else {
      this.entInvoice.received = false;
    }

    if (this.entInvoice.id == 0) {
      this.invoiceData.add(this.entInvoice).subscribe(data => {
        this.entFileStorage.referenceId = data.id;
        this.fileSave();
        this.showToast();
      }, error => this.responseError = error.error.message);
    } else {
      this.invoiceData.update(this.entInvoice).subscribe(data => {
        this.fileSave();
        this.showToast();
      }, error => this.responseError = error.error.message);
    }
  }

  fileSave() {
    if (this.file != undefined && this.file.length > 0) {
      if (this.entFileStorage.id == 0) { // Add file
        if (this.entInvoice.id > 0) { // Edit Invoice
          this.entFileStorage.referenceId = this.entInvoice.id;
        }

        this.entFileStorage.fileMime = this.file[0].type;
        //this.entFileStorage.fileName = this.file[0].name;

        var projectName = this.lstProject.find(x => x.id == this.modelProject).projectName;
        var emissionDate = moment(this.entInvoice.emissionDate).format("YYYYMMDD");
        //var emissionDate = this.entInvoice.emissionDate.getFullYear().toString()+(this.entInvoice.emissionDate.getMonth()+1).toString()+this.entInvoice.emissionDate.getDate().toString();

        this.entFileStorage.fileName = "Invoice " + this.entInvoice.number.toString() + " - " + projectName + " - " + emissionDate + ".pdf";

        this.fileStorageData.add(this.entFileStorage).subscribe(data => {
          this.entFileStorage.id = data.id;

          this.fileManagementSave();
        }, error => this.responseError = error.error.message);
      } else { //Edit File
        this.fileManagementSave();
      }
    } else {
      this.redirect();
    }
  }

  fileManagementSave() {
    const formData = new FormData();

    for (let file of this.file)
      formData.append(file.name, file);

    this.fileManagementData.add(this.entFileStorage.id, formData).subscribe(data => {
      this.redirect();
    }, error => this.responseError = error.error.message);
  }

  showToast() {
    this.purchaseOrderData.getBalance(this.entInvoice.poid).subscribe(data => {
      if (data < (this.entInvoice.totalValue * 2)) {
        this.addToast(data);
      }
    });
  }

  addToast(Value: number) {
    var Message = "The PO was automatically deactivated";

    if (Value > 0) {
      Message = "Only one more invoice will be possible.";
    }

    AppSettings.openSnackBar(this._snackBar, "The PO " + this.lstPurchaseOrder.find(x => x.id == this.entInvoice.poid).number + " currente balance is " + this.toMoneyFormat(Value) + ". " + Message, 10, AppSettings.Toast_Color_Error)
  }

  toMoneyFormat(Value: number) {
    return "R$ " + Value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
  }

  redirect() {
    SessionManager.SetSession('invoiceToast', '1');
    this.router.navigate(['/manager/invoice']);
  }

  cancelClick() {
    this.router.navigate(['/manager/invoice']);
  }

}
