import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Animations
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// App Component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Pipes
import { ActivePipe } from './utils/pipes/active.pipe';
import { MonthPipe } from './utils/pipes/month.pipe';
import { WeekdayPipe } from './utils/pipes/weekday.pipe';
import { ActivityTypePipe } from './utils/pipes/activityType.pipe';
import { HourTypePipe } from './utils/pipes/hourType.pipe';
import { LocationPipe } from './utils/pipes/location.pipe';
import { ZipCodePipe } from './utils/pipes/zipCode.pipe';
import { ActiveStatusPipe } from 'src/app/utils/pipes/activeStatus.pipe'
import { ActivePriorityPipe } from 'src/app/utils/pipes/activePriority.pipe'

// Elements
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { ScheduleModule, DayService, WeekService, WorkWeekService, MonthService, AgendaService } from '@syncfusion/ej2-angular-schedule';

// Validation
import { ReactiveFormsModule } from '@angular/forms';

// Http Interceptor
import { HttpRequestInterceptor } from './helpers/httpRequest.interceptor';
import { HttpReturnInterceptor } from './helpers/httpReturn.interceptor';

// Other Imports
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// No Template
import { NoTemplateModule } from './template/no-template/no-template.module';

// Common Template
import { CommonTemplateModule } from './template/common/common-template/common-template.module';

// Hyla Spinner
import { HylaSpinnerComponent } from './components/hyla-spinner/hyla-spinner.component';
import { HylaSpinnerService } from './components/hyla-spinner/hyla-spinner.service';

// Hyla Grid
import { HylaGridComponent } from './components/hyla-grid/hyla-grid.component';

// Hyla Dialog
import { HylaDialogComponent } from './components/hyla-dialog/hyla-dialog.component';

// Hyla Calendar
import { HylaCalendarComponent } from './components/hyla-calendar/hyla-calendar.component';

// Login
import { LoginComponent } from './pages/login/login.component';

// Annual Report
import { AnnualReportComponent } from './pages/annual-report/annual-report.component';

// Bill
import { BillListComponent } from './pages/bill/bill-list/bill-list.component';
import { BillDetailComponent } from './pages/bill/bill-detail/bill-detail.component';

// Bulk Add
import { BulkAddComponent } from './pages/bulk-add/bulk-add.component';

// Customer
import { CustomerListComponent } from './pages/customer/customer-list/customer-list.component';
import { CustomerDetailComponent } from './pages/customer/customer-detail/customer-detail.component';

// Dashboard
import { DashboardComponent } from './pages/dashboard/dashboard.component';

// Holidays
import { HolidaysListComponent } from './pages/holidays/holidays-list/holidays-list.component';
import { HolidaysDetailComponent } from './pages/holidays/holidays-detail/holidays-detail.component';

// Invoice
import { InvoiceListComponent } from './pages/invoice/invoice-list/invoice-list.component';
import { InvoiceDetailComponent } from './pages/invoice/invoice-detail/invoice-detail.component';

// Location
import { LocationListComponent } from './pages/location/location-list/location-list.component';
import { LocationDetailComponent } from './pages/location/location-detail/location-detail.component';

// Month Report
import { MonthReportComponent } from './pages/month-report/month-report.component';

// Monthly Closure
import { MonthlyClosureComponent } from './pages/monthly-closure/monthly-closure.component';

// My Tasks
import { MyTaskListComponent } from './pages/my-task/my-task-list/my-task-list.component';
import { MyTaskDetailComponent } from './pages/my-task/my-task-detail/my-task-detail.component';

// Notification
import { NotificationDetailComponent } from './pages/notification/notification-detail/notification-detail.component';

// Profile
import { ProfileComponent } from './pages/user/profile/profile.component';

// Project
import { ProjectListComponent } from './pages/project/project-list/project-list.component';
import { ProjectDetailComponent } from './pages/project/project-detail/project-detail.component';
import { ProjectActivitiesComponent } from './pages/project/project-activities/project-activities.component';
import { EffortReportComponent } from './pages/project/effort-report/effort-report.component';
import { ResourceEffortComponent } from './pages/project/resource-effort/resource-effort.component';

// Purchase Order
import { PurchaseOrderListComponent } from './pages/purchase-order/purchase-order-list/purchase-order-list.component';
import { PurchaseOrderDetailComponent } from './pages/purchase-order/purchase-order-detail/purchase-order-detail.component';

// Supplier
import { SupplierListComponent } from './pages/supplier/supplier-list/supplier-list.component';
import { SupplierDetailComponent } from './pages/supplier/supplier-detail/supplier-detail.component';

//Timesheet
import { TimesheetListComponent } from './pages/timesheet/timesheet-list/timesheet-list.component';
import { TimesheetDetailComponent } from './pages/timesheet/timesheet-detail/timesheet-detail.component';

// User
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { UserDetailComponent } from './pages/user/user-detail/user-detail.component';
import { DatePipe } from '@angular/common';

export class CustomDateAdapter extends NativeDateAdapter {

  parse(value: any): Date | null {

    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: Object): string {
    date = new Date(Date.UTC(
      date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
      date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    displayFormat = Object.assign({}, displayFormat, { timeZone: 'utc' });

    const dtf = new Intl.DateTimeFormat(this.locale, displayFormat);
    return dtf.format(date).replace(/[\u200e\u200f]/g, '');
  }

}

function pad(value) {
  return value.toString().padStart(2, 0);
}

Date.prototype.toJSON = function() {
  return `${this.getFullYear()}-${pad(this.getMonth() + 1)}-${pad(this.getDate())}T${pad(this.getHours())}:${pad(this.getMinutes())}:${pad(this.getSeconds())}`;
}

@NgModule({
  declarations: [
    AppComponent,
    HylaGridComponent,
    TimesheetListComponent,
    TimesheetDetailComponent,
    HylaSpinnerComponent,
    LoginComponent,
    ActivePipe,
    WeekdayPipe,
    MonthPipe,
    ActivityTypePipe,
    HourTypePipe,
    LocationPipe,
    ZipCodePipe,
    ActiveStatusPipe,
    ActivePriorityPipe,
    HylaDialogComponent,
    ProfileComponent,
    MonthlyClosureComponent,
    MonthReportComponent,
    AnnualReportComponent,
    ProjectListComponent,
    ProjectDetailComponent,
    PurchaseOrderListComponent,
    PurchaseOrderDetailComponent,
    InvoiceListComponent,
    InvoiceDetailComponent,
    LocationListComponent,
    LocationDetailComponent,
    HolidaysListComponent,
    HolidaysDetailComponent,
    UserListComponent,
    UserDetailComponent,
    CustomerListComponent,
    CustomerDetailComponent,
    DashboardComponent,
    BulkAddComponent,
    ProjectActivitiesComponent,
    SupplierListComponent,
    SupplierDetailComponent,
    BillListComponent,
    BillDetailComponent,
    EffortReportComponent,
    ResourceEffortComponent,
    HylaCalendarComponent,
    NotificationDetailComponent,
    MyTaskListComponent,
    MyTaskDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonTemplateModule,
    NoTemplateModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatBadgeModule,
    DragDropModule,
    PortalModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatTreeModule,
    ScheduleModule
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    HylaSpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpReturnInterceptor, multi: true },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'en-gb' },
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    DatePipe,
    WeekdayPipe,
    HourTypePipe,
    ActivityTypePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
