<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body" *ngIf="lstClient">
        <form [formGroup]="validationForm">
            <mat-tab-group [(selectedIndex)]="tabIndex">
                <mat-tab label="Project">
                    <div class="row" style="overflow: hidden; padding: 15px;">
                        <div class="col col-lg-4 col-md-6 col-sm-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Project Name</mat-label>
                                <input matInput formControlName="projectName" [(ngModel)]="entProject.projectName">
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-4 col-md-6 col-sm-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Customer</mat-label>
                                <mat-select formControlName="clientId" [(ngModel)]="entProject.clientId">
                                    <mat-option *ngFor="let client of lstClient" [value]="client.id">
                                        {{ client.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Estimated Start Date</mat-label>
                                <input matInput [matDatepicker]="dp1" formControlName="estimatedStartDate"
                                    [(ngModel)]="entProject.estimatedStartDate">
                                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                                <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-4 col-md-6 col-sm-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Estimated End Date</mat-label>
                                <input matInput [matDatepicker]="dp2" formControlName="estimatedEndDate"
                                    [(ngModel)]="entProject.estimatedEndDate">
                                <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                                <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-4 col-md-6 col-sm-8">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Started Date</mat-label>
                                <input matInput [matDatepicker]="dp3" formControlName="startedDate"
                                    [(ngModel)]="entProject.startedDate">
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-2 col-md-3 col-sm-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Hours Package</mat-label>
                                <input matInput type="number" formControlName="hoursPackage"
                                    [(ngModel)]="entProject.hoursPackage">
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-2 col-md-3 col-sm-4">
                            <mat-slide-toggle formControlName="active" [(ngModel)]="entProject.active">Active
                            </mat-slide-toggle>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Resources" [disabled]="userType == 2">
                    <div class="row" *ngIf="lstUser && userType == 1" style="padding: 15px;">
                        <div class="col col-md-5 mat-elevation-z8 card-table">
                            <mat-table #employeeTable [dataSource]="lstUser">

                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                    <mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle(0) : null"
                                            [checked]="employeeSelection.hasValue() && isAllSelected(0)"
                                            [indeterminate]="employeeSelection.hasValue() && !isAllSelected(0)"
                                            [aria-label]="checkboxLabel()">
                                        </mat-checkbox>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? employeeSelection.toggle(row) : null"
                                            [checked]="employeeSelection.isSelected(row)"
                                            [aria-label]="checkboxLabel(row, 0)">
                                        </mat-checkbox>
                                    </mat-cell>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef> Resource </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"
                                    (click)="employeeSelection.toggle(row)">
                                </mat-row>
                            </mat-table>
                        </div>
                        <div class="col-md-2 col-sm-12 button">
                            <button mat-mini-fab color="primary" (click)="addEmployeeProject()">
                                <mat-icon>arrow_forward</mat-icon>
                            </button>
                            <button mat-mini-fab color="primary" (click)="addEmployee()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <div class="col col-md-5 mat-elevation-z8 card-table" *ngIf="lstEmployeeProject">
                            <mat-table #employeeProjectTable [dataSource]="lstEmployeeProject">

                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                    <mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle(1) : null"
                                            [checked]="employeeProjectSelection.hasValue() && isAllSelected(1)"
                                            [indeterminate]="employeeProjectSelection.hasValue() && !isAllSelected(1)"
                                            [aria-label]="checkboxLabel()">
                                        </mat-checkbox>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? employeeProjectSelection.toggle(row) : null"
                                            [checked]="employeeProjectSelection.isSelected(row)"
                                            [aria-label]="checkboxLabel(row, 1)">
                                        </mat-checkbox>
                                    </mat-cell>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef> Resource </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"
                                    (click)="employeeProjectSelection.toggle(row)">
                                </mat-row>
                            </mat-table>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()" *ngIf="userType == 1">Save</button>
                    <button mat-button (click)="cancelClick()">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>