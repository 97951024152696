import { Deserializable } from './deserializable.model';
import { User } from './user.model';

export class Notification implements Deserializable {
  public id: number;
  public title: string;
  public description: string;
  public userId: number;
  public readDate: Date;

  public user: User;

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    if (this.readDate != null) {
      newObj.readDate = new Date(Number(this.readDate.toString().substr(0, 4)), Number(this.readDate.toString().substr(5, 2)) - 1, Number(this.readDate.toString().substr(8, 2)));
    }

    return newObj;
  }
}
