import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../models/location.model';

@Pipe({ name: 'location' })
export class LocationPipe implements PipeTransform {

    transform(value: number, lstLocation: Location[]): string {
        var LocationName = 'All';

        if (value > 0) {
            LocationName = lstLocation.find(x => x.id == value).locationName;
        }

        return LocationName;
    }
}