<!-- <hyla-grid [page]="gridPaging[0]" [totalRecords]="gridPaging[1]" [data]="lstTimesheet"
    [colNames]="columns" [dialogDelete]="dialogDelete" [searchText]="gridSearch" (setPageEvent)="getData($event)" (newEvent)="addClick()"
    (editEvent)="onEdit($event)" (deleteEvent)="onDelete($event)"></hyla-grid> -->

<div class="hyla-grid-header-container">
    <mat-card>
        <span class="spacer"></span>
        <span>
            <button mat-mini-fab color="primary" class="hyla-grid-header-add-button" (click)="addClick()">
                <mat-icon>
                    add
                </mat-icon>
            </button>
        </span>
    </mat-card>
</div>
<div class="hyla-grid-card-container" id="containt">
    <mat-card>
        <ejs-schedule #scheduleObj [selectedDate]="selectedDate" [currentView]="currentView"
            [eventSettings]="eventSettings" [views]="views" [rowAutoHeight]="true" (created)="onCreated()"
            (actionComplete)="onActionComplete($event)" (popupOpen)="onPopupOpen($event)">

            <ng-template #eventSettingsTemplate let-data>
                <div class="row"
                    [ngClass]="{ 'bg-orange': data.HourType == 2, 'bg-red': data.HourType == 3, 'bg-blue': data.HourType == 4, 'bg-white': data.HourType == 5, 'bg-lightblue': data.HourType == 6 }">
                    <div class="col-12">{{ data.Subject }}</div>
                    <div class="col-4" style="font-size: 10px;">Hours: {{ data.RegisteredHours }}</div>
                    <div class="col-8" style="font-size: 10px;">Type: {{ data.HourType | hourType }}</div>
                </div>
            </ng-template>

            <!-- Header template -->
            <ng-template #quickInfoTemplatesHeader let-data>
                <div *ngIf="data.elementType == 'cell' || data.elementType == 'event'">
                    <div class="e-popup-header"
                        [ngClass]="{ 'bg-primary-green': data.HourType == 1, 'bg-orange': data.HourType == 2, 'bg-red': data.HourType == 3, 'bg-blue': data.HourType == 4, 'bg-white': data.HourType == 5, 'bg-lightblue': data.HourType == 6 }">
                        <div class="e-header-icon-wrapper">
                            <div class="event-date">{{ data.StartTime | date: 'dd/MM/yyyy' }}</div>
                            <button *ngIf="data.elementType == 'event'" mat-icon-button aria-label="Edit Event"
                                class="event-icon" (click)="onEditClick(data.Id)" [disabled]="data.HourType == 6">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button *ngIf="data.elementType == 'event'" mat-icon-button aria-label="Delete Event"
                                class="event-icon" (click)="onDeleteClick(data.Id)" [disabled]="data.HourType == 6">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button aria-label="Close Modal"
                                [ngClass]="{ 'event-icon': data.elementType == 'event' }" (click)="onCloseClick()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="data.elementType == 'event'" class="event-subject">{{data.Subject}}</div>
                    </div>
                </div>
            </ng-template>

            <!-- Content Template -->
            <ng-template #quickInfoTemplatesContent let-data>
                <div *ngIf="data.elementType == 'event'" class="row event-content">
                    <div class="col-6"><span class="event-subtitle">Registered Hours:</span> {{ data.RegisteredHours }}
                    </div>
                    <div class="col-6"><span class="event-subtitle">Hour Type:</span> {{ data.HourType | hourType }}
                    </div>
                    <div class="col-12"><span class="event-subtitle">Activity Type:</span> {{ data.ActivityType |
                        activityType }}</div>
                </div>
            </ng-template>
        </ejs-schedule>
    </mat-card>
</div>