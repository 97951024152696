import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as jwt_decode from 'jwt-decode';

// App Settings
import { AppSettings } from 'src/app/utils/settings';

// Holidays
import { Holidays } from 'src/app/models/holidays.model';
import { HolidaysService } from 'src/app/services/holidays.service';

// Timesheet
import { Timesheet } from 'src/app/models/timesheet.model';
import { TimesheetService } from 'src/app/services/timesheet.service';

// User
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

// Project
import { Project } from 'src/app/models/project.model';
import { ProjectService } from 'src/app/services/project.service';

// Project Users
import { ProjectUsers } from 'src/app/models/projectUsers.model';
import { ProjectUsersService } from 'src/app/services/projectUsers.service';

// Vacation Control Users
import { VacationControlService } from 'src/app/services/vacationControl.service';

@Component({
  selector: 'app-bulk-add',
  templateUrl: './bulk-add.component.html',
  styleUrls: ['./bulk-add.component.scss']
})
export class BulkAddComponent implements OnInit {
  public validationForm: FormGroup;
  responseError: any;
  commentValidation: boolean = false;
  modelStartDate: Date;
  modelEndDate: Date;
  modelSoldVacation: boolean;

  userRole: any;
  userType: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    private holidaysData: HolidaysService,
    private timesheetData: TimesheetService,
    private userData: UserService,
    private projectData: ProjectService,
    private projectUsersData: ProjectUsersService,
    private vacationControlData: VacationControlService) { }

  // Holidays Variables 
  public lstHolidays: Holidays[];

  // Timesheet Variables
  public entTimesheet: Timesheet;

  // User Variables
  public lstUser: User[];

  // Project Variables
  public lstProject: Project[] = [];
  public lstProjectUsers: ProjectUsers[] = [];

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole != 1 && this.userRole != 2 && this.userType != 1) {
      this.router.navigate(['/manager']);
    }

    this.entTimesheet = new Timesheet();

    this.getData();
    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      employee: new FormControl('', [Validators.required]),
      project: new FormControl('', [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      registeredHours: new FormControl('', [Validators.required]),
      activityType: new FormControl('', [Validators.required]),
      hourType: new FormControl('', [Validators.required]),
      soldVacation: new FormControl('', null),
      comment: new FormControl('', null)
    });
  }

  getData() {
    this.entTimesheet.id = 0;

    this.userData.getAll(0, '').subscribe(response => {
      this.lstUser = response.data.map(data => new User().deserialize(data)).filter(x => x.type != 2);

    });
  }

  onSelectUser() {
    this.projectData.getAll(-1, '', false, this.entTimesheet.userId).subscribe(response => {
      this.lstProject = response.data.map(data => new Project().deserialize(data));

      this.projectUsersData.getAll(0, this.entTimesheet.userId).subscribe(response => {
        if (response.length > 0) {
          this.lstProjectUsers = response;
        } else {
          this.lstProjectUsers = [];
        }

        this.entTimesheet.registeredHours = this.lstUser.find(x => x.id == this.entTimesheet.userId).workingHours / 5;

      });
    });

  }

  onSelectHourType() {
    if (this.entTimesheet.hourType == 6) {
      this.entTimesheet.activityType = 9;
    }
  }

  disabledVacation() {
    var disabled = false;
    var date = new Date(this.modelEndDate);
    date.setDate(date.getDate() - 9);

    if (date < this.modelStartDate || this.modelStartDate == undefined || this.modelEndDate == undefined) {
      disabled = true;
    }

    return disabled;
  }

  projectName(projectId: number) {
    return this.lstProject.find(x => x.id == projectId).projectName;
  }

  onSubmit() {
    if (this.entTimesheet.hourType != 6) {
      this.holidaysData.getAll(0, '', this.modelStartDate, this.modelEndDate).subscribe(response => {
        var holidaysData = response.data.map(data => new Holidays().deserialize(data));
        if (holidaysData.length > 0) {
          this.lstHolidays = holidaysData;
        } else {
          this.lstHolidays = [];
        }

        this.validation();

      });
    } else {
      this.validation();
    }
  }

  validation() {
    this.commentValidation = false;
    this.responseError = '';
    var registerHoursValidation = this.entTimesheet.registeredHours / 0.5;

    if (this.validationForm.invalid) {
      return;
    }

    if (this.modelStartDate > this.modelEndDate) {
      this.responseError = "It is not possible to register this data range";
      return;
    }

    if (!Number.isInteger(registerHoursValidation)) {
      this.responseError = "It is not possible to register this fraction of hour. The only fraction accepted is .5";
      return;
    }

    if ((this.entTimesheet.hourType == 5 && this.entTimesheet.comment == undefined) || (this.entTimesheet.hourType == 5 && this.entTimesheet.comment == '')) {
      this.responseError = "Comment is required if hour type equal to extra hour or license";
      return;
    }

    this.save();
  }

  save() {
    this.entTimesheet.registeredDate = new Date(this.modelStartDate);
    var soldVacation = this.modelSoldVacation;

    this.entTimesheet.registeredDate.setDate(this.entTimesheet.registeredDate.getDate() - 1);
    this.add(soldVacation);

  }

  add(soldVacation: boolean) {
    this.entTimesheet.registeredDate.setDate(this.entTimesheet.registeredDate.getDate() + 1);
    var difference = Math.abs(new Date(this.modelStartDate).getTime() - new Date(this.modelEndDate).getTime());
    difference = difference / (1000 * 3600 * 24)

    if ((this.entTimesheet.registeredDate <= this.modelEndDate && this.entTimesheet.hourType == 6) || (this.entTimesheet.registeredDate <= this.modelEndDate && ((this.entTimesheet.registeredDate.getDay() != 0 && this.entTimesheet.registeredDate.getDay() != 6) && this.lstHolidays.filter(x => (x.locationId == this.lstUser.find(x => x.id == this.entTimesheet.userId).locationId || x.locationId == 0) && x.date.getDate() == this.entTimesheet.registeredDate.getDate() && x.date.getMonth() == this.entTimesheet.registeredDate.getMonth() && x.date.getFullYear() == this.entTimesheet.registeredDate.getFullYear()).length == 0))) {
      this.timesheetData.add(this.entTimesheet).subscribe(data => {

        if (this.entTimesheet.hourType == 6) {
          this.vacationControlData.getAll(this.entTimesheet.userId, this.entTimesheet.registeredDate).subscribe(response => {
            if (response.length > 0) {
              var entVacationControl = response[0];

              if (entVacationControl.soldVacation == 10 && soldVacation) {
                this.responseError = "The user already sold vacation days for the current vacation period";
              } else if (entVacationControl.vacationDays + difference + 10 >= 30 && soldVacation) {
                this.responseError = "The period selected exceed the days of the current vacation period";
              } else if ((entVacationControl.vacationDays + entVacationControl.soldVacation) < 30) {
                entVacationControl.vacationDays += 1;

                if (soldVacation) {
                  entVacationControl.soldVacation = 10;
                  soldVacation = false;
                }

                this.vacationControlData.update(entVacationControl).subscribe(data => {
                  this.successSnackBar();
                  this.add(soldVacation);
                });

              }
            } else {
              this.vacationControlData.getAll(this.entTimesheet.userId).subscribe(response => {
                if (response.length > 0) {
                  var entVacationControl = response[0];

                  if (entVacationControl.vacationDays + entVacationControl.soldVacation < 30) {

                    if (entVacationControl.soldVacation == 10 && soldVacation) {
                      this.responseError = "The user already sold vacation days for the current vacation period";
                    } else if (entVacationControl.vacationDays + difference + 10 >= 30 && soldVacation) {
                      this.responseError = "The period selected exceed the days of the current vacation period";
                    } else {
                      entVacationControl.vacationDays += 1;

                      if (soldVacation) {
                        entVacationControl.soldVacation = 10;
                        soldVacation = false;
                      }

                      this.vacationControlData.update(entVacationControl).subscribe(data => {
                        this.successSnackBar();
                        this.add(soldVacation);
                      });
                    }
                  }
                }
              });
            }
          });
        } else {
          this.successSnackBar();
          this.add(soldVacation);
        }

      }, error => this.responseError = error.error.message);
    } else {
      this.add(soldVacation);
    }
  }

  successSnackBar() {
    if (this.entTimesheet.registeredDate.getDate() == this.modelEndDate.getDate() && this.entTimesheet.registeredDate.getMonth() == this.modelEndDate.getMonth() && this.entTimesheet.registeredDate.getFullYear() == this.modelEndDate.getFullYear()) {
      AppSettings.openSnackBar(this._snackBar, 'Timesheet successfully saved', 5, AppSettings.Toast_Color_Success);
    }

  }

}
