import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

// Customer
import { Client } from '../../../models/client.model';
import { ClientService } from '../../../services/client.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  columns = [{ prop: 'name', name: 'Customer' }, { prop: 'taxNumber', name: 'Tax Number' }, { prop: 'city', name: 'City' }, { prop: 'state', name: 'State' }];
  dialogDelete = { col: 'name', data: { title: 'Confirm deletion', message: 'Do you want to delete the customer', saveButton: 'Yes', cancelButton: 'No' } };
  userRole: any;
  delete: boolean = false;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private clientData: ClientService) { }

  // Customer Variables 
  public lstClient: Client[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;

    if (this.userRole != 1 && this.userRole != 2) {
      this.router.navigate(['/manager']);
    }

    if (SessionManager.SessionExist('customerPage')) {
      this.gridPaging[0] = SessionManager.GetSession('customerPage');
    }

    if (SessionManager.SessionExist('customerSearch')) {
      this.gridSearch = SessionManager.GetSession('customerSearch');
    }

    if (SessionManager.SessionExist('customerToast')) {
      SessionManager.DeleteSession('customerToast');
      AppSettings.openSnackBar(this._snackBar, 'Customer successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }

    this.clientData.getAll(pageInfo.pageIndex, pageInfo.search).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstClient = response.data.map(data => new Client().deserialize(data));
    });
  }

  addClick() {
    this.router.navigate(['/manager/customer/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('customerPage', this.gridPaging[0].toString());
    SessionManager.SetSession('customerSearch', this.gridSearch);
    this.router.navigate(['/manager/customer/edit/' + value])
  }

  onDelete(value: any) {
    this.clientData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

}
