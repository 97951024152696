import { Deserializable } from './deserializable.model';
import { Project } from './project.model';

export class Sprint implements Deserializable {
  public id: number;
  public name: string;
  public plannedStartDate: Date;
  public plannedEndDate: Date;
  public actualStartDate: Date;
  public actualEndDate: Date;
  public plannedEffort: number;
  public actualEffort: number;
  public projectId: number;

  public project: Project;

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    newObj.plannedStartDate = new Date(Number(this.plannedStartDate.toString().substr(0, 4)), Number(this.plannedStartDate.toString().substr(5, 2)) - 1, Number(this.plannedStartDate.toString().substr(8, 2)));
    newObj.plannedEndDate = new Date(Number(this.plannedEndDate.toString().substr(0, 4)), Number(this.plannedEndDate.toString().substr(5, 2)) - 1, Number(this.plannedEndDate.toString().substr(8, 2)));

    if (this.actualStartDate) {
      newObj.actualStartDate = new Date(Number(this.actualStartDate.toString().substr(0, 4)), Number(this.actualStartDate.toString().substr(5, 2)) - 1, Number(this.actualStartDate.toString().substr(8, 2)));
    }

    if (this.actualEndDate) {
      newObj.actualEndDate = new Date(Number(this.actualEndDate.toString().substr(0, 4)), Number(this.actualEndDate.toString().substr(5, 2)) - 1, Number(this.actualEndDate.toString().substr(8, 2)));
    }

    return newObj;
  }
}
