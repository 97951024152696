import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { TimesheetBalance } from '../models/timesheetBalance.model';

@Injectable({ providedIn: 'root' })
export class TimesheetBalanceService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.timesheetBalanceEndPoint; //'https://localhost:44325/api/timesheetbalance';


    AuthenticateTimesheet(entTimesheetBalance: TimesheetBalance) {
        return this.http.post<TimesheetBalance>(this.URI + '/authenticate', entTimesheetBalance);
    }

    getAll(userId: number, year: number, month?: number) {
        var Period = 'month=';
        if (month != undefined) {
            Period += month;
        }
        return this.http.get<TimesheetBalance[]>(this.URI + '?' + 'userId=' + userId + '&' + 'year=' + year + '&' +  Period).pipe(
            map(data => data.map(data => new TimesheetBalance().deserialize(data)))
        );
    }

    getById(Id: number) {
        return this.http.get<TimesheetBalance>(this.URI + '/' + Id).pipe(
            map(data => new TimesheetBalance().deserialize(data))
        );
    }

    add(entTimesheetBalance: TimesheetBalance) {
        return this.http.post<TimesheetBalance>(this.URI, entTimesheetBalance);
    }

    update(entTimesheetBalance: TimesheetBalance) {
        return this.http.put<TimesheetBalance>(this.URI + '/' + entTimesheetBalance.id, entTimesheetBalance);
    }

    delete(Id: number) {
        return this.http.delete<TimesheetBalance>(this.URI + '/' + Id);
    }
}
