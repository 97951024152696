import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import moment from 'moment';

// AppSettings
import { AppSettings } from 'src/app/utils/settings';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Customer
import { Client } from '../../../models/client.model';
import { ClientService } from 'src/app/services/client.service';

// File Storage
import { FileStorage } from '../../../models/fileStorage.model';
import { FileStorageService } from 'src/app/services/fileStorage.service';

// File Management 
import { FileManagementService } from 'src/app/services/fileManagement.service';

// Project
import { Project } from '../../../models/project.model';
import { ProjectService } from 'src/app/services/project.service';

// Purchase Order
import { PurchaseOrder } from '../../../models/purchaseOrder.model';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder.service';

@Component({
  selector: 'app-purchase-order-detail',
  templateUrl: './purchase-order-detail.component.html',
  styleUrls: ['./purchase-order-detail.component.scss']
})
export class PurchaseOrderDetailComponent implements OnInit {
  public validationForm: FormGroup;
  responseError: string;
  file: any;

  modelClient: number;

  userRole: any;
  userType: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private clientData: ClientService,
    private fileStorageData: FileStorageService,
    private fileManagementData: FileManagementService,
    private projectData: ProjectService,
    private purchaseOrderData: PurchaseOrderService,) { }

  // Customer Variables
  public lstClient: Client[];

  // File Storage Variables
  public entFileStorage: FileStorage;

  // Project Variables
  public lstProject: Project[];

  // Purchase Order Variables
  public entPurchaseOrder: PurchaseOrder;

  ngOnInit(): void {
    this.entPurchaseOrder = new PurchaseOrder();
    this.entFileStorage = new FileStorage();
    this.entFileStorage.id = 0;

    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole == 3 && this.userType == 1) {
      this.router.navigate(['/manager']);
    }

    this.validation();
    this.getData();
  }

  validation() {
    this.validationForm = new FormGroup({
      clientId: new FormControl('', [Validators.required]),
      projectId: new FormControl('', [Validators.required]),
      emissionDate: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      item1Code: new FormControl('', [Validators.required]),
      item1Description: new FormControl('', [Validators.required]),
      item1Value: new FormControl('', [Validators.required]),
      item2Code: new FormControl('', null),
      item2Description: new FormControl('', null),
      item2Value: new FormControl('', null),
      item3Code: new FormControl('', null),
      item3Description: new FormControl('', null),
      item3Value: new FormControl('', null),
      totalValue: new FormControl('', null),
      active: new FormControl('', null)
    });

    this.validationForm.get('totalValue').disable({ onlySelf: true })
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.purchaseOrderData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entPurchaseOrder = response;

        this.modelClient = this.entPurchaseOrder.project.clientId;
        this.onCustomerChange(true);

        if (this.entPurchaseOrder.hasFile) {
          this.fileStorageData.getAll(1, this.entPurchaseOrder.id).subscribe(response => {
            if (response.length > 0) {
              this.entFileStorage = response[0];
            }
          });
        }
      });
    } else {
      this.entPurchaseOrder.id = 0;
      this.entPurchaseOrder.active = true;
      this.entPurchaseOrder.item1Value = 0;
      this.entPurchaseOrder.item2Value = 0;
      this.entPurchaseOrder.item3Value = 0;
    }

    this.clientData.getAll(-1, '').subscribe(response => {
      this.lstClient = response.data.map(data => new Client().deserialize(data));

    });
  }

  onCustomerChange(edit?: boolean) {
    this.projectData.getAll(-1, '', false, null, this.modelClient).subscribe(response => {
      this.lstProject = response.data.map(data => new Project().deserialize(data));

      if (edit) {
        var project = this.lstProject.find(x => x.id == this.entPurchaseOrder.projectId);
        if (!project.active) {
          this.validationForm.get('projectId').disable({ onlySelf: true });
        }
      } else {
        this.lstProject = this.lstProject.filter(x => x.active);
      }
    });
  }

  onValueChange() {
    this.entPurchaseOrder.totalValue = (parseInt(String(this.entPurchaseOrder.item1Value)) + parseInt(String(this.entPurchaseOrder.item2Value)) + parseInt(String(this.entPurchaseOrder.item3Value)));
  }

  upload(files) {
    if (files.length === 0)
      return;

    if (files[0].type != 'application/pdf') {
      this.responseError = 'Only pdf is allowed';
      return;
    } else {
      this.responseError = '';
    }

    this.file = files;
    for (let file of this.file) {
      document.getElementById('fileName').innerHTML = file.name;
    }
    this.entFileStorage.registerType = 1;
  }

  downloadClick() {
    AppSettings.viewFirstFile(this.fileStorageData, HttpEventType, 1, this.entPurchaseOrder);
  }

  onSubmit() {
    this.responseError = '';

    if (this.responseError != '' || this.validationForm.invalid) {
      return;
    }

    if (this.file != undefined) {
      this.entPurchaseOrder.hasFile = true;
    }

    this.save();
  }

  save() {
    if (this.entPurchaseOrder.id == 0) { // Add PO
      this.purchaseOrderData.add(this.entPurchaseOrder).subscribe(data => {
        this.entFileStorage.referenceId = data.id;
        this.fileSave();
      }, error => this.responseError = error.error.message);
    } else { // Edit PO
      this.purchaseOrderData.update(this.entPurchaseOrder).subscribe(data => {
        this.fileSave();
      }, error => this.responseError = error.error.message);
    }
  }

  fileSave() {
    if (this.file != undefined && this.file.length > 0) {

      if (this.entFileStorage.id == 0) { //Add file
        if (this.entPurchaseOrder.id > 0) {
          this.entFileStorage.referenceId = this.entPurchaseOrder.id;
        }

        this.entFileStorage.fileMime = this.file[0].type;
        //this.entFileStorage.fileName = this.file[0].name;
        var customerName = this.lstClient.find(x => x.id == this.modelClient).name;
        var emissionDate = moment(this.entPurchaseOrder.emissionDate).format("YYYYMMDD");
        var projectName = this.lstProject.find(x => x.id == this.entPurchaseOrder.projectId).projectName;
        this.entFileStorage.fileName = "PO " + this.entPurchaseOrder.number.toString() + " - " + customerName + " - " + projectName + " - " + emissionDate + ".pdf";

        this.fileStorageData.add(this.entFileStorage).subscribe(data => {
          this.entFileStorage.id = data.id;

          this.fileManagementSave();
        }, error => this.responseError = error.error.message);
      } else { // Edit File
        this.fileManagementSave();
      }
    } else {
      this.redirect();
    }
  }

  fileManagementSave() {
    const formData = new FormData();

    for (let file of this.file)
      formData.append(file.name, file);

    this.fileManagementData.add(this.entFileStorage.id, formData).subscribe(data => {
      this.redirect();
    }, error => this.responseError = error.error.message);
  }

  cancelClick() {
    this.router.navigate(['/manager/purchaseorder']);
  }

  redirect() {
    SessionManager.SetSession('purchaseOrderToast', '1');
    this.router.navigate(['/manager/purchaseorder']);
  }

}
