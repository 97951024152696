import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

// Location
import { Location } from '../../../models/location.model';
import { LocationService } from '../../../services/location.service';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit {
  columns = [{ prop: 'locationName', name: 'Location' }, { prop: 'city', name: 'City' }, { prop: 'state', name: 'State' }, { prop: 'zipCode', name: 'ZipCode', pipe: 'zipCode' }];
  dialogDelete = { col: 'locationName', data: { title: 'Confirm deletion', message: 'Do you want to delete the location', saveButton: 'Yes', cancelButton: 'No' } };
  userRole: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private locationData: LocationService) { }

  // Location Variables 
  public lstLocation: Location[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;

    if (this.userRole != 1 && this.userRole != 2) {
      this.router.navigate(['/manager']);
    }
    
    if (SessionManager.SessionExist('locationPage')) {
      this.gridPaging[0] = SessionManager.GetSession('locationPage');
    }

    if (SessionManager.SessionExist('locationSearch')) {
      this.gridSearch = SessionManager.GetSession('locationSearch');
    }

    if (SessionManager.SessionExist('locationToast')) {
      SessionManager.DeleteSession('locationToast');
      AppSettings.openSnackBar(this._snackBar, 'Location successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }

    this.locationData.getAll(pageInfo.pageIndex, pageInfo.search).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstLocation = response.data.map(data => new Location().deserialize(data));
    });
  }

  addClick() {
    this.router.navigate(['/manager/location/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('locationPage', this.gridPaging[0].toString());
    SessionManager.SetSession('locationSearch', this.gridSearch);
    this.router.navigate(['/manager/location/edit/' + value])
  }

  onDelete(value: any) {
    this.locationData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

}
