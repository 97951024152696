import { Component, OnInit } from '@angular/core';
import * as jwt_decode from 'jwt-decode';

// User
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'hyla-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  userRole: number;
  userType: number;
  userId: any;
  userName: any;

  constructor(private userData: UserService) { }

  // User Variables
  public entUser: User;

  ngOnInit(): void {
    var entUser = jwt_decode(localStorage.getItem('currentUser'))
    this.userRole = entUser.role;
    this.userType = entUser.Type;
    this.userId = entUser.userId;
    this.userName = entUser.unique_name;
  }

  logOut() {
    localStorage.removeItem('currentUser');
  }
}
