import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Invoice } from '../models/invoice.model';

import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.invoiceEndPoint; //'https://localhost:44325/api/invoice';


    getAll(page: number, search: string, POId?: number) {
        if (search == undefined) {
            search = "";
        }
        if (POId == undefined) {
            POId = 0;
        }
        
        return this.http.get<ResponseData>(this.URI + '?POId=' + POId + '&page=' + page + '&pageSize=' + AppSettings.pageSize + '&search=' + search).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<Invoice>(this.URI + '/' + Id).pipe(
            map(data => new Invoice().deserialize(data))
        );
    }

    add(entInvoice: Invoice) {
        return this.http.post<Invoice>(this.URI, entInvoice);
    }

    update(entInvoice: Invoice) {
        return this.http.put<Invoice>(this.URI + '/' + entInvoice.id, entInvoice);
    }

    delete(Id: number) {
        return this.http.delete<Invoice>(this.URI + '/' + Id);
    }
}