import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Supplier } from '../models/supplier.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class SupplierService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.supplierEndPoint; //'https://localhost:44325/api/supplier';

    getAll(page: number, search: string) {
        if (search == undefined) { search = ""; }

        return this.http.get<ResponseData>(this.URI + "?page=" + page + "&pageSize=" + AppSettings.pageSize + "&search=" + search).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<Supplier>(this.URI + '/' + Id).pipe(
            map(data => new Supplier().deserialize(data))
        );
    }

    add(entSupplier: Supplier) {
        return this.http.post<Supplier>(this.URI, entSupplier);
    }

    update(entSupplier: Supplier) {
        return this.http.put<Supplier>(this.URI + '/' + entSupplier.id, entSupplier);
    }

    delete(Id: number) {
        return this.http.delete<Supplier>(this.URI + '/' + Id);
    }
}