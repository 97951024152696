import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Notification } from '../models/notification.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.notificationEndPoint; // 'https://localhost:44325/api/notification';

    getAll() {
        return this.http.get<ResponseData>(this.URI).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<Notification>(this.URI + '/' + Id).pipe(
            map(data => new Notification().deserialize(data))
        );
    }

    getAllByUserId(userId: number) {
        return this.http.get<Notification[]>(this.URI + '/GetAllByUserId/' + userId).pipe(
            map(data => data.map(data => new Notification().deserialize(data)))
        );
    }

    add(entNotification: Notification) {
        return this.http.post<Notification>(this.URI, entNotification);
    }

    addByType(type: number, parentId: number[], entNotification: Notification) {
        var queryParameters = '?type=' + type;
        parentId.forEach(Id => {
            queryParameters += ('&parentId=' + Id);
        });

        return this.http.post<Notification>(this.URI + '/PostByType' + queryParameters, entNotification);
    }

    update(entNotification: Notification) {
        return this.http.put<Notification>(this.URI + '/' + entNotification.id, entNotification);
    }

    updateByUserId(userId: number) {
        return this.http.put<Notification>(this.URI + '/PutByUserId/' + userId, null);
    }

    delete(Id: number) {
        return this.http.delete<Notification>(this.URI + '/' + Id);
    }
}