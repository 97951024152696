import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Supplier
import { Supplier } from 'src/app/models/supplier.model';
import { SupplierService } from 'src/app/services/supplier.service';

@Component({
  selector: 'app-supplier-detail',
  templateUrl: './supplier-detail.component.html',
  styleUrls: ['./supplier-detail.component.scss']
})
export class SupplierDetailComponent implements OnInit {
  public validationForm: FormGroup;
  responseError: string;

  userRole: any;
  userType: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private supplierData: SupplierService,) { }

  public entSupplier: Supplier;

  ngOnInit(): void {
    this.entSupplier = new Supplier();

    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole == 3 && this.userType == 1) {
      this.router.navigate(['/manager']);
    }

    this.validation();
    this.getData();
  }

  validation() {
    this.validationForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      fiscalName: new FormControl('', [Validators.required]),
      taxNumber: new FormControl('', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]),
      municipalTaxNumber: new FormControl('', null),
      stateTaxNumber: new FormControl('', null),
      address: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl('', null),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      contact: new FormControl('', null),
      phone: new FormControl('', null),
      observation: new FormControl('', null),
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.supplierData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entSupplier = response;
      });
    } else {
      this.entSupplier.id = 0;
    }
  }

  zipCodeMask(event: any) {
    if (this.entSupplier.zipCode != undefined) {
      if (this.entSupplier.zipCode.length == 5) {
        this.entSupplier.zipCode = this.entSupplier.zipCode + '-';
      } else if (this.entSupplier.zipCode.length > 9) {
        this.entSupplier.zipCode = this.entSupplier.zipCode.substr(0, 9);
      }
    }
  }

  onSubmit() {
    this.responseError = '';

    if (this.responseError != '' || this.validationForm.invalid) {
      return;
    }

    this.save();
  }

  save() {
    if (this.entSupplier.id == 0) { // Add Supplier
      this.supplierData.add(this.entSupplier).subscribe(data => {
        this.redirect();
      }, error => this.responseError = error.error.message);
    } else { // Edit Supplier
      this.supplierData.update(this.entSupplier).subscribe(data => {
        this.redirect();
      }, error => this.responseError = error.error.message);
    }
  }

  cancelClick() {
    this.router.navigate(['/manager/supplier']);
  }

  redirect() {
    SessionManager.SetSession('supplierToast', '1');
    this.router.navigate(['/manager/supplier']);
  }

}
