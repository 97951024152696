import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// User
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

// Location
import { Location } from 'src/app/models/location.model';
import { LocationService } from 'src/app/services/location.service';

// Vacation Control
import { VacationControl } from 'src/app/models/vacationControl.model';
import { VacationControlService } from 'src/app/services/vacationControl.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  colNames = [{ prop: 'startDate', name: 'Start Date', pipe: 'date' },{ prop: 'endDate', name: 'End Date', pipe: 'date' }, { prop: 'limitDate', name: 'Limit Date', pipe: 'date' }, { prop: 'dueDate', name: 'Due Date', pipe: 'date' }, { prop: 'vacationDays', name: 'Vacation Days' }, { prop: 'soldVacation', name: 'Sold Days' }];
  displayedColumns = ['Start Date', 'End Date', 'Limit Date', 'Due Date', 'Vacation Days', 'Sold Days'];
  public validationForm: FormGroup;
  userRole: any;
  responseError: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private userData: UserService,
    private locationData: LocationService,
    private vacationControlData: VacationControlService) { }

  // User Variables
  public entUser: User;

  // Location Variables
  public lstLocation: Location[];

  // Vacation Control Variables
  public lstVacationControl: VacationControl[];

  ngOnInit(): void {
    this.entUser = new User();

    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;

    if (this.userRole != 1) {
      this.router.navigate(['/manager']);
    }

    this.getData();
    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(10)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      type: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
      birthDate: new FormControl('', [Validators.required]),
      admissionDate: new FormControl('', [Validators.required]),
      locationId: new FormControl('', [Validators.required]),
      workingHours: new FormControl('', [Validators.required]),
      dismissDate: new FormControl('', null)
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.userData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entUser = response;

        this.vacationControlData.getAll(this.entUser.id, undefined, true).subscribe(response => {
          this.lstVacationControl = response;

        });
      });
    } else {
      this.entUser.id = 0;
    }

    this.locationData.getAll(-1, '').subscribe(response => {
      this.lstLocation = response.data.map(data => new Location().deserialize(data));
    });
  }

  onSubmit() {
    if (this.validation()) {
      return;
    }

    this.save();
  }

  save() {
    if (this.entUser.id == 0) { // Add
      this.userData.add(this.entUser).subscribe(data => {

        this.redirect();
      }, error => this.responseError = error.error.message);
    } else { // Edit
      this.userData.update(this.entUser).subscribe(data => {
        this.redirect();
      }, error => this.responseError = error.error.message);
    }
  }

  validation() {
    var validation = false



    if (this.entUser.id == 0 && (this.validationForm.value.name == "" || this.validationForm.value.email == "" || this.validationForm.value.password == "" || this.validationForm.value.type == "" || this.validationForm.value.role == "" || this.validationForm.value.birthDate == "" || this.validationForm.value.admissionDate == "" || this.validationForm.value.locationId == "" || this.validationForm.value.workingHours == "")) {
      validation = true;
    } else if (this.validationForm.value.name == "" || this.validationForm.value.email == "" || this.validationForm.value.type == "" || this.validationForm.value.role == "" || this.validationForm.value.birthDate == "" || this.validationForm.value.admissionDate == "" || this.validationForm.value.locationId == "" || this.validationForm.value.workingHours == "") {
      validation = true;
    }

    return validation;
  }

  redirect() {
    SessionManager.SetSession('userToast', '1');
    this.router.navigate(['/manager/user']);
  }

  cancelClick() {
    this.router.navigate(['/manager/user']);
  }

}
