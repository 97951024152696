<h1 mat-dialog-title [ngClass]="{ 'titleProjectActivities': epic || issue || userTask }">
  <mat-icon style="vertical-align: middle; margin-right: 5px;" *ngIf="epic || issue">{{ epic ? 'landscape' : issue ?
    'layers' : '' }}</mat-icon>{{ title }}
</h1>
<div mat-dialog-content *ngIf="!table && !receipt && !epic && !issue && !userTask && !chart">{{ message }}</div>

<!-- Start Table -->
<div mat-dialog-content id="timesheet" class="mat-typography" *ngIf="table">
  <table>
    <thead>
      <tr>
        <th></th>
        <th>Date</th>
        <th>Weekday</th>
        <th>Hours</th>
        <th>Hour Type</th>
        <th>Activity Type</th>
        <th>Project</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of dataSource"
        [ngClass]="{ 'Weekend': (data.registeredDate.getDay() == 6) || (data.registeredDate.getDay() == 0) }">
        <td>
          <div style="height: 10px; width: 10px; border-radius: 10px;"
            [ngClass]="{ 'bg-primary-green': data.hourType == 1, 'bg-orange': data.hourType == 2, 'bg-red': data.hourType == 3, 'bg-blue': data.hourType == 4, 'bg-white': data.hourType == 5, 'bg-lightblue': data.hourType == 6 }">
          </div>
        </td>
        <td>{{ data.registeredDate | date: 'dd/MM/yyyy' }}</td>
        <td>{{ data.registeredDate | weekday }}</td>
        <td>{{ data.registeredHours }}</td>
        <td>{{ data.hourType | hourType }}</td>
        <td>{{ data.activityType | activityType }}</td>
        <td>{{ data.project.projectName }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Start Receipt -->
<div mat-dialog-content id="receipt" class="mat-typography" *ngIf="receipt">
  <table>
    <thead>
      <tr>
        <th>Description</th>
        <th>Emission Date</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>PO Total</td>
        <td>{{ entReceipt.emissionDate | date:"dd/MM/yyyy" }}</td>
        <td>{{ entReceipt.POTotal | currency:'R$' }}</td>
      </tr>
      <tr *ngFor="let entInvoice of entReceipt.Inv">
        <td>Inv {{ entInvoice.number }}</td>
        <td>{{ entInvoice.emissionDate | date:"dd/MM/yyyy" }}</td>
        <td>{{ entInvoice.totalValue | currency:'R$-' }}</td>
      </tr>
      <tr>
        <td colspan="2">PO Balance</td>
        <td>{{ entReceipt.POTotal - entReceipt.InvSumOfValue | currency:'R$' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Start Form -->
<div mat-dialog-content id="epicIssue" class="mat-typography projectActivities" *ngIf="epic || issue || userTask">
  <form [formGroup]="validationForm">

    <!-- Start Epic Form -->
    <div class="row" *ngIf="epic">
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" [(ngModel)]="entEpic.title">
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description" [(ngModel)]="entEpic.description"></textarea>
        </mat-form-field>
      </div>
      <div class="col col-lg-4 col-md-6" *ngIf="entEpic.id > 0">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status" [(ngModel)]="entEpic.status" disabled>
            <mat-option [value]="0">To Do</mat-option>
            <mat-option [value]="1">Doing</mat-option>
            <mat-option [value]="2">To Review</mat-option>
            <mat-option [value]="3">Redo</mat-option>
            <mat-option [value]="4">Done</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-lg-4 col-md-6" *ngIf="entEpic.id > 0">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="dp1" formControlName="startDate" [(ngModel)]="entEpic.startDate" disabled>
          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
          <mat-datepicker #dp1 disabled="true"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col col-lg-4 col-md-6" *ngIf="entEpic.id > 0">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="dp2" formControlName="endDate" [(ngModel)]="entEpic.endDate" disabled>
          <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
          <mat-datepicker #dp2 disabled="true"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <!-- Start Issue Form -->
    <div class="row" *ngIf="issue" style="width: 1044.8px; height: 346.75 !important;" id="issue">
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" [(ngModel)]="entIssue.title">
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-tab-group mat-align-tabs="end">
          <mat-tab label="Details">
            <div class="row tabRow" style="padding: 15px 0 0 0;">
              <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Description</mat-label>
                  <textarea matInput formControlName="description" [(ngModel)]="entIssue.description"></textarea>
                </mat-form-field>
              </div>
              <div class="col col-lg-4 col-md-6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Priority</mat-label>
                  <mat-select formControlName="priority" [(ngModel)]="entIssue.priority">
                    <mat-option [value]="0">Critical</mat-option>
                    <mat-option [value]="1">High</mat-option>
                    <mat-option [value]="2">Medium</mat-option>
                    <mat-option [value]="3">Low</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col col-lg-4 col-md-6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Responsible</mat-label>
                  <mat-select formControlName="responsible" [(ngModel)]="entIssue.responsible">
                    <mat-option *ngFor="let projectUsers of lstProjectUsers" [value]="projectUsers.user.id">{{
                      projectUsers.user.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Effort</mat-label>
                  <input matInput type="number" formControlName="effort" [(ngModel)]="entIssue.effort"
                    disabled="entIssue.status != 0">
                </mat-form-field>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>ActualEffort</mat-label>
                  <input matInput type="number" formControlName="actualEffort" [(ngModel)]="entIssue.actualEffort"
                    disabled>
                </mat-form-field>
              </div>
              <div class="col col-lg-4 col-md-6" *ngIf="entIssue.id > 0">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status" [(ngModel)]="entIssue.status" disabled>
                    <mat-option [value]="0">To Do</mat-option>
                    <mat-option [value]="1">Doing</mat-option>
                    <mat-option [value]="2">To Review</mat-option>
                    <mat-option [value]="3">Redo</mat-option>
                    <mat-option [value]="4">Done</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col col-lg-4 col-md-6" *ngIf="entIssue.id > 0">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Sprint</mat-label>
                  <mat-select formControlName="sprint" [(ngModel)]="entIssue.sprintId">
                    <mat-option [value]="0">No Sprint</mat-option>
                    <mat-option *ngFor="let sprint of lstSprints" [value]="sprint.id">{{ sprint.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-6" *ngIf="entIssue.id > 0">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Start Date</mat-label>
                  <input matInput [matDatepicker]="dp1" formControlName="startDate" [(ngModel)]="entIssue.startDate"
                    disabled>
                  <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                  <mat-datepicker #dp1 disabled="true"></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-6" *ngIf="entIssue.id > 0">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>End Date</mat-label>
                  <input matInput [matDatepicker]="dp2" formControlName="endDate" [(ngModel)]="entIssue.endDate"
                    disabled>
                  <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                  <mat-datepicker #dp2 disabled="true"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Files">
            <div style="height: 245px;">
              <button mat-button class="addFile" (click)="file.click()">
                <mat-icon>add</mat-icon>
                Add attachment
              </button>
              <input hidden #file type="file" class="" multiple (change)="upload(file.files)" accept="application/pdf">
              <!-- <input hidden (change)="onFileSelected()" #fileInput type="file" id="file"> -->
              <table class="fileTable">
                <tr *ngFor="let entFile of dataSource">
                  <td class="fileName"><label (click)="viewFile(entFile.id)">{{ entFile.fileName }}</label></td>
                  <td class="fileDelete">
                    <button mat-icon-button (click)="openDeleteIssueDialog(entFile)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </mat-tab>
          <mat-tab label="History">
            <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">
              <!-- <div *cdkVirtualFor="let item of issueHistory" class="example-item">{{ item.description }}</div> -->
              <table class="fileTable">
                <tr>
                  <th style="font-weight: 500; text-align: left; padding-left: 15px;">Change</th>
                  <th style="font-weight: 500; text-align: left; padding-left: 15px;">When</th>
                  <th style="font-weight: 500; text-align: left; padding-left: 15px;">Who</th>
                </tr>
                <tr *ngFor="let item of issueHistory">
                  <td [innerHtml]="item.description" class="fileName"></td>
                  <td class="fileName">{{ item.date | date:'dd/MM/yyyy' }} {{ item.date | date:'hh:mm:ss a' }}</td>
                  <td class="fileName">{{ item.user.name }}</td>
                </tr>
              </table>
            </cdk-virtual-scroll-viewport>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <!-- Start User Task -->
    <div class="row" *ngIf="userTask">
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" [(ngModel)]="entUserTask.title">
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description" [(ngModel)]="entUserTask.description"></textarea>
        </mat-form-field>
      </div>
      <div [ngClass]="{ 'col-6': entUserTask.id > 0, 'col-12': entUserTask.id == 0 }">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Due Date</mat-label>
          <input matInput [matDatepicker]="dp1" formControlName="date" [(ngModel)]="entUserTask.dueDate">
          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
          <mat-datepicker #dp1 disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-6" *ngIf="entUserTask.id > 0" style="padding-top: 15px;">
        <mat-slide-toggle formControlName="done" [(ngModel)]="entUserTask.done">Done
        </mat-slide-toggle>
      </div>
    </div>

    <!-- Error Message -->
    <div class="row">
      <div class="col-12">
        <div class="error" *ngIf="responseError">
          <mat-label>{{ responseError }}</mat-label>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Start BrunDown Chart -->
<div mat-dialog-content id="chart" class="mat-typography" *ngIf="chart">
  <div style="width: 800px; margin: auto;">
    <canvas id="myChart"></canvas>
  </div>
</div>

<div mat-dialog-actions align="end" [ngClass]="{ 'buttonSaveProjectActivities': epic || issue || userTask }">
  <button mat-button mat-dialog-close *ngIf="cancelButton != ''">{{ cancelButton }}</button>
  <button mat-flat-button color="warn" (click)="onConfirmClick()" *ngIf="saveButton != ''">{{
    saveButton }}</button>
</div>