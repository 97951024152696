<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body" *ngIf="lstLocation">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="date"
                            [(ngModel)]="entHolidays.date">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="holidayName" [(ngModel)]="entHolidays.name">
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Location</mat-label>
                        <mat-select formControlName="locationId" [(ngModel)]="entHolidays.locationId">
                            <mat-option [value]="0">All</mat-option>
                            <mat-option *ngFor="let location of lstLocation" [value]="location.id">{{ location.locationName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()" style="float: right;">Save</button>
                    <button mat-button (click)="cancelClick()" style="float: right;">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>