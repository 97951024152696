import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HylaSpinnerService } from '../components/hyla-spinner/hyla-spinner.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    requestCount = 0;

    constructor(public hylaSpinnerService : HylaSpinnerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestCount++;
        this.hylaSpinnerService.show();
        
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request).pipe(
            //finalize(() => {this.requestCount--})
            finalize(() => {this.requestCount--; if (this.requestCount === 0) { this.hylaSpinnerService.hide()}})
        );
    }
}