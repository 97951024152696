<mat-sidenav-container autosize hasBackdrop="{{ width >= 961 ? false : true }}">
    <mat-sidenav #drawer mode="{{ width >= 961 ? 'side' : 'over' }}">

        <hyla-sidenav></hyla-sidenav>

    </mat-sidenav>

    <mat-sidenav-content>
        <div class="page-container">
            <hyla-toolbar id="toolbar" (menuEvent)="drawer.toggle()"></hyla-toolbar>

            <div class="main-content">
                <router-outlet></router-outlet>
            </div>

            <hyla-footer></hyla-footer>
        </div>

    </mat-sidenav-content>

</mat-sidenav-container>