<div class="row">
    <div class="col col-md-2">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Month</mat-label>
            <mat-select [(value)]="modelMonth">
                <mat-option [value]="1">January</mat-option>
                <mat-option [value]="2">February</mat-option>
                <mat-option [value]="3">March</mat-option>
                <mat-option [value]="4">April</mat-option>
                <mat-option [value]="5">May</mat-option>
                <mat-option [value]="6">June</mat-option>
                <mat-option [value]="7">July</mat-option>
                <mat-option [value]="8">August</mat-option>
                <mat-option [value]="9">September</mat-option>
                <mat-option [value]="10">October</mat-option>
                <mat-option [value]="11">November</mat-option>
                <mat-option [value]="12">December</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col col-md-2">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Year</mat-label>
            <mat-select [(value)]="modelYear">
                <mat-option *ngFor="let year of year" [value]="year">{{ year }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col col-md-4">
        <button mat-flat-button color="primary" class="button" (click)="getData()">Search</button>
    </div>
</div>
<div class="row">
    <div class="col col-lg-2 col-md-4" *ngFor="let entUser of lstUser">
        <mat-card class="card-header">
            <h3>{{ entUser.name }}<small class="round">{{ round(entUser) }}%</small></h3>
            <label><small (click)="openDialog(entUser, 2)">View</small> | <small
                    (click)="openDialog(entUser, 1)">Close</small></label>
        </mat-card>
        <mat-card class="card-body" style="padding: 0px">
            <table>
                <tbody>
                    <tr>
                        <th>Previous Extra Hours</th>
                        <td colspan="2">{{ extraHourInformation(entUser, 1) }}</td>
                    </tr>
                    <tr>
                        <th>Normal Hours:</th>
                        <td>{{ gridInformation(entUser.id, 1) }}</td>
                        <td rowspan="4">{{ gridInformation(entUser.id, 7) }}</td>
                    </tr>
                    <tr>
                        <th>Basket Hours:</th>
                        <td>{{ gridInformation(entUser.id, 4) }}</td>
                    </tr>
                    <tr>
                        <th>License Hours:</th>
                        <td>{{ gridInformation(entUser.id, 5) }}</td>
                    </tr>
                    <tr>
                        <th>Vacation Hours:</th>
                        <td matTooltip="{{ gridInformation(entUser.id, 6, false) }} - {{ gridInformation(entUser.id, 6, false) - gridInformation(entUser.id, 6, true) }}"
                            matTooltipPosition="after">{{
                            gridInformation(entUser.id, 6, true) }}</td>
                    </tr>
                    <tr>
                        <th>Extra Hours:</th>
                        <td colspan="2">{{ gridInformation(entUser.id, 2) }}</td>
                    </tr>
                    <tr>
                        <th>Warning Hours:</th>
                        <td colspan="2">{{ gridInformation(entUser.id, 3) }}</td>
                    </tr>
                    <tr>
                        <th>Total Extra Hours</th>
                        <td colspan="2">{{ extraHourInformation(entUser, 2) }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card>

    </div>
</div>