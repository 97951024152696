<div style="padding-right: 1.5rem; padding-left: 1.5rem; background-color: #dedfe1;" *ngIf="lstTimesheet">
    <mat-card class="card-header">
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <mat-card>
                    <div
                        style="width: 40px; height: 40px; background-color: #379e52; border-radius: 100%; float: right; padding: 9px;">
                        <mat-icon style="color: #fff;">schedule</mat-icon>
                    </div>
                    <h2 style="color: #7e868c; margin-bottom: 0px; font: 500 12px/25px Roboto">Weekly View
                    </h2>
                    <label style="font-weight: 500; font-size: 25px;">{{ WorkedHours }}/{{ entUser.workingHours -
                        (lstWeekHolidays.length * (entUser.workingHours/5)) }}
                        <small style="font-size: 12px; color: #7e868c;">hours</small></label>
                    <mat-progress-bar mode="determinate" value="{{ (this.WorkedHours*100)/this.entUser.workingHours }}"
                        style="border-radius: 10px;"></mat-progress-bar>
                </mat-card>
            </div>
            <div class="col-md-4 col-sm-12">
                <mat-card style="height: 94px;">
                    <div
                        style="width: 40px; height: 40px; background-color: #f8d932; border-radius: 100%; float: right; padding: 9px;">
                        <mat-icon style="color: #fff;">check</mat-icon>
                    </div>
                    <h2 style="color: #7e868c; margin-bottom: 0px; font: 500 12px/25px Roboto">Compliance
                    </h2>
                    <label style="font-weight: 500; font-size: 25px;">{{
                        round((WorkedHours*100)/(entUser.workingHours - (lstWeekHolidays.length * (entUser.workingHours/5)))) }}<small
                            style="font-size: 12px; color: #7e868c;">%</small></label>
                </mat-card>
            </div>
            <div class="col-md-4 col-sm-12">
                <mat-card style="height: 94px;">
                    <div
                        style="width: 40px; height: 40px; background-color: #e43c92; border-radius: 100%; float: right; padding: 9px;">
                        <mat-icon style="color: #fff;">trending_up</mat-icon>
                    </div>
                    <h2 style="color: #7e868c; margin-bottom: 0px; font: 500 12px/25px Roboto">Basket Balance
                    </h2>
                    <label style="font-weight: 500; font-size: 25px;">{{ entTimesheetBalance.hoursExtraTotal }}
                        <small style="font-size: 12px; color: #7e868c;">hours</small></label>
                </mat-card>
            </div>
        </div>
    </mat-card>
</div>
<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body">
        <div class="row">
            <div class="col col-lg-8 col-md-12" style="padding: 0;">
                <div class="row">
                    <div class="col col-lg-12">
                        <mat-card>
                            <h2 style="color: #7e868c; margin-bottom: 0px; font: 500 12px/25px Roboto">Timesheet View
                            </h2>
                            <!-- <div>
                                <canvas id="DailySummaryChart"></canvas>
                            </div> -->
                            <table class="timesheet-table" style="width: 100%;" *ngIf="lstTimesheet">
                                <tr>
                                    <th class="timesheet-header" *ngFor="let item of Arr(7); let i = index">
                                        {{ i == 0 ? 'Sun' : i == 1 ? 'Mon' : i == 2 ? 'Tue' : i == 3 ? 'Wed' : i == 4 ?
                                        'Thu' : i == 5 ? 'Fri' : i == 6 ? 'Sat' : 'Error' }}
                                        <small [ngClass]="TimesheetOverView(2, i)">{{ TimesheetOverView(1, i) }}</small>
                                    </th>
                                </tr>
                                <tr>
                                    <td *ngFor="let weekDay of TimesheetView; let t = index"
                                        (click)="redirectAddTimesheet(t)">
                                        <div class="row">
                                            <div class="col-12 timesheet-tag" *ngFor="let entTimesheet of weekDay"
                                                (click)="redirectEditTimesheet(entTimesheet.id)"
                                                [ngClass]="{ 'bg-orange': entTimesheet.hourType == 2, 'bg-red': entTimesheet.hourType == 3, 'bg-blue': entTimesheet.hourType == 4, 'bg-white': entTimesheet.hourType == 5, 'bg-lightblue': entTimesheet.hourType == 6 }">
                                                <p class="timesheet-tag-header">{{ entTimesheet.project.projectName }}
                                                </p>
                                                <p class="timesheet-tag-info">{{ entTimesheet.hourType | hourType
                                                    }}
                                                    <span style="float: right;">{{
                                                        entTimesheet.registeredHours }}h</span>
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </mat-card>
                    </div>
                    <div class="col col-lg-6 col-md-12">
                        <mat-card>
                            <h2 style="color: #7e868c; margin-bottom: 0px; font: 500 12px/25px Roboto">Hours By Type
                            </h2>
                            <div>
                                <canvas id="HoursByTypeChart"></canvas>
                            </div>
                        </mat-card>
                    </div>
                    <div class="col col-lg-6 col-md-12">
                        <mat-card>
                            <h2 style="color: #7e868c; margin-bottom: 0px; font: 500 12px/25px Roboto">Hours By
                                Project</h2>
                            <div>
                                <canvas id="HoursByProjectChart"></canvas>
                            </div>
                        </mat-card>
                    </div>
                    <div class="col col-lg- 12">
                        <mat-card class="holidays-card">
                            <h2 style="color: #7e868c; margin-bottom: 0px; font: 500 12px/25px Roboto">Holidays
                            </h2>

                            <mat-table [dataSource]="lstHolidays">

                                <!--Columns-->
                                <ng-container *ngFor="let col of colNames" [matColumnDef]="col.name">
                                    <mat-header-cell *matHeaderCellDef>{{ col.name }}</mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{ col.pipe == "date" ? (element[col.prop] | date: 'dd/MM/yyyy') : col.pipe ==
                                        "weekday" ? (element[col.prop] | weekday) : element[col.prop] }}
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>
                        </mat-card>
                    </div>
                </div>
            </div>
            <div class="col col-lg-4 col-md-12" style="padding-bottom: 15px;">
                <mat-card style="height: 100%;">
                    <div class="row">
                        <h2 style="color: #7e868c; margin-bottom: 0px; font: 500 12px/25px Roboto">To Do
                        </h2>
                        <span class="spacer"></span>
                        <button mat-flat-button color="primary"
                            style="min-width: 20px; line-height: 20px; padding: 0 6px;" (click)="taskDetail()">
                            <mat-icon style="height: 17px; width: 16px; font-size: 17px !important;">add</mat-icon>
                        </button>
                    </div>
                    <div style="padding-top: 10px;">
                        <mat-list class="todo" role="todo">
                            <mat-list-item role="todoitem" *ngFor="let task of lstUserTask">
                                <label class="taskTitle" style="font-size: 12px; margin: 0;">{{ task.title }}</label>
                                <span class="spacer"></span>
                                <div class="tag" [ngClass]="tag(task.date)" *ngIf="task.date != undefined"
                                    matTooltip="{{ tooltip(task.date) }} days left">
                                    <mat-icon
                                        style="font-size: 18px !important; height: 19px; width: 16px; vertical-align: middle; pointer-events: none;">
                                        notifications</mat-icon>
                                    <label style="margin: 0 5px;">{{
                                        (task.date.getMonth() + 1) | month }} {{ task.date.getDate() }}</label>
                                </div>
                                <button mat-icon-button class="onUserTaskListHover" [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="taskDetail(task)">
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button>
                                    <button mat-menu-item (click)="taskDone(task.id)">
                                        <mat-icon>check</mat-icon>
                                        <span>Done</span>
                                    </button>
                                </mat-menu>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </mat-card>
            </div>
        </div>
    </mat-card>
</div>