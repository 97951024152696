<div class="container-fluid">
    <form [formGroup]="validationForm">
        <div class="row">
            <div class="col-sm-2" style=" padding: 0;">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="dp1" formControlName="startDate" [(ngModel)]="modelStartDate">
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-sm-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="dp2" formControlName="endDate" [(ngModel)]="modelEndDate">
                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                    <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-sm-2" style="padding-top: 15px; padding-left: 0;">
                <button mat-flat-button color="primary" class="button" (click)="getData()">Search</button>
            </div>
        </div>
    </form>
</div>

<div id="print" *ngIf="entClient">
    <div class="row" style="padding-top: 20px; padding-bottom: 80px;">
        <div class="row">
            <div class="col-sm-4 img">
                <img src="../../../assets/images/atomosHylaLogo.svg" width="300px">
            </div>

            <div class="col-sm-8 content">
                <h4 style="margin: 0; font-size: 18px;">{{ entProject.projectName }}</h4>
                <div class="row" style="color: rgb(126, 134, 140);">
                    <div class="col-md-2 col-sm-3" style="display: grid; font-weight: 500; padding: 0;">
                        <label>Customer: </label>
                        <label>Date Range: </label>
                    </div>

                    <div class="col-md-4 col-sm-6" style="display: grid; padding: 0;">
                        <label>{{ entClient.name }}</label>
                        <label>{{ modelStartDate | date:'dd/MM/yyyy' }} - {{ modelEndDate | date:'dd/MM/yyyy' }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" id="div-card" [ngStyle]="getStyles()">
        <div class="col-12">
            <mat-card id="card" style="min-width: 85%; max-width: calc(100% - 30px); position : absolute;">
                <table *ngIf="lstReport">
                    <tr class="header">
                        <th>Title</th>
                        <th style="width: 11%;">Start Date</th>
                        <th style="width: 11%;">End Date</th>
                        <th style="width: 11%;">Estimated Effort</th>
                        <th style="width: 11%;">Actual Effort</th>
                        <th style="width: 11%;">Effort Accuracy</th>
                    </tr>

                    <tr *ngFor="let report of lstReport">
                        <!-- Epic -->
                        <td class="epic" *ngIf="report.epic">{{ report.title }}</td>
                        <td class="epic" *ngIf="report.epic">{{ report.startDate |
                            date:"dd/MM/yyyy"}}</td>
                        <td class="epic" *ngIf="report.epic">{{ report.endDate | date:"dd/MM/yyyy"}}
                        </td>
                        <td class="epic" *ngIf="report.epic">{{ epicEffort(1, report.issue) }}</td>
                        <td class="epic" *ngIf="report.epic">{{ epicEffort(2, report.issue) }}</td>
                        <td class="epic" *ngIf="report.epic">{{ epicEffort(3, report.issue) }}%</td>

                        <!-- Issue -->
                        <td *ngIf="!report.epic" style="padding-left: 40px;">{{ report.title }}</td>
                        <td *ngIf="!report.epic">{{ report.startDate | date:"dd/MM/yyyy" }}</td>
                        <td *ngIf="!report.epic">{{ report.endDate | date:"dd/MM/yyyy" }}</td>
                        <td *ngIf="!report.epic">{{ report.effort }}</td>
                        <td *ngIf="!report.epic">{{ report.actualEffort }}</td>
                        <td *ngIf="!report.epic">{{ accuracyCalc(report.effort,
                            report.actualEffort)}}%</td>
                    </tr>
                </table>
            </mat-card>
        </div>
    </div>
</div>