export class SessionManager {
    /** 
     * Set Session
     */
    public static SetSession(session: string, value: string) {
        sessionStorage.setItem(session, value);
    }

    /**
     * Get Session
     */
    public static GetSession(session: string) {
        var Value;
        if (sessionStorage.getItem(session)) {
            Value = sessionStorage.getItem(session);
            sessionStorage.removeItem(session);
        }

        return Value;
    }

    /**
     * Delete Session
     */
    public static DeleteSession(session: string) {
        sessionStorage.removeItem(session);
    }

    /**
     * Session Exist
     */
    public static SessionExist(session: string) {
        return sessionStorage.getItem(session);
    }
}