import { Component, OnInit, Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { FlatTreeControl } from "@angular/cdk/tree";
import { BehaviorSubject, from, Observable, of as observableOf } from "rxjs";
import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

// Session Manager
import { SessionManager } from 'src/app/utils/session-manager';

// App Setings
import { AppSettings } from 'src/app/utils/settings';

//Dialog
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HylaDialogComponent } from 'src/app/components/hyla-dialog/hyla-dialog.component';

// Epic
import { Epic } from 'src/app/models/epic.model';
import { EpicService } from 'src/app/services/epic.service';

// Issue
import { Issue } from 'src/app/models/issue.model';
import { IssueService } from 'src/app/services/issue.service';

// ProjectUser
import { ProjectUsers } from 'src/app/models/projectUsers.model';
import { ProjectUsersService } from 'src/app/services/projectUsers.service';

// File Storage
import { FileStorage } from 'src/app/models/fileStorage.model';
import { FileStorageService } from 'src/app/services/fileStorage.service';

// History
import { History } from 'src/app/models/history.model';
import { HistoryService } from 'src/app/services/history.service';

// Sprint
import { Sprint } from 'src/app/models/sprint.model';
import { SprintService } from 'src/app/services/sprint.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * File node data with nested structure.
 * Each node has a filename, and a type or a list of children.
 */
export class BacklogNode {
  id: string;
  title: string;
  children: BacklogNode[];
  type: any;
  dbId: number;
  parentId: number;
  effort: number;
  status: number;
  responsible: number;
  startDate: Date;
  endDate: Date;
  sprintId: number;
}

export class BacklogFlatNode {
  constructor(
    public expandable: boolean,
    public title: string,
    public level: number,
    public type: any,
    public id: string,
    public dbId: number,
    public parentId: number,
    public effort: number,
    public status: number,
    public responsible: number,
    public startDate: Date,
    public endDate: Date,
    public sprintId: number,
  ) { }
}

/**
 * The file structure tree data in string. The data could be parsed into a Json object
 */
var TREE_DATA = JSON.stringify([]);

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
@Injectable()
export class FileDatabase {
  dataChange = new BehaviorSubject<BacklogNode[]>([]);

  get data(): BacklogNode[] {
    return this.dataChange.value;
  }

  constructor() {
    // this.initialize();
  }

  initialize() {
    // Parse the string to json object.
    const dataObject = JSON.parse(TREE_DATA);

    // Build the tree nodes from Json object. The result is a list of `FileNode` with nested
    //     file node as children.
    //const data = this.buildFileTree(dataObject, 0);

    const data = this.buildBackLogTree(dataObject, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  buildBackLogTree(obj: any, level: number, parentId: string = "0"): BacklogNode[] {
    var lstReturn: BacklogNode[] = [];

    for (let item of obj) {
      const node = new BacklogNode();
      node.id = `${parentId}/${item["id"]}`;
      node.dbId = item['id'];
      node.effort = item['effort'];
      node.status = item['status'];
      node.responsible = item['responsible'];
      node.startDate = item['startDate'];
      node.endDate = item['endDate'];

      if (level == 1) {
        node.parentId = parseInt(parentId.split('/')[1]);
        node.sprintId = item['sprintId'];
      }
      node.title = item["title"];

      if (item["issue"] != null && item["issue"].length > 0) {
        node.children = this.buildBackLogTree(item["issue"], 1, node.id);
      } else {
        node.children = null;
      }

      lstReturn.push(node);
    }

    return lstReturn;
  }
}

@Component({
  selector: 'app-project-activities',
  templateUrl: './project-activities.component.html',
  styleUrls: ['./project-activities.component.scss'],
  providers: [FileDatabase]
})
export class ProjectActivitiesComponent implements OnInit {
  modelProject: number;
  tabIndex: number = 0;
  modelActiveToggle: boolean = false;
  modelSprint: number = 0;
  modelSprintForm: boolean = false;
  validationForm: FormGroup;
  validationHeader: FormGroup;
  userRole: any;

  treeControl: FlatTreeControl<BacklogFlatNode>;
  treeFlattener: MatTreeFlattener<BacklogNode, BacklogFlatNode>;
  dataSource: MatTreeFlatDataSource<BacklogNode, BacklogFlatNode>;
  // expansion model tracks expansion state
  expansionModel = new SelectionModel<string>(true);
  dragging = false;
  expandTimeout: any;
  expandDelay = 1000;
  validateDrop = true;
  issueNumber: number = 3;
  totalRecords: number;
  lstAdd: any[] = [];
  lstDelete: any[] = [];
  responseError: string;
  ChartData: { label: string; list: any[]; }[];

  constructor(public dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private epicData: EpicService,
    private issueData: IssueService,
    private historyData: HistoryService,
    private projectUsersData: ProjectUsersService,
    private fileStorageData: FileStorageService,
    private sprintData: SprintService,
    public database: FileDatabase
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this._getLevel,
      this._isExpandable,
      this._getChildren
    );
    this.treeControl = new FlatTreeControl<BacklogFlatNode>(
      this._getLevel,
      this._isExpandable

    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    database.dataChange.subscribe(data => this.rebuildTreeForData(data));
  }

  public lstEpic: Epic[];
  public entEpic: Epic = new Epic;

  public lstIssue: Issue[] = [];
  public entIssue: Issue = new Issue();

  public lstProjectUsers: ProjectUsers[];

  public lstFileStorage: FileStorage[];

  public lstHistory: History[];

  public lstSprint: Sprint[];
  public entSprint: Sprint = new Sprint();

  public lstSprintIssue: BacklogNode[] = [];
  public lstSprintIssueView: BacklogNode[] = [];

  /**
   * Board List
   */
  todo: Issue[];
  doing: Issue[];
  review: Issue[];
  redo: Issue[];
  done: Issue[];

  ngOnInit(): void {
    if (SessionManager.SessionExist('projectId')) {
      this.modelProject = SessionManager.GetSession('projectId');
      this.getData();
    } else {
      this.router.navigate(['/manager/project']);
    }

    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;

    this.entSprint.id = 0;
    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      plannedStartDate: new FormControl('', [Validators.required]),
      plannedEndDate: new FormControl('', [Validators.required]),
    });

    this.validationHeader = new FormGroup({
      modelActiveToggle: new FormControl(0, null),
      sprint: new FormControl(0, null),
    });
  }

  onTabChanged($event) {
    this.modelActiveToggle = true;
    this.getData();

    if ($event.index == 1) {
      this.lstSprint = this.lstSprint.filter(x => x.actualStartDate != undefined).sort((a, b) => {
        return +new Date(a.actualStartDate) - +new Date(b.actualStartDate);
      });

      this.entSprint = this.lstSprint[this.lstSprint.length - 1]
      this.modelSprint = this.entSprint.id;
      this.getBoardData();
    }

  }

  getData() {
    this.projectUsersData.getAll(this.modelProject, 0).subscribe(response => {
      if (response.length > 0) {
        this.lstProjectUsers = response;
      } else {
        this.lstProjectUsers = [];
      }

      this.sprintData.getAll(this.modelProject).subscribe(sprintResponse => {
        if (response.length > 0) {
          this.lstSprint = sprintResponse;
        } else {
          this.lstSprint = [];
        }

        this.getEpic();
      });
    });
  }

  getEpic() {
    this.lstIssue = [];
    this.epicData.getAll(this.modelProject, !this.modelActiveToggle, this.modelSprint).subscribe(response => {
      this.lstEpic = response;
      this.lstEpic.forEach(entEpic => {
        this.lstIssue = this.lstIssue.concat(entEpic.issue);
      });
      TREE_DATA = JSON.stringify(this.lstEpic);
      this.database.initialize();
    });
  }

  getEpicName(id) {
    return this.lstEpic.find(x => x.id == id).title;
  }

  activeToggleClick(value: any) {
    this.modelActiveToggle = value;
    this.getEpic();
  }

  onPlannedStartDateChange() {
    this.entSprint.name = 'Sprint ' + this.entSprint.plannedStartDate.getDate() + '/' + (this.entSprint.plannedStartDate.getMonth() + 1) + '/' + this.entSprint.plannedStartDate.getFullYear();
  }

  onIssueClick(entIssue) {
    this.lstSprintIssueView.push(entIssue);
    var index = this.lstDelete.indexOf(this.lstDelete.find(x => x.dbId == entIssue.dbId));

    if (this.lstAdd.find(x => x.dbId == entIssue.dbId) == undefined && this.lstSprintIssue.find(x => x.dbId == entIssue.dbId) == undefined) {
      this.lstAdd.push(entIssue);
    } else if (index > -1) {
      this.lstDelete.splice(index, 1);
    }
  }

  onSprintIssueDelete(issue) {
    var indexView = this.lstSprintIssueView.indexOf(issue);
    var addIndex = this.lstAdd.indexOf(issue);

    if (this.lstSprintIssue.find(x => x.dbId == issue.dbId) != undefined) {
      if (this.lstDelete.find(x => x.dbId == issue.dbId) == undefined) {
        this.lstDelete.push(issue);
      } else {
        var deleteIndex = this.lstDelete.indexOf(issue);
        this.lstDelete.splice(deleteIndex, 1);
      }
    } else {
      this.lstAdd.splice(addIndex, 1)
    }

    this.lstSprintIssueView.splice(indexView, 1)
  }

  onSprintChange(data) {
    this.lstSprintIssue = [];
    this.lstSprintIssueView = [];
    this.lstAdd = [];
    this.lstDelete = [];

    if (this.modelSprint == -1) {
      this.modelSprintForm = true;
      this.entSprint = new Sprint();
      this.entSprint.id = 0;
      this.validators();
      this.getData();
    } else {
      this.modelSprintForm = false;

      if (this.modelSprint == 0) {
        this.modelActiveToggle = false;
      } else {
        this.modelActiveToggle = true;
      }
      this.getData();

      if (this.modelSprint == 0) {
        var issues = []
        this.modelSprint = data.value;
        this.entSprint = this.lstSprint.find(x => x.id == this.modelSprint);
        this.modelSprintForm = false;

        this.lstEpic.filter(x => x.issue.length > 0).forEach(function (epic) {
          issues = issues.concat(epic.issue);
        });

        this.lstIssue = issues;

        this.lstSprintIssue = this.lstIssue.filter(x => x.sprintId == this.modelSprint).map((issue) => {
          var sprintIssues = new BacklogNode();
          sprintIssues.dbId = issue.id;
          sprintIssues.title = issue.title;
          sprintIssues.startDate = issue.startDate;
          sprintIssues.endDate = issue.endDate;
          return sprintIssues;
        });

        this.lstSprintIssueView = this.lstIssue.filter(x => x.sprintId == this.modelSprint).map((issue) => {
          var sprintIssues = new BacklogNode();
          sprintIssues.dbId = issue.id;
          sprintIssues.title = issue.title;
          sprintIssues.startDate = issue.startDate;
          sprintIssues.endDate = issue.endDate;
          return sprintIssues;
        });
      }
    }
  }

  onSubmit() {
    this.responseError = '';

    if (this.validationForm.invalid) {
      return;
    }

    if (this.lstSprintIssueView.length == 0) {
      this.responseError = "No issues selected for this sprint";

      return;
    }

    this.save();
  }

  save() {
    this.entSprint.projectId = this.modelProject;

    if (this.entSprint.id == 0) {
      this.sprintData.add(this.entSprint).subscribe(data => {
        this.modelSprint = data.id;
        this.modelSprintForm = false;
        this.updataIssue(0, data.id);
      });
    } else {
      this.sprintData.update(this.entSprint).subscribe(data => {
        this.modelSprint = data.id;
        this.modelSprintForm = false;
        this.updataIssue(0, data.id);
      });
    }
  }

  updataIssue(index, sprintId) {
    let entIssue = this.lstSprintIssueView[index];

    if (entIssue != undefined) {
      this.issueData.updateSprint(entIssue.dbId, sprintId).subscribe(issueData => {
        this.updataIssue(index + 1, sprintId)
      });
    } else {
      this.getData();
    }
  }

  projectActivitiesButton(type) {
    let icon = "add";
    let disabled = false;

    if (this.tabIndex == 0 && (this.modelSprint > 0 || (this.modelSprint > 0 && this.lstSprint.find(x => x.id == this.modelSprint).actualStartDate != undefined))) {
      icon = "play_arrow";
      if (this.lstSprint.find(x => (x.id == this.modelSprint && x.actualEndDate != undefined) || (x.actualStartDate != undefined && x.actualEndDate == undefined)) != undefined) {
        disabled = true;
      }
    } else if (this.tabIndex == 1) {
      icon = "stop";
      if (this.lstSprint.find(x => x.id == this.modelSprint && x.actualEndDate != undefined) != undefined) {
        disabled = true;
      }
    }

    return type == 1 ? icon : disabled;
  }

  issueformValidation() {
    return this.modelSprint > 0 ? this.lstSprint.find(x => x.id == this.modelSprint).actualEndDate != undefined ? true : false : false;
  }

  issueClickValidator(id) {
    let info = true;
    if (this.lstSprintIssueView.filter(x => x.dbId == id).length != 0 || this.lstIssue.find(x => x.id == id && x.sprintId != undefined) != undefined) {
      info = false;
    }

    return info;
  }

  columnWidth(value1, value2) {
    return this.modelSprintForm ? value1 : value2;
  }

  userName(id: number) {
    var information = '';

    if (id > 0 && this.lstProjectUsers) {
      information = this.lstProjectUsers.find(x => x.userId == id).user.name;
    }
    return information;
  }

  sprintName(sprintId) {
    return sprintId != undefined ? this.lstSprint.find(x => x.id == sprintId).name : null;
  }

  sprintButtonClick(type) {
    this.entSprint = this.lstSprint.find(x => x.id == this.modelSprint);

    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        title: (type == 0 ? 'Start' : 'Stop') + ' Sprint',
        message: 'Are you sure you want to ' + (type == 0 ? 'start' : 'stop') + ' this sprint now?',
        saveButton: 'Yes',
        cancelButton: 'No'
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      // entEpic.projectId = this.modelProject;
      if (confirmed) {
        this.sprintData.updateActualDate(this.modelSprint, type).subscribe(data => {
          this.getData();
        });
      }
    });

  }

  openEpicDialog(Id?: number) {
    this.entEpic = this.lstEpic.find(x => x.id == Id);

    if (this.entEpic == undefined) {
      this.entEpic = new Epic();
      this.entEpic.projectId = this.modelProject;
    }


    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        epic: true,
        entEpic: this.entEpic,
        saveButton: 'Save',
        cancelButton: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe((entEpic: any) => {
      // entEpic.projectId = this.modelProject;
      if (entEpic != '' && entEpic != undefined) {
        if (entEpic.id > 0) {
          this.epicData.update(entEpic).subscribe(data => { this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
        } else {
          this.epicData.add(entEpic).subscribe(data => { this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
        }
      }
    });
  }

  openDeleteEpicDialog(Id: number) {
    this.entEpic = this.lstEpic.find(x => x.id == Id);

    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        title: 'Confirm deletion',
        message: 'Do you want to delete the epic ' + this.entEpic.title + '?',
        saveButton: 'Yes',
        cancelButton: 'No'
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      // entEpic.projectId = this.modelProject;
      if (confirmed) {
        this.epicData.delete(Id).subscribe(data => { this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
      }
    });
  }

  openIssueDialog(parentId: number, type: number, Id?: number) {
    this.entIssue = this.lstEpic.find(x => x.id == parentId).issue.find(x => x.id == Id);

    if (this.entIssue == undefined) {
      this.entIssue = new Issue();
      this.entIssue.epicId = parentId;

      const dialogRef = this.dialog.open(HylaDialogComponent, {
        data: {
          issue: true,
          entIssue: this.entIssue,
          projectUsers: this.lstProjectUsers,
          dataSource: [],
          issueHistory: [],
          fileStorageData: this.fileStorageData,
          saveButton: 'Save',
          cancelButton: 'Cancel'
        }
      });

      dialogRef.afterClosed().subscribe((entIssue: any) => {
        if (entIssue != '' && entIssue != undefined) {
          if (entIssue.id > 0) { // Edit Issue
            this.issueData.update(entIssue).subscribe(data => {
              if (type == 0) {
                this.getData();
              } else {
                this.getBoardData();
              }
            });
          } else { // Add Issue
            entIssue.actualEffort = 0;

            if (this.modelSprint > 0 && this.lstSprint.find(x => x.id == this.modelSprint && x.actualEndDate != undefined) == undefined) {
              entIssue.sprintId = this.modelSprint;
            }

            this.issueData.add(entIssue).subscribe(data => {
              if (type == 0) {
                this.getData();
              } else {
                this.getBoardData();
              }
            }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
          }
        }
      });
    } else {
      this.fileStorageData.getAll(3, Id).subscribe(response => {
        this.lstFileStorage = response;

        this.historyData.getByIssueId(Id).subscribe(response => {

          const dialogRef = this.dialog.open(HylaDialogComponent, {
            data: {
              issue: true,
              entIssue: JSON.parse(JSON.stringify(this.entIssue)),
              projectUsers: this.lstProjectUsers,
              sprints: this.lstSprint.filter(x => x.id == this.entIssue.sprintId || x.actualEndDate == null),
              dataSource: this.lstFileStorage,
              issueHistory: response,
              fileStorageData: this.fileStorageData,
              saveButton: 'Save',
              cancelButton: 'Cancel'
            }
          });

          dialogRef.afterClosed().subscribe((entIssue: any) => {
            if (entIssue != '' && entIssue != undefined) {
              if (entIssue.id > 0) { // Edit Issue
                this.issueData.update(entIssue).subscribe(data => {
                  if (type == 0) {
                    this.getData();
                  } else {
                    this.getBoardData();
                    this.entSprint.plannedEffort += entIssue.plannedEffortDifference;
                  }
                });
              } else { // Add Issue
                this.issueData.add(entIssue).subscribe(data => {
                  if (type == 0) {
                    this.getData();
                  } else {
                    this.getBoardData();
                  }
                }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
              }
            }
          });
        });
      });
    }
  }

  openDeleteIssueDialog(parentId: number, Id: number) {
    this.entIssue = this.lstEpic.find(x => x.id == parentId).issue.find(x => x.id == Id);

    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        title: 'Confirm deletion',
        message: 'Do you want to delete the issue ' + this.entIssue.title + '?',
        saveButton: 'Yes',
        cancelButton: 'No'
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      // entEpic.projectId = this.modelProject;
      if (confirmed) {
        this.issueData.delete(Id).subscribe(data => {
          this.getData();
        }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
      }
    });
  }

  openBurnDownChartDialog() {
    let dateDifference = Math.round(this.entSprint.plannedEndDate.getTime() - this.entSprint.plannedStartDate.getTime()) / (1000 * 60 * 60 * 24);

    let idealData = this.yAxies(dateDifference, 0);
    let remainingEffortData = this.yAxies(dateDifference, 1, this.lstIssue.filter(x => x.sprintId == this.modelSprint && x.status == 4));
    let labels = this.labels(dateDifference);

    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        title: 'Sprint Burndown Chart',
        chart: true,
        myChartConfig: {
          type: 'line',
          data: {
            labels: labels,
            datasets: [{
              label: 'Remaining Effort',
              data: remainingEffortData,
              fill: false,
              borderColor: 'rgba(255, 205, 86, 1)',
              tension: 0.1
            },
            {
              label: 'Ideal trend',
              data: idealData,
              fill: false,
              borderColor: 'rgb(54, 162, 235)',
              tension: 0.1
            }]
          },
        },
        // lstIssue: this.lstIssue.filter(x => x.sprintId == this.modelSprint),
        // saveButton: 'Save',
        cancelButton: 'Close'
      }
    });
  }

  labels(dateDifference) {
    let labels = ['Day 0'];

    for (let i = 0; i < dateDifference; i++) {
      labels.push('Day ' + (i + 1));
    }

    return labels;
  }

  yAxies(dateDifference, type, lstIssue?: Issue[]) {
    let data = [this.entSprint.plannedEffort];
    let date = new Date(this.entSprint.actualStartDate);
    let effort = this.entSprint.plannedEffort;

    for (let i = 0; i < dateDifference; i++) {
      if (type == 0) {
        let information = (this.entSprint.plannedEffort - (this.entSprint.plannedEffort / dateDifference * (i + 1))).toFixed(1);
        data.push(Number(information));
      } else {

        date.setDate(date.getDate() + 1);

        if (date <= new Date()) {
          lstIssue.filter(x => new Date(x.endDate) != undefined && new Date(x.endDate).getDate() == date.getDate() && new Date(x.endDate).getMonth() == date.getMonth() && new Date(x.endDate).getFullYear() == date.getFullYear()).map(function (entIssue) {
            effort += -entIssue.effort;
          });

          data.push(Number(effort.toFixed(1)));
        }
      }
    }

    return data;
  }

  /**
   * Board
   */
  getBoardData() {
    this.todo = [];
    this.doing = [];
    this.review = [];
    this.redo = [];
    this.done = [];
    this.entSprint = this.lstSprint.find(x => x.id == this.modelSprint);

    this.issueData.getAllBySprintId(0, -1, 0, this.modelSprint).subscribe(response => {
      this.todo = response.data.map(data => new Issue().deserialize(data));

      this.issueData.getAllBySprintId(0, -1, 1, this.modelSprint).subscribe(response => {
        this.doing = response.data.map(data => new Issue().deserialize(data));

        this.issueData.getAllBySprintId(0, -1, 2, this.modelSprint).subscribe(response => {
          this.review = response.data.map(data => new Issue().deserialize(data));

          this.issueData.getAllBySprintId(0, -1, 3, this.modelSprint).subscribe(response => {
            this.redo = response.data.map(data => new Issue().deserialize(data));

            this.issueData.getAllBySprintId(0, this.issueNumber, 4, this.modelSprint).subscribe(response => {
              this.done = response.data.map(data => new Issue().deserialize(data));
              this.totalRecords = response.totalRecords;
            });
          });
        });
      });
    });

    // this.issueData.getAllByProjectId(0, this.issueNumber, this.modelProject).subscribe(response => {
    //   this.lstIssue = response.data.map(data => new Issue().deserialize(data));
    //   this.totalRecords = response.totalRecords;

    //   this.lstIssue.forEach(entIssue => {
    //     switch (entIssue.status) {
    //       case 0:
    //         this.todo.push(entIssue);
    //         break;

    //       case 1:
    //         this.doing.push(entIssue);
    //         break;

    //       case 2:
    //         this.review.push(entIssue);
    //         break;

    //       case 3:
    //         this.redo.push(entIssue);
    //         break;

    //       case 4:
    //         this.done.push(entIssue);
    //         break;

    //       default:
    //         break;
    //     }
    //   });
    // });
  }

  issuePaging(type: number) {
    if (type == 1) {
      this.issueNumber += 3;
    } else {
      this.issueNumber -= 3;
      if (this.issueNumber < 3) this.issueNumber = 3;
    }

    this.issueData.getAllBySprintId(0, this.issueNumber, 4, this.modelSprint).subscribe(response => {
      this.done = response.data.map(data => new Issue().deserialize(data));
      this.totalRecords = response.totalRecords;
    });
  }

  boardDisabled() {
    if (this.lstSprint != undefined) {
      return this.lstSprint.filter(x => x.actualStartDate != null).length > 0 ? false : true;
    }
  }

  boardDrop(event: CdkDragDrop<string[]>, status?: number) {
    if (this.lstSprint.find(x => x.id == this.modelSprint).actualEndDate == null) {
      if (event.previousContainer === event.container && status == 0) {
        let entIssue = event.container.data[event.previousIndex];
        this.issueData.updateSequence(entIssue['id'], event.currentIndex).subscribe(data => {
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        });
      } else {
        var sequence = parseInt(event.previousContainer.data[event.previousIndex]['epic']['sequence'].toString() + event.previousContainer.data[event.previousIndex]['sequence'].toString());
        event.currentIndex = event.container.data.length;

        var statusValidation = AppSettings.activitiesStatus.filter(x => x.currentStatus == event.previousContainer.data[event.previousIndex]['status']);

        if (statusValidation == undefined || statusValidation[0].nextStatus.find(x => x == status).length == 0) {
          return;
        }

        if (event.container.data.length > 0) {
          for (let i = 0; i < event.container.data.length; i++) {
            var entIssue = event.container.data[i];
            var sequenceCurrent = parseInt(entIssue['epic']['sequence'].toString() + entIssue['sequence'].toString());
            var index = event.container.data.findIndex(x => x['id'] == entIssue['id']);

            if (sequence < sequenceCurrent) {
              event.currentIndex = index;
              break;
            }
          }
        }

        var previousStatus = event.previousContainer.data[event.previousIndex]['status'];

        event.previousContainer.data[event.previousIndex]['status'] = status;
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);

        // Chamar API para atualizar sequence
        this.issueData.updateStatus(event.container.data[event.currentIndex]['id'], status).subscribe(data => {
          this.entSprint.actualEffort += data;
          this.entSprint.actualEffort = Number(this.entSprint.actualEffort.toFixed(2));
          let entIssue = event.container.data[event.currentIndex];
          this.lstEpic.find(x => x.id == entIssue['epicId']).issue.find(x => x.id == entIssue['id']).actualEffort += data;
          this.lstEpic.find(x => x.id == entIssue['epicId']).issue.find(x => x.id == entIssue['id']).actualEffort = Number(this.lstEpic.find(x => x.id == entIssue['epicId']).issue.find(x => x.id == entIssue['id']).actualEffort.toFixed(2))

        }, error => {
          event.container.data[event.currentIndex]['status'] = previousStatus;
          transferArrayItem(event.container.data,
            event.previousContainer.data,
            event.currentIndex,
            event.previousIndex);
        });
      }
    }
  }

  /**
   * Start Backlog
   */
  transformer = (node: BacklogNode, level: number) => {
    return new BacklogFlatNode(
      !!node.children,
      node.title,
      level,
      node.type,
      node.id,
      node.dbId,
      node.parentId,
      node.effort,
      node.status,
      node.responsible,
      node.startDate,
      node.endDate,
      node.sprintId
    );
  };
  private _getLevel = (node: BacklogFlatNode) => node.level;
  private _isExpandable = (node: BacklogFlatNode) => node.expandable;
  private _getChildren = (node: BacklogNode): Observable<BacklogNode[]> =>
    observableOf(node.children);
  hasChild = (_: number, _nodeData: BacklogFlatNode) => _nodeData.level == 0 ? true : false;

  // DRAG AND DROP METHODS

  shouldValidate(event: any): void {
    this.validateDrop = event.checked;
  }

  /**
   * This constructs an array of nodes that matches the DOM
   */
  visibleNodes(): BacklogNode[] {
    const result = [];

    function addExpandedChildren(node: BacklogNode, expanded: string[]) {
      result.push(node);
      if (expanded.includes(node.id)) {
        node.children.map(child => addExpandedChildren(child, expanded));
      }
    }
    this.dataSource.data.forEach(node => {
      addExpandedChildren(node, this.expansionModel.selected);
    });
    return result;
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   * */
  drop(event: CdkDragDrop<string[]>) {
    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) return;
    // construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes();
    // deep clone the data source so we can mutate it
    const changedData = JSON.parse(JSON.stringify(this.dataSource.data));

    // recursive find function to find siblings of node
    function findNodeSiblings(arr: Array<any>, id: string): Array<any> {
      let result, subResult;
      arr.forEach((item, i) => {
        if (item.id === id) {
          result = arr;
        } else if (item.children) {
          subResult = findNodeSiblings(item.children, id);
          if (subResult) result = subResult;
        }
      });
      return result;
    }

    // determine where to insert the node
    const nodeAtDest = visibleNodes[event.currentIndex];
    const newSiblings = findNodeSiblings(changedData, nodeAtDest.id);
    if (!newSiblings) return;
    const insertIndex = newSiblings.findIndex(s => s.id === nodeAtDest.id);

    // remove the node from its old place
    const node = event.item.data;
    const siblings = findNodeSiblings(changedData, node.id);
    const siblingIndex = siblings.findIndex(n => n.id === node.id);
    const nodeToInsert: BacklogNode = siblings.splice(siblingIndex, 1)[0];
    if (nodeAtDest.id === nodeToInsert.id) return;

    // ensure validity of drop - must be same level
    const nodeAtDestFlatNode = this.treeControl.dataNodes.find(
      n => nodeAtDest.id === n.id
    );
    //if (this.validateDrop && nodeAtDestFlatNode.level !== node.level) {
    if (
      this.validateDrop &&
      this.getParentNode(nodeAtDestFlatNode) !== this.getParentNode(node)
    ) {
      //alert('Items can only be moved within the same level.');
      return;
    }

    // insert node
    newSiblings.splice(insertIndex, 0, nodeToInsert);

    //Chamar API para atualizar sequence
    if (node.level == 0) {
      this.epicData.updateSequence(node.dbId, insertIndex).subscribe(data => { this.getData() });
      // console.log("atualiza EPIC id = " + idSplit[1] + " para posição " + insertIndex);
    } else {
      this.issueData.updateSequence(node.dbId, insertIndex).subscribe(data => { this.getData() });
      // console.log("atualiza ISSUE id = " + idSplit[2] + " para posição " + insertIndex);
    }

    // rebuild tree with mutated data
    this.rebuildTreeForData(changedData);
  }

  /**
   * Experimental - opening tree nodes as you drag over them
   */
  dragStart() {
    this.dragging = true;
  }
  dragEnd() {
    this.dragging = false;
  }
  dragHover(node: BacklogFlatNode) {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
      this.expandTimeout = setTimeout(() => {
        this.treeControl.expand(node);
      }, this.expandDelay);
    }
  }
  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
    }
  }

  /**
   * The following methods are for persisting the tree expand state
   * after being rebuilt
   */

  rebuildTreeForData(data: any) {
    this.dataSource.data = data;
    this.expansionModel.selected.forEach(id => {
      const node = this.treeControl.dataNodes.find(n => n.id === id);
      this.treeControl.expand(node);
    });
  }

  /**
   * Not used but you might need this to programmatically expand nodes
   * to reveal a particular node
   */
  private expandNodesById(flatNodes: BacklogFlatNode[], ids: string[]) {
    if (!flatNodes || flatNodes.length === 0) return;
    const idSet = new Set(ids);
    return flatNodes.forEach(node => {
      if (idSet.has(node.id)) {
        this.treeControl.expand(node);
        let parent = this.getParentNode(node);
        while (parent) {
          this.treeControl.expand(parent);
          parent = this.getParentNode(parent);
        }
      }
    });
  }

  private getParentNode(node: BacklogFlatNode): BacklogFlatNode | null {
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
