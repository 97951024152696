import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Issue } from '../models/issue.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class IssueService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.issueEndPoint; //'https://localhost:44325/api/issue';

    getAll(epicId: number) {
        return this.http.get<Issue[]>(this.URI + '?epicId=' + epicId).pipe(
            map(data => data.map(data => new Issue().deserialize(data)))
        );
    }

    getAllBySprintId(page: number, pageSize: number, onlyStatus: number, sprintId: number) {
        return this.http.get<Issue[]>(this.URI + '/GetBySprint/' + sprintId + '?page=' + page + '&pageSize=' + pageSize + '&onlyStatus=' + onlyStatus).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<Issue>(this.URI + '/' + Id).pipe(
            map(data => new Issue().deserialize(data))
        );
    }

    add(entIssue: Issue) {
        return this.http.post<Issue>(this.URI, entIssue);
    }

    update(entIssue: Issue) {
        return this.http.put<Issue>(this.URI + '/' + entIssue.id, entIssue);
    }

    updateSequence(id: number, newSequence: number) {
        return this.http.put<Issue>(this.URI + '/UpdateSequence/' + id + '/' + newSequence, '');
    }

    updateStatus(id: number, newStatus: number) {
        return this.http.put<number>(this.URI + '/UpdateStatus/' + id + '/' + newStatus, '');
    }
    
    updateSprint(id: number, sprintId: number) {
        return this.http.put<Issue>(this.URI + '/UpdateSprint/' + id + '/' + sprintId, '');
    }

    delete(Id: number) {
        return this.http.delete<Issue>(this.URI + '/' + Id);
    }
}