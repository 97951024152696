import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

// Project
import { Project } from '../../../models/project.model';
import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
  columns = [
    { prop: 'client.name', name: 'Customer', pipe: 'customer', minWidth: 17 },
    { prop: 'projectName', name: 'Project', minWidth: 25 },
    { prop: 'estimatedStartDate', name: 'Estimated Start', pipe: 'date' },
    { prop: 'estimatedEndDate', name: 'Estimated End', pipe: 'date' },
    { prop: 'startedDate', name: 'Started Date', pipe: 'date' },
    { prop: 'hoursPackage', name: 'Hours Package', pipe: 'hoursPackage', maxWidth: 10 },
    { prop: 'active', name: 'Active', pipe: 'active', maxWidth: 12 }
  ];

  dialogDelete = {
    col: 'projectName',
    data: {
      title: 'Confirm deletion', message: 'Do you want to delete the project', saveButton: 'Yes', cancelButton: 'No'
    }
  };

  modelActive: boolean = true;

  userRole: any;
  userType: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private projectData: ProjectService) { }

  // Project Variables
  public lstProject: Project[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    // if (this.userRole == 3 && this.userType == 1) {
    //   this.router.navigate(['/manager']);
    // }

    if (SessionManager.SessionExist('projectPage')) {
      this.gridPaging[0] = SessionManager.GetSession('projectPage');
    }

    if (SessionManager.SessionExist('projectSearch')) {
      this.gridSearch = SessionManager.GetSession('projectSearch');
    }

    if (SessionManager.SessionExist('projectToast')) {
      SessionManager.DeleteSession('projectToast');
      AppSettings.openSnackBar(this._snackBar, 'Project successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    var userId = 0;
    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }

    if (this.userRole == 3 && this.userType == 1) {
      userId = jwt_decode(localStorage.getItem('currentUser')).userId;
      // this.columns = [{ prop: 'client.name', name: 'Customer', pipe: 'customer' }, { prop: 'projectName', name: 'Project' }, { prop: 'estimatedStartDate', name: 'Estimated Start', pipe: 'date' }, { prop: 'estimatedEndDate', name: 'Estimated End', pipe: 'date' }, { prop: 'startedDate', name: 'Started Date', pipe: 'date' }, { prop: 'active', name: 'Active', pipe: 'active' }];
    }

    this.projectData.getAll(pageInfo.pageIndex, pageInfo.search, this.modelActive, userId).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstProject = response.data.map(data => new Project().deserialize(data));
    });
  }

  addClick() {
    this.router.navigate(['/manager/project/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('projectPage', this.gridPaging[0].toString());
    SessionManager.SetSession('projectSearch', this.gridSearch);
    this.router.navigate(['/manager/project/edit/' + value])
  }

  onDelete(value: any) {
    this.projectData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

  onActivities(value: any) {
    SessionManager.SetSession('projectId', value.id);
    this.router.navigate(['/manager/projectactivities']);
  }

  onActiveToggleChange(value: boolean) {
    this.modelActive = value;
    this.getData();
  }

  onEffortReportClick(value: any) {
    this.router.navigate(['/manager/project/effortreport/' + value]);
  }

}
