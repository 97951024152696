import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Location
import { Location } from '../../../models/location.model';
import { LocationService } from '../../../services/location.service';

@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss']
})
export class LocationDetailComponent implements OnInit {
  public validationForm: FormGroup;
  responseError: any;
  userRole: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private locationData: LocationService) { }

  // Location Variables 
  public entLocation: Location;

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.entLocation = new Location();
    if (this.userRole != 1 && this.userRole != 2) {
      this.router.navigate(['/manager']);
    }

    this.getData();
    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      locationName: new FormControl('', [Validators.required, Validators.minLength(5)]),
      address: new FormControl('', [Validators.required, Validators.minLength(6)]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl('', null),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]),
      zipCode: new FormControl('', [Validators.required])
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.locationData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entLocation = response;
        this.entLocation.zipCode = this.entLocation.zipCode.substr(0, 5) + '-' + this.entLocation.zipCode.substr(5, this.entLocation.zipCode.length);
      });
    } else {
      this.entLocation.id = 0;
    }
  }

  zipCodeMask(event: any) {
    if (this.entLocation.zipCode != undefined) {
      if (this.entLocation.zipCode.length == 5) {
        this.entLocation.zipCode = this.entLocation.zipCode + '-';
      } else if (this.entLocation.zipCode.length > 9) {
        this.entLocation.zipCode = this.entLocation.zipCode.substr(0, 9);
      }
    }
  }

  onSubmit() {
    if (this.validationForm.invalid) {
      return;
    }

    this.save();
  }

  save() {
    this.entLocation.zipCode = this.entLocation.zipCode.substr(0, 5) + this.entLocation.zipCode.substr(6, this.entLocation.zipCode.length);
    if (this.entLocation.id == 0) { // Add
      this.locationData.add(this.entLocation).subscribe(data => {
        this.redirect();
      }, error => this.responseError = error.error.message);
    } else { // Edit
      this.locationData.update(this.entLocation).subscribe(data => {
        this.redirect();
      }, error => this.responseError = error.error.message);
    }
  }

  redirect() {
    SessionManager.SetSession('locationToast', '1');
    this.router.navigate(['/manager/location']);
  }

  cancelClick() {
    this.router.navigate(['/manager/location']);
  }

}
