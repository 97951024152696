import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

// Holidays
import { Holidays } from '../../../models/holidays.model';
import { HolidaysService } from '../../../services/holidays.service';

@Component({
  selector: 'app-holidays-list',
  templateUrl: './holidays-list.component.html',
  styleUrls: ['./holidays-list.component.scss']
})
export class HolidaysListComponent implements OnInit {
  columns = [{ prop: 'date', name: "Date", pipe: "date" }, { prop: 'name', name: "Holiday Name" }, { prop: 'locationId', name: "Location", pipe: 'location' }];
  dialogDelete = {
    col: 'name',
    data: {
      title: 'Confirm deletion', message: 'Do you want to delete the holiday', saveButton: 'Yes', cancelButton: 'No'
    }
  };
  userRole: any;
  userType: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private holidaysData: HolidaysService) { }

  // Holidays Variables 
  public lstHolidays: Holidays[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole != 1 && this.userRole != 2 && this.userType != 1) {
      this.router.navigate(['/manager']);
    }

    if (SessionManager.SessionExist('holidaysPage')) {
      this.gridPaging[0] = SessionManager.GetSession('holidaysPage');
    }

    if (SessionManager.SessionExist('holidaysSearch')) {
      this.gridSearch = SessionManager.GetSession('holidaysSearch');
    }

    if (SessionManager.SessionExist('holidaysToast')) {
      SessionManager.DeleteSession('holidaysToast');
      AppSettings.openSnackBar(this._snackBar, 'Holidays successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }

    this.holidaysData.getAll(pageInfo.pageIndex, pageInfo.search, null, null).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstHolidays = response.data.map(data => new Holidays().deserialize(data));
    });
  }

  addClick() {
    this.router.navigate(['/manager/holidays/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('holidaysPage', this.gridPaging[0].toString());
    SessionManager.SetSession('holidaysSearch', this.gridSearch);
    this.router.navigate(['/manager/holidays/edit/' + value])
  }

  onDelete(value: any) {
    this.holidaysData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

}
