import { Component, OnInit } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

// Materials Components
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// Session Storage
import { SessionManager } from 'src/app/utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

// File Storage
import { FileStorageService } from 'src/app/services/fileStorage.service';

// Supplier
import { Supplier } from 'src/app/models/supplier.model';
import { SupplierService } from 'src/app/services/supplier.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent implements OnInit {
  columns = [{ prop: 'name', name: 'Name' }, { prop: 'taxNumber', name: 'Tax Number' }, { prop: 'city', name: 'City' }, { prop: 'state', name: 'State' }];
  dialogDelete = { col: 'name', data: { title: 'Confirm deletion', message: 'Do you want to delete the supplier', saveButton: 'Yes', cancelButton: 'No' } };

  userRole: any;
  userType: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private fileStorageData: FileStorageService,
    private supplierData: SupplierService) { }

  public lstSupplier: Supplier[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole == 3 && this.userType == 1) {
      this.router.navigate(['/manager']);
    }

    if (SessionManager.SessionExist('supplierPage')) {
      this.gridPaging[0] = SessionManager.GetSession('supplierPage');
    }

    if (SessionManager.SessionExist('supplierSearch')) {
      this.gridSearch = SessionManager.GetSession('supplierSearch');
    }

    if (SessionManager.SessionExist('supplierToast')) {
      SessionManager.DeleteSession('supplierToast');
      AppSettings.openSnackBar(this._snackBar, 'Supplier successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }

    this.supplierData.getAll(pageInfo.pageIndex, pageInfo.search).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstSupplier = response.data.map(data => new Supplier().deserialize(data));
    });
  }

  addClick() {
    this.router.navigate(['/manager/supplier/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('supplierPage', this.gridPaging[0].toString());
    SessionManager.SetSession('supplierSearch', this.gridSearch);
    this.router.navigate(['/manager/supplier/edit/' + value])
  }

  onDelete(value: any) {
    this.supplierData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

}

