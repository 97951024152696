import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'activityType' })
export class ActivityTypePipe implements PipeTransform {
    transform(type: number): string {
        var activityType;
        switch (type) {
            case 1: {
                activityType = 'Project Management';
                break;
            }
            case 2: {
                activityType = 'Pre-Sales';
                break;
            }
            case 3: {
                activityType = 'Design';
                break;
            }
            case 4: {
                activityType = 'Development';
                break;
            }
            case 5: {
                activityType = 'Internal Testing';
                break;
            }
            case 6: {
                activityType = 'SAT';
                break;
            }
            case 7: {
                activityType = 'Assited Operation';
                break;
            }
            case 8: {
                activityType = 'Maintenance Support';
                break;
            }
            case 9: {
                activityType = 'Other';
                break;
            }
            case 10: {
                activityType = 'Meeting';
            }
        }

        return activityType;
    }
}