import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

// Settings
import { AppSettings } from 'src/app/utils/settings';

// Toast
import { MatSnackBar } from '@angular/material/snack-bar';

//Dialog
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HylaDialogComponent } from 'src/app/components/hyla-dialog/hyla-dialog.component'

// Holidays
import { Holidays } from '../../models/holidays.model';
import { HolidaysService } from 'src/app/services/holidays.service';

// Timesheet 
import { Timesheet } from '../../models/timesheet.model';
import { TimesheetService } from 'src/app/services/timesheet.service';

// Timesheet Balance
import { TimesheetBalance } from '../../models/timesheetBalance.model';
import { TimesheetBalanceService } from 'src/app/services/timesheetBalance.service';

// User
import { User } from '../../models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-monthly-closure-list',
  templateUrl: './monthly-closure.component.html',
  styleUrls: ['./monthly-closure.component.scss']
})
export class MonthlyClosureComponent implements OnInit {
  modelMonth: any = new Date().getMonth() == 0 ? 12 : new Date().getMonth();
  modelYear: any = new Date().getMonth() == 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();
  year: number[] = [(new Date().getFullYear() - 1), new Date().getFullYear(), (new Date().getFullYear() + 1)];
  businessDay: number;
  responseError: string;
  userRole: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private holidaysData: HolidaysService,
    private timesheetData: TimesheetService,
    private timesheetBalanceData: TimesheetBalanceService,
    private userData: UserService) { }

  // Holidays Variables 
  public lstHolidays: Holidays[];

  // Timesheet Variables
  public lstTimesheet: Timesheet[];

  // Timesheet Balance Variables
  public entTimesheetBalance: TimesheetBalance;
  public lstTimesheetBalance: TimesheetBalance[];

  // User Variables
  public lstUser: User[];

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    if (this.userRole != 1 && this.userRole != 2) {
      this.router.navigate(['/manager']);
    }

  }

  getData() {
    var startDate = new Date(this.modelYear, this.modelMonth - 1, 1);
    var endDate = new Date(this.modelYear, this.modelMonth - 1, 1);
    var lastMonth = this.lastDayMonth(endDate);

    this.userData.getAll(-1, '').subscribe(response => {
      this.lstUser = response.data.map(data => new User().deserialize(data)).filter(x => x.type == 1 && (x.dismissDate == undefined || (x.dismissDate >= lastMonth) || x.dismissDate.getMonth() == this.modelMonth - 1 && x.dismissDate.getFullYear() == this.modelYear));

      this.timesheetBalanceData.getAll(0, this.modelMonth == 1 ? this.modelYear - 1 : this.modelYear, this.modelMonth == 1 ? 12 : this.modelMonth - 1).subscribe(response => {
        this.lstTimesheetBalance = response;

        this.timesheetData.getAll(-1, '', startDate, lastMonth, -1).subscribe(response => {
          this.lstTimesheet = response.data.map(data => new Timesheet().deserialize(data));

          this.holidaysData.getAll(-1, '', startDate, lastMonth).subscribe(response => {
            this.lstHolidays = response.data.map(data => new Holidays().deserialize(data));

          });
        });
      });
    });
  }

  lastDayMonth(date: Date) {
    var data = date;
    var Month = data.getMonth();

    this.businessDay = 0;

    while (data.getMonth() == Month) {

      if (data.getDay() >= 1 && data.getDay() <= 5) {
        this.businessDay++;
      }

      data.setDate(data.getDate() + 1);
    }

    data.setDate(data.getDate() - 1);

    return data;
  }

  openDialog(value: User, type: number) {
    var data = { title: '', message: '', saveButton: '', cancelButton: '', table: false, dataSource: [] };

    if (type == 1) {
      data.title = 'Confirm save';
      data.message = 'Do you want to save the timesheet balance ' + value.name + '?';
      data.saveButton = 'Yes';
      data.cancelButton = 'No';
    } else {
      data.title = 'Timesheet view';
      data.table = true;
      data.dataSource = this.lstTimesheet.filter(x => x.userId == value.id);
      data.cancelButton = 'Close';
    }

    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        title: data.title,
        message: data.message,
        table: data.table,
        dataSource: data.dataSource,
        saveButton: data.saveButton,
        cancelButton: data.cancelButton
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.dataTimesheetBalance(value);
        this.onSave();

      }
    });
  }

  dataTimesheetBalance(user: User) {
    this.responseError = "";
    this.entTimesheetBalance = new TimesheetBalance();
    this.entTimesheetBalance.userId = user.id;
    this.entTimesheetBalance.month = this.modelMonth;
    this.entTimesheetBalance.year = this.modelYear;
    this.entTimesheetBalance.hoursNormal = this.gridInformation(user.id, 1);
    this.entTimesheetBalance.hoursBasket = this.gridInformation(user.id, 4);
    this.entTimesheetBalance.hoursLicense = this.gridInformation(user.id, 5);
    this.entTimesheetBalance.hoursVacation = this.gridInformation(user.id, 6);
    this.entTimesheetBalance.hoursExtra = this.gridInformation(user.id, 2);
    this.entTimesheetBalance.hoursWarning = this.gridInformation(user.id, 3);
    this.entTimesheetBalance.hoursExtraPrevious = this.extraHourInformation(user, 1);
    this.entTimesheetBalance.hoursExtraTotal = this.extraHourInformation(user, 2);
    this.entTimesheetBalance.workingHours = user.workingHours;
  }

  onSave() {
    if (this.entTimesheetBalance.hoursNormal == 0 && this.entTimesheetBalance.hoursBasket == 0 && this.entTimesheetBalance.hoursLicense == 0 && this.entTimesheetBalance.hoursVacation == 0 && this.entTimesheetBalance.hoursExtra == 0 && this.entTimesheetBalance.hoursWarning == 0) {
      this.responseError = "We cannot save without information";
      return
    }
    this.timesheetBalanceData.add(this.entTimesheetBalance).subscribe(data => { }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

  round(user: User) {

    if (this.lstHolidays != undefined) {
      var HolidaysLength = this.lstHolidays.filter(x => (x.locationId == user.locationId || x.locationId == 0) && x.date.getDay() != 0 && x.date.getDay() != 6).length;
      var RegisteredHours = this.gridInformation(user.id, 8);
      var RoundHours = (RegisteredHours * 100) / ((user.workingHours / 5) * (this.businessDay - HolidaysLength));

      return RoundHours.toFixed(1);
    }
  }

  gridInformation(Id: number, type: number, vacation?: boolean) {
    var Information = 0;
    var lstTimesheetUserLocal: Timesheet[];
    var i;

    if (this.lstTimesheet != undefined) {
      if (type == 7) {
        lstTimesheetUserLocal = this.lstTimesheet.filter(x => x.userId == Id && (x.hourType == 1 || x.hourType == 4 || x.hourType == 5 || x.hourType == 6) && x.registeredDate.getDay() != 0 && x.registeredDate.getDay() != 6);
      } else if (type == 8) {
        lstTimesheetUserLocal = this.lstTimesheet.filter(x => x.userId == Id && (x.hourType == 1 || x.hourType == 4 || x.hourType == 5 || x.hourType == 6) && x.registeredDate.getDay() != 0 && x.registeredDate.getDay() != 6);
      } else if (type == 6 && vacation) {
        lstTimesheetUserLocal = this.lstTimesheet.filter(x => x.userId == Id && x.hourType == type && x.registeredDate.getDay() != 0 && x.registeredDate.getDay() != 6);
      } else {
        lstTimesheetUserLocal = this.lstTimesheet.filter(x => x.userId == Id && x.hourType == type);
      }

      for (i = 0; i < lstTimesheetUserLocal.length; i++) {
        if (this.lstHolidays == undefined || this.lstHolidays.find(x => x.date.getDate() == lstTimesheetUserLocal[i].registeredDate.getDate() && (x.locationId == this.lstUser.find(x => x.id == Id).locationId || x.locationId == 0)) == undefined || type != 8) {
          Information += lstTimesheetUserLocal[i].registeredHours;
        }
      }
    }

    return Information;
  }

  extraHourInformation(user: User, type: number) {
    var Information;

    if (this.lstTimesheetBalance != undefined) {
      var LastMonth = this.lstTimesheetBalance.find(x => x.userId == user.id);
      var HoursExtraPrevious;

      if (LastMonth === undefined) {
        HoursExtraPrevious = 0;
      } else {
        HoursExtraPrevious = LastMonth.hoursExtraTotal;
      }

      if (type == 1) {
        Information = HoursExtraPrevious;
      } else {
        Information = (HoursExtraPrevious + this.gridInformation(user.id, 2)) - this.gridInformation(user.id, 4);
      }
    }

    return Information;
  }

}
