import { Client } from './client.model';
import { Deserializable } from './deserializable.model';
import { Project } from './project.model';

export class PurchaseOrder implements Deserializable {
  public id: number;
  public number: number;
  public emissionDate: Date;
  public projectId: number;
  public item1Code: string;
  public item1Description: string;
  public item1Value: number;
  public item2Code: string;
  public item2Description: string;
  public item2Value: number;
  public item3Code: string;
  public item3Description: string;
  public item3Value: number;
  public totalValue: number;
  public hasFile: boolean;
  public active: boolean;
  public project: Project;

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    newObj.emissionDate = new Date(Number(this.emissionDate.toString().substr(0, 4)), Number(this.emissionDate.toString().substr(5, 2)) - 1, Number(this.emissionDate.toString().substr(8, 2)));

    return newObj;
  }
}
