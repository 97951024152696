import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Location } from '../models/location.model';

import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class LocationService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.locationEndPoint; //'https://localhost:44325/api/location';


    getAll(page: number, search: string) {
        if (search == undefined) {
            search = "";
        }
        
        return this.http.get<ResponseData>(this.URI + "?page=" + page + "&pageSize=" + AppSettings.pageSize + "&search="+ search).pipe(
            map(data => new ResponseData().deserialize(data))
            //response.data.map(data => new Location().deserialize(data));
            // map(data => data.map(data => new Location().deserialize(data)))
          );
    }

    getById(Id: number) {
        return this.http.get<Location>(this.URI + '/' + Id).pipe(
            map(data => new Location().deserialize(data))
          );
    }

    add(entLocation: Location) {
        return this.http.post<Location>(this.URI, entLocation);
    }

    update(entLocation: Location) {
        return this.http.put<Location>(this.URI + '/' + entLocation.id, entLocation);
    }

    delete(Id: number) {
        return this.http.delete<Location>(this.URI + '/' + Id);
    }
}