import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

// Timesheet
import { Timesheet } from '../../../models/timesheet.model';
import { TimesheetService } from '../../../services/timesheet.service';
import { EventSettingsModel, ScheduleComponent, View } from '@syncfusion/ej2-angular-schedule';
import * as $ from 'jquery';

@Component({
  selector: 'app-timesheet-list',
  templateUrl: './timesheet-list.component.html',
  styleUrls: ['./timesheet-list.component.scss']
})

export class TimesheetListComponent implements OnInit {
  @ViewChild("scheduleObj", { static: false })

  public scheduleObj: ScheduleComponent;
  public currentView: View = 'Month';
  public views: View[] = ['Month']
  public selectedDate: Date = new Date();

  public data: object[];

  public eventSettings: EventSettingsModel = {
    dataSource: [],
  };

  // columns = [
  //   { prop: 'registeredDate', name: 'Registered Date', pipe: 'date' },
  //   { prop: 'registeredDate', name: 'Weekday', pipe: 'weekday' },
  //   { prop: 'registeredHours', name: 'Registered Hours' },
  //   { prop: 'activityType', name: 'Activity Type', pipe: 'activityType' },
  //   { prop: 'hourType', name: 'Hour Type', pipe: 'hourType' },
  //   { prop: 'project.projectName', name: 'Project', pipe: 'project', minWidth: 30 }
  // ];

  dialogDelete = {
    col: 'registeredDate',
    data: {
      title: 'Confirm deletion', message: 'Do you want to delete the timesheet', saveButton: 'Yes', cancelButton: 'No'
    }
  };

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private timesheetData: TimesheetService) { }

  // Timesheet Variables 
  public lstTimesheet: Timesheet[];

  ngOnInit(): void {
    // if (SessionManager.SessionExist('timesheetPage')) {
    //   this.gridPaging[0] = SessionManager.GetSession('timesheetPage');
    // }

    // if (SessionManager.SessionExist('timesheetSearch')) {
    //   this.gridSearch = SessionManager.GetSession('timesheetSearch');
    // }

    // if (SessionManager.SessionExist('timesheetToast')) {
    //   SessionManager.DeleteSession('timesheetToast');
    //   AppSettings.openSnackBar(this._snackBar, 'Timesheet successfully saved', 5, AppSettings.Toast_Color_Success);
    // }

    // var startDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);
    // var endDate = new Date(startDate);
    // this.getData(startDate, this.lastDayMonth(endDate));
  }

  getData(startDate: Date, endDate: Date) {
    this.data = [];

    this.timesheetData.getAll(-1, '', startDate, endDate, 0).subscribe(response => {
      this.lstTimesheet = response.data.map(data => new Timesheet().deserialize(data));

      this.lstTimesheet.forEach(entTimesheet => {
        this.data.push({
          Id: entTimesheet.id,
          Subject: entTimesheet.project.projectName,
          StartTime: entTimesheet.registeredDate,
          EndTime: entTimesheet.registeredDate,
          ActivityType: entTimesheet.activityType,
          HourType: entTimesheet.hourType,
          RegisteredHours: entTimesheet.registeredHours
        });
      });

      this.scheduleObj.eventSettings.dataSource = this.data;
    });
  }

  onCreated(): void { // Get start & end date on created
    let currentViewDates: Date[] = this.scheduleObj.getCurrentViewDates() as Date[];
    let startDate: Date = currentViewDates[0] as Date;
    let endDate: Date = currentViewDates[currentViewDates.length - 1] as Date;
    this.getData(startDate, endDate);
  }

  onActionComplete(args): void {
    if (args.requestType === "viewNavigate" || args.requestType === "dateNavigate") { // Get start & end date on navigation
      var currentViewDates = this.scheduleObj.getCurrentViewDates();
      var startDate = currentViewDates[0];
      var endDate = currentViewDates[currentViewDates.length - 1];
      this.getData(startDate, endDate);
    }
  }

  addClick(date?: Date) {
    this.router.navigate(['/manager/timesheet/new/' + date], { queryParams: { returnUrl: '/manager/timesheet' }});
  }

  public onPopupOpen(args: any): void {
    if (args.data.Id == undefined && args.type != 'EventContainer') { // New Timesheet
      this.addClick(args.data.startTime);
    } else if (args.type == 'Editor') {
      args.cancel = true;
    } else if (args.type == 'QuickInfo') {
      let dialog = args.element.ej2_instances[0];

      dialog.open = function () {
        var containtHeight = document.getElementsByClassName('page-container').item(0)['offsetHeight'];
        if (args.element.offsetTop > (containtHeight - 461)) args.element.style.top = (containtHeight - 461) + 'px';
      }

    }
  }

  public onCloseClick(): void {
    this.scheduleObj.quickPopup.quickPopupHide();
  }

  public onEditClick(Id: number): void {
    this.router.navigate(['/manager/timesheet/edit/' + Id], { queryParams: { returnUrl: '/manager/timesheet' }});
  }

  public onDeleteClick(Id: number): void {
    this.timesheetData.delete(Id).subscribe(data => { this.onCloseClick(); this.onActionComplete({ requestType: "viewNavigate" }); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }
}