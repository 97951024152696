import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import * as CryptoJS from 'crypto-js';

// User Model
import { User } from '../../models/user.model';

// Login Authentication
import { LoginService } from '../../services/login.service';

// App Settings
import { AppSettings } from '../../utils/settings';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public validation: FormGroup;

  constructor(private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  entUser: User;
  returnUrl: string;
  error = '';

  ngOnInit(): void {
    this.entUser = new User();

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    this.validation = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  encryptPass(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  Login() {
    this.error = "";
    
    // stop here if form is invalid
    if (this.validation.invalid) {
      return;
    }

    var encryptPassword = AppSettings.Encrypt(this.entUser.password);

    this.loginService.login(this.entUser.email, encryptPassword)
      .pipe(first())
      .subscribe(
        data => {
          if (this.returnUrl != undefined) {
            this.router.navigate([this.returnUrl]);
          } else {
            this.router.navigate(['/manager/dashboard']);
          }
        },
        error => this.error = error.error.message);

  }

}
