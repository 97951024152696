import { Deserializable } from './deserializable.model';
import { User } from './user.model';

export class UserTask implements Deserializable {
  public id: number;
  public title: string;
  public description: string;
  public dueDate: Date;
  public done: boolean;
  public userId: number;
  public user: User;

deserialize(input: any): this {
  var newObj = Object.assign(this, input);

  if (this.dueDate != null) {
    newObj.date = new Date(Number(this.dueDate.toString().substr(0, 4)), Number(this.dueDate.toString().substr(5, 2)) - 1, Number(this.dueDate.toString().substr(8, 2)));
  }

  return newObj;
}

}
