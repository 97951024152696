<div class="hyla-grid-header-container">
    <mat-card>
        <span class="spacer"></span>
        <span>
            <button mat-mini-fab color="primary" class="hyla-grid-header-add-button">
                <mat-icon>
                    add
                </mat-icon>
            </button>
        </span>
    </mat-card>
</div>
<div class="hyla-grid-card-container">
    <mat-card>
        
    </mat-card>
</div>