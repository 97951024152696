<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Title</mat-label>
                        <input matInput formControlName="title" [(ngModel)]="entUserTask.title">
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description"
                            [(ngModel)]="entUserTask.description"></textarea>
                    </mat-form-field>
                </div>
                <div [ngClass]="{ 'col-6': entUserTask.id > 0, 'col-12': entUserTask.id == 0 }">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Due Date</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="date" [(ngModel)]="entUserTask.dueDate">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-6" *ngIf="entUserTask.id > 0" style="padding-top: 15px;">
                    <mat-slide-toggle formControlName="done" [(ngModel)]="entUserTask.done">Done
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()" style="float: right;">Save</button>
                    <button mat-button (click)="cancelClick()" style="float: right;">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>