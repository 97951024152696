<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body" *ngIf="lstSupplier">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Supplier</mat-label>
                        <mat-select formControlName="supplierId" [(ngModel)]="entBill.supplierId">
                            <mat-option *ngFor="let supplier of lstSupplier" [value]="supplier.id">{{ supplier.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Number</mat-label>
                        <input matInput type="number" formControlName="number" [(ngModel)]="entBill.number">
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Emission Date</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="emissionDate"
                            [(ngModel)]="entBill.emissionDate">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Payment Type</mat-label>
                        <mat-select formControlName="paymentType" [(ngModel)]="entBill.paymentType">
                            <mat-option [value]="1">Service</mat-option>
                            <mat-option [value]="2">Product/Material</mat-option>
                            <mat-option [value]="3">Others</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Total Value</mat-label>
                        <input matInput type="number" formControlName="totalValue" [(ngModel)]="entBill.totalValue">
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Net Value</mat-label>
                        <input matInput type="number" formControlName="netValue" [(ngModel)]="entBill.netValue">
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Payment Forecast</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="paymentForecast"
                            [(ngModel)]="entBill.paymentForecast">
                        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Paid Date</mat-label>
                        <input matInput [matDatepicker]="dp3" formControlName="paidDate"
                            [(ngModel)]="entBill.paidDate">
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6" style="padding-top: .5rem;" *ngIf="entFileStorage.id > 0">
                    <button mat-raised-button (click)="downloadClick()" style="background-color:#ffcc00; color: #fff;">
                        <mat-icon>cloud_download</mat-icon> View Purchase Order File
                    </button>
                </div>
                <div class="col col-lg-4 col-md-6" style="padding-top: 15px;" *ngIf="userType == 1">
                    <button mat-button class="addFile" (click)="file.click()">
                        <mat-icon>file_upload</mat-icon>
                        Choose Files
                    </button>
                    <div style="height: 36px; padding-top: 10px; margin-left: 132px;">
                        <label id="fileName"></label>
                    </div>
                    <input hidden #file type="file" class="" multiple (change)="upload(file.files)"
                        accept="application/pdf">
                    <!-- <input #file type="file" class="" multiple (change)="upload(file.files)" accept="application/pdf"> -->
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()" *ngIf="userType == 1">Save</button>
                    <button mat-button (click)="cancelClick()">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>