import { Deserializable } from './deserializable.model';
import { Supplier } from './supplier.model';

export class Bill implements Deserializable {
  public id: number;
  public supplierId: number;
  public number: number;
  public emissionDate: Date;
  public paymentType: number;
  public totalValue: number;
  public netValue: number;
  public paymentForecast: Date;
  public paid: boolean;
  public paidDate: Date;
  public hasFile: boolean;
  public supplier: Supplier;

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    newObj.emissionDate = new Date(Number(this.emissionDate.toString().substr(0, 4)), Number(this.emissionDate.toString().substr(5, 2)) - 1, Number(this.emissionDate.toString().substr(8, 2)));
    newObj.paymentForecast = new Date(Number(this.paymentForecast.toString().substr(0, 4)), Number(this.paymentForecast.toString().substr(5, 2)) - 1, Number(this.paymentForecast.toString().substr(8, 2)));

    if (this.paid) {
      newObj.paidDate = new Date(Number(this.paidDate.toString().substr(0, 4)), Number(this.paidDate.toString().substr(5, 2)) - 1, Number(this.paidDate.toString().substr(8, 2)));
    }

    return newObj;
  }
}
