import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { FileStorage } from '../models/fileStorage.model';

@Injectable({ providedIn: 'root' })
export class FileManagementService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.fileManagementEndPoint; //'https://localhost:44325/api/filemanagement';

    add(fileStorageId: number, formData: FormData) {
        return this.http.post<FileStorage>(this.URI + '?Id=' + fileStorageId, formData);
    }
}