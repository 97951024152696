<div class="hyla-grid-header-container">
    <mat-card>
        <div class="hyla-grid-header-content" [formGroup]="searchForm">
            <mat-icon mat-list-icon>search</mat-icon>
            <input placeholder="Search..." formControlName="search" [(ngModel)]="searchText"
                (keyup.enter)="searchClick()" />
        </div>
        <mat-slide-toggle style="margin-left: 65px !important; margin: auto;" *ngIf="activeToggle"
            (toggleChange)="activeToggleClick(!modelActiveToggle)" labelPosition="after" checked>{{ toggleTitle }}</mat-slide-toggle>
        <span class="spacer"></span>
        <span *ngIf="newButton">
            <button mat-mini-fab color="primary" class="hyla-grid-header-add-button" (click)="newClick()">
                <mat-icon>
                    add
                </mat-icon>
            </button>
        </span>
    </mat-card>
</div>
<div class="hyla-grid-card-container">
    <mat-card>
        <mat-table [dataSource]="data">

            <!-- Columns -->
            <ng-container *ngFor="let col of colNames" [matColumnDef]="col.name">
                <mat-header-cell *matHeaderCellDef [ngStyle]="columnStyle(col)">
                    {{ col.name }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element" [ngStyle]="columnStyle(col)" [ngClass]="{ 'description': col.prop == 'description'  }">
                    <label>{{ col.name }}</label>
                    {{ col.pipe == "date" ? (element[col.prop] | date: 'dd/MM/yyyy') : col.pipe == "currency" ?
                    (element[col.prop] | currency:'R$') : col.pipe == "active" ? (element[col.prop] | active) : col.pipe
                    == "weekday" ? (element[col.prop] | weekday) : col.pipe == "activityType" ? (element[col.prop] |
                    activityType) : col.pipe == "hourType" ? (element[col.prop] | hourType) : col.pipe == "location" ?
                    (element[col.prop] | location:lstLocation) : col.pipe == "project" ?
                    element['project']['projectName'] : col.pipe == "customer" ? element['client']['name'] : col.pipe ==
                    "customerPo" ? element['project']['client']['name'] : col.pipe == "purchaseorder" ?
                    element['po']['number'] : col.pipe == "poproject" ? element['po']['project']['projectName'] :
                    col.pipe == "zipCode" ? (element[col.prop] | zipCode) : col.pipe == "suppliername" ?
                    element['supplier']['name'] : col.pipe == "hoursPackage" ?
                    element[col.prop] != null ? 'Yes' : 'No' : element[col.prop] }}
                </mat-cell>
            </ng-container>

            <!-- Activities -->
            <ng-container matColumnDef="Activities">
                <mat-header-cell *matHeaderCellDef style="flex: inherit; min-width: 50px;"></mat-header-cell>
                <mat-cell *matCellDef="let element" style="flex: inherit;">
                    <button mat-icon-button (click)="activitiesClick(element)">
                        <!-- <mat-icon>design_services</mat-icon> -->
                        <!-- <mat-icon>dashboard</mat-icon> -->
                        <!-- <mat-icon>dns</mat-icon> -->
                        <mat-icon>event_note</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <!-- Menu -->
            <ng-container matColumnDef="Menu">
                <mat-header-cell *matHeaderCellDef style="flex: inherit; min-width: 40px;"></mat-header-cell>
                <mat-cell *matCellDef="let element" style="flex: inherit;">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                        *ngIf="element.hourType != 6">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="downloadClick(element)"
                            [disabled]="element.hasFile == 1 ? false : true" *ngIf="downloadButton">
                            <mat-icon>cloud_download</mat-icon>
                            <span>Download</span>
                        </button>
                        <button mat-menu-item (click)="receiptClick(element)" *ngIf="receiptButton">
                            <mat-icon>receipt</mat-icon>
                            <span>Receipt</span>
                        </button>
                        <button mat-menu-item (click)="effortReportClick(element.id)"
                            [disabled]="element.hoursPackage != null ? false : true" *ngIf="effortReportButton">
                            <mat-icon>pending_actions</mat-icon>
                            <span>Effort Report</span>
                        </button>
                        <button mat-menu-item (click)="editClick(element.id)">
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="openDialog(element)" *ngIf="deleteButton">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="lstColName"></mat-header-row>
            <mat-row *matRowDef="let row; columns: lstColName;"></mat-row>
        </mat-table>
        <mat-paginator [length]="totalRecords" [pageSize]="25" [pageIndex]="page" (page)="setPage($event)" hidePageSize
            showFirstLastButtons>
        </mat-paginator>
    </mat-card>
</div>