import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as jwt_decode from 'jwt-decode';
import moment from 'moment';

// AppSettings
import { AppSettings } from 'src/app/utils/settings';

// Session Storage
import { SessionManager } from 'src/app/utils/session-manager';

// Bill
import { Bill } from 'src/app/models/bill.model';
import { BillService } from 'src/app/services/bill.service';

// File Storage
import { FileStorage } from 'src/app//models/fileStorage.model';
import { FileStorageService } from 'src/app/services/fileStorage.service';

// File Management 
import { FileManagementService } from 'src/app/services/fileManagement.service';

// Supplier
import { Supplier } from 'src/app/models/supplier.model';
import { SupplierService } from 'src/app/services/supplier.service';

@Component({
  selector: 'app-bill-detail',
  templateUrl: './bill-detail.component.html',
  styleUrls: ['./bill-detail.component.scss']
})
export class BillDetailComponent implements OnInit {
  public validationForm: FormGroup;
  responseError: string;
  file: any;

  userRole: any;
  userType: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private billData: BillService,
    private fileStorageData: FileStorageService,
    private fileManagementData: FileManagementService,
    private supplierData: SupplierService) { }

  // Bill Variables
  public entBill: Bill;

  // File Storage Variables
  public entFileStorage: FileStorage;

  // Supplier Variables
  public lstSupplier: Supplier[];

  ngOnInit(): void {
    this.entBill = new Bill();
    this.entFileStorage = new FileStorage();
    this.entFileStorage.id = 0;

    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole == 3 && this.userType == 1) {
      this.router.navigate(['/manager']);
    }

    this.validation();
    this.getData();
  }

  validation() {
    this.validationForm = new FormGroup({
      supplierId: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      emissionDate: new FormControl('', [Validators.required]),
      paymentType: new FormControl('', [Validators.required]),
      totalValue: new FormControl('', [Validators.required]),
      netValue: new FormControl('', [Validators.required]),
      paymentForecast: new FormControl('', [Validators.required]),
      paidDate: new FormControl('', null),
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.billData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entBill = response;

        if (this.entBill.hasFile) {
          this.fileStorageData.getAll(4, this.entBill.id).subscribe(response => {
            if (response.length > 0) {
              this.entFileStorage = response[0];
            }
          });
        }
      });
    } else {
      this.entBill.id = 0;
      this.entBill.paid = false;
    }

    this.supplierData.getAll(-1, '').subscribe(response => {
      this.lstSupplier = response.data.map(data => new Supplier().deserialize(data));

    });
  }

  upload(files) {
    if (files.length === 0)
      return;

    if (files[0].type != 'application/pdf') {
      this.responseError = 'Only pdf is allowed';
      return;
    } else {
      this.responseError = '';
    }

    this.file = files;
    for (let file of this.file) {
      document.getElementById('fileName').innerHTML = file.name;
    }
    this.entFileStorage.registerType = 4;
  }

  downloadClick() {
    AppSettings.viewFirstFile(this.fileStorageData, HttpEventType, 4, this.entBill);
  }

  onSubmit() {
    this.responseError = '';

    if (this.responseError != '' || this.validationForm.invalid) {
      return;
    }

    if (this.file != undefined) {
      this.entBill.hasFile = true;
    }

    this.save();
  }

  save() {
    if (this.entBill.paidDate != undefined || this.entBill.paidDate != null) {
      this.entBill.paid = true;
    } else {
      this.entBill.paid = false;
    }

    if (this.entBill.id == 0) { // Add Bill
      this.billData.add(this.entBill).subscribe(data => {
        this.entFileStorage.referenceId = data.id;
        this.fileSave();
        this.showToast();
      }, error => this.responseError = error.error.message);
    } else { // Edit Bill
      this.billData.update(this.entBill).subscribe(data => {
        this.fileSave();
        this.showToast();
      }, error => this.responseError = error.error.message);
    }
  }

  fileSave() {
    if (this.file != undefined && this.file.length > 0) {
      if (this.entFileStorage.id == 0) { // Add file
        if (this.entBill.id > 0) { // Edit Invoice
          this.entFileStorage.referenceId = this.entBill.id;
        }

        this.entFileStorage.fileMime = this.file[0].type;
        //this.entFileStorage.fileName = this.file[0].name;

        var supplierName = this.lstSupplier.find(x => x.id == this.entBill.supplierId).name;
        var emissionDate = moment(this.entBill.emissionDate).format("YYYYMMDD");
        //var emissionDate = this.entInvoice.emissionDate.getFullYear().toString()+(this.entInvoice.emissionDate.getMonth()+1).toString()+this.entInvoice.emissionDate.getDate().toString();

        this.entFileStorage.fileName = "Bill " + this.entBill.number.toString() + " - " + supplierName + " - " + emissionDate + ".pdf";

        this.fileStorageData.add(this.entFileStorage).subscribe(data => {
          this.entFileStorage.id = data.id;

          this.fileManagementSave();
        }, error => this.responseError = error.error.message);
      } else { //Edit File
        this.fileManagementSave();
      }
    } else {
      this.redirect();
    }
  }

  fileManagementSave() {
    const formData = new FormData();

    for (let file of this.file)
      formData.append(file.name, file);

    this.fileManagementData.add(this.entFileStorage.id, formData).subscribe(data => {
      this.redirect();
    }, error => this.responseError = error.error.message);
  }

  showToast() {
    // this.supplierData.getBalance(this.entInvoice.poid).subscribe(data => {
    //   if (data < (this.entInvoice.totalValue * 2)) {
    //     this.addToast(data);
    //   }
    // });
  }

  addToast(Value: number) {
    // var Message = "The PO was automatically deactivated";

    // if (Value > 0) {
    //   Message = "Only one more invoice will be possible.";
    // }

    // AppSettings.openSnackBar(this._snackBar, "The PO " + this.lstPurchaseOrder.find(x => x.id == this.entInvoice.poid).number + " currente balance is " + this.toMoneyFormat(Value) + ". " + Message, 10, AppSettings.Toast_Color_Error)
  }

  redirect() {
    SessionManager.SetSession('billToast', '1');
    this.router.navigate(['/manager/bill']);
  }

  cancelClick() {
    this.router.navigate(['/manager/bill']);
  }

}
