import { Deserializable } from './deserializable.model';

export class VacationControl implements Deserializable {
  public id: number;
  public userId: number;
  public startDate: Date;
  public endDate: Date;
  public dueDate: Date;
  public limitDate: Date;
  public vacationDays: number;
  public soldVacation: number;

  deserialize(input: VacationControl): this {
    var newObj = Object.assign(this, input);
    newObj.startDate = new Date(Number(this.startDate.toString().substr(0, 4)), Number(this.startDate.toString().substr(5, 2)) - 1, Number(this.startDate.toString().substr(8, 2)));
    newObj.endDate = new Date(Number(this.endDate.toString().substr(0, 4)), Number(this.endDate.toString().substr(5, 2)) - 1, Number(this.endDate.toString().substr(8, 2)));
    newObj.limitDate = new Date(Number(this.limitDate.toString().substr(0, 4)), Number(this.limitDate.toString().substr(5, 2)) - 1, Number(this.limitDate.toString().substr(8, 2)));
    newObj.dueDate = new Date(Number(this.dueDate.toString().substr(0, 4)), Number(this.dueDate.toString().substr(5, 2)) - 1, Number(this.dueDate.toString().substr(8, 2)));

    return newObj;
  }
}
