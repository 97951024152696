import { Deserializable } from './deserializable.model';

// Project
import { Project } from './project.model';

// User
import { User } from './user.model';

export class ProjectUsers implements Deserializable {
  public id: number;
  public userId: number;
  public user: User;
  public projectId: number;
  public project: Project;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
