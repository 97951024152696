<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body" *ngIf="lstClient">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Customer</mat-label>
                        <mat-select formControlName="clientId" [(ngModel)]="modelClient"
                            (selectionChange)="onCustomerChange()">
                            <mat-option *ngFor="let client of lstClient" [value]="client.id">{{ client.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Project</mat-label>
                        <mat-select formControlName="projectId" [(ngModel)]="entPurchaseOrder.projectId">
                            <mat-option *ngFor="let project of lstProject" [value]="project.id">
                                {{ project.projectName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-md-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>PO Number</mat-label>
                        <input matInput formControlName="number" [(ngModel)]="entPurchaseOrder.number"
                            type="number">
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-8">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Emission Date</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="emissionDate"
                            [(ngModel)]="entPurchaseOrder.emissionDate">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-4">
                    <mat-slide-toggle formControlName="active" [(ngModel)]="entPurchaseOrder.active">Active
                    </mat-slide-toggle>
                </div>
                <div class="col-12">
                    <mat-card class="card-header" style="background-color: rgb(222, 223, 225);">
                        <h3>Items</h3>
                    </mat-card>
                    <mat-card class="card-body" style="box-shadow: none; border: solid 1px rgb(222, 223, 225);">
                        <div class="row">
                            <div class="col col-md-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="item1Code" [(ngModel)]="entPurchaseOrder.item1Code"
                                        type="number">
                                </mat-form-field>
                            </div>
                            <div class="col col-md-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <input matInput formControlName="item1Description"
                                        [(ngModel)]="entPurchaseOrder.item1Description">
                                </mat-form-field>
                            </div>
                            <div class="col col-md-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Value</mat-label>
                                    <input matInput formControlName="item1Value"
                                        [(ngModel)]="entPurchaseOrder.item1Value" type='number' (change)="onValueChange()">
                                </mat-form-field>
                            </div>
                            <div class="col col-md-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="item2Code" [(ngModel)]="entPurchaseOrder.item2Code"
                                        type="number">
                                </mat-form-field>
                            </div>
                            <div class="col col-md-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <input matInput formControlName="item2Description"
                                        [(ngModel)]="entPurchaseOrder.item2Description">
                                </mat-form-field>
                            </div>
                            <div class="col col-md-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Value</mat-label>
                                    <input matInput formControlName="item2Value"
                                        [(ngModel)]="entPurchaseOrder.item2Value" type="number" (change)="onValueChange()">
                                </mat-form-field>
                            </div>
                            <div class="col col-md-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="item3Code" [(ngModel)]="entPurchaseOrder.item3Code"
                                        type="number">
                                </mat-form-field>
                            </div>
                            <div class="col col-md-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <input matInput formControlName="item3Description"
                                        [(ngModel)]="entPurchaseOrder.item3Description">
                                </mat-form-field>
                            </div>
                            <div class="col col-md-4">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Value</mat-label>
                                    <input matInput formControlName="item3Value"
                                        [(ngModel)]="entPurchaseOrder.item3Value" type="number" (change)="onValueChange()">
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Total Value</mat-label>
                                    <input matInput formControlName="totalValue"
                                        [(ngModel)]="entPurchaseOrder.totalValue" type="number">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-12" style="padding-bottom: 1.5rem;" *ngIf="entFileStorage.id > 0">
                    <button mat-raised-button (click)="downloadClick()"
                        style="background-color:#ffcc00; color: #fff;">
                        <mat-icon>cloud_download</mat-icon> View Purchase Order File
                    </button>
                </div>
                <div class="col-12" *ngIf="userType == 1">
                    <button mat-button class="addFile" (click)="file.click()">
                        <mat-icon>file_upload</mat-icon>
                        Choose Files
                    </button>
                    <div style="height: 36px; padding-top: 10px; margin-left: 132px;">
                        <label id="fileName"></label>
                    </div>
                    <input hidden #file type="file" class="" multiple (change)="upload(file.files)"
                        accept="application/pdf">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()" *ngIf="userType == 1">Save</button>
                    <button mat-button (click)="cancelClick()">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>