import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hourType' })
export class HourTypePipe implements PipeTransform {
    transform(type: number): string {
        var hourType;
        switch (type) {
            case 1: {
                hourType = 'Normal';
                break;
            }
            case 2: {
                hourType = 'Extra Hour';
                break;
            }
            case 3: {
                hourType = 'Warning Hour';
                break;
            }
            case 4: {
                hourType = 'Basket Hour';
                break;
            }
            case 5: {
                hourType = 'License';
                break;
            }
            case 6: {
                hourType = 'Vacation';
                break;
            }
        }

        return hourType;
    }
}