import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Client
import { Client } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';

// Project
import { Project } from 'src/app/models/project.model';
import { ProjectService } from 'src/app/services/project.service';

// Project Users
import { ProjectUsersService } from 'src/app/services/projectUsers.service';

// User
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  public validationForm: FormGroup;
  displayedColumns: string[] = ['select', 'name'];
  dataSource: User[];
  employeeSelection = new SelectionModel<User>(true, []);
  employeeProjectSelection = new SelectionModel<User>(true, []);
  userRole: any;
  userType: any;
  responseError: string;

  tabIndex: number = 0;

  @ViewChild('employeeTable') employeeTable: MatTable<any>;
  @ViewChild('employeeProjectTable') employeeProjectTable: MatTable<any>;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private clientData: ClientService,
    private projectData: ProjectService,
    private projectUsersData: ProjectUsersService,
    private userData: UserService) { }

  // Client Variables
  public lstClient: Client[];

  // Project Variables
  public entProject: Project;

  // User Variables
  public lstUser: User[];
  public lstEmployeeProject: User[] = [];

  public lstAdd: number[] = [];
  public lstDelete: number[] = [];

  ngOnInit(): void {
    this.entProject = new Project();

    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole == 3 && this.userType == 1) {
      this.router.navigate(['/manager']);
    }

    this.getData();
    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      projectName: new FormControl('', [Validators.required, Validators.minLength(2)]),
      clientId: new FormControl('', [Validators.required]),
      estimatedStartDate: new FormControl('', [Validators.required]),
      estimatedEndDate: new FormControl('', [Validators.required]),
      startedDate: new FormControl('', [Validators.required]),
      hoursPackage: new FormControl('', null),
      active: new FormControl('', null),
      employeeProjectId: new FormControl('', null)
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) { // Edit
      this.projectData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entProject = response;
      });
    } else { // Add
      this.entProject.id = 0;
      this.entProject.active = true;
    }

    this.clientData.getAll(-1, '').subscribe(response => {
      this.lstClient = response.data.map(data => new Client().deserialize(data));

      if (this.userType == 1) {
        this.userData.getAll(-1, '', true).subscribe(response => {
          this.lstUser = response.data.map(data => new User().deserialize(data)).filter(x => x.type == 1 && (x.dismissDate == undefined || x.dismissDate.getMonth() >= new Date().getMonth() && x.dismissDate.getFullYear() >= new Date().getFullYear()));

          this.projectUsersData.getAll(this.entProject.id, 0).subscribe(response => {
            if (response.length > 0) {
              response.forEach(entProjectUsers => {
                if (this.lstUser.find(x => x.id == entProjectUsers.userId)) {
                  this.lstEmployeeProject.push(this.lstUser.find(x => x.id == (entProjectUsers.userId)));
                  this.lstUser.splice(this.lstUser.indexOf(this.lstUser.find(x => x.id == (entProjectUsers.userId))), 1);
                }
              });
            } else {
              this.lstEmployeeProject = [];
            }

            this.tablesRefresh();
          });

        });
      }
    });
  }

  addEmployeeProject() {
    this.employeeSelection.selected.forEach(User => {
      this.lstEmployeeProject.push(User);
      this.lstUser.splice(this.lstUser.findIndex(x => x.id == User.id), 1);
      this.lstAdd.push(User.id);

    });

    this.employeeSelection.clear();

    this.lstEmployeeProjectOrder();
    this.tablesRefresh();
  }

  lstEmployeeProjectOrder() {
    this.lstEmployeeProject.sort(function (a, b) {
      return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
    });
  }

  addEmployee() {
    this.employeeProjectSelection.selected.forEach(User => {
      this.lstUser.push(User);
      this.lstEmployeeProject.splice(this.lstEmployeeProject.findIndex(x => x.id == User.id), 1);
      if (this.lstAdd.find(x => x == User.id) == undefined) {
        this.lstDelete.push(User.id);
      } else {
        this.lstAdd.splice(this.lstAdd.indexOf(this.lstAdd.find(x => x == User.id)), 1);
      }

    });

    this.employeeProjectSelection.clear();

    this.lstEmployeeOrder();
    this.tablesRefresh();
  }

  lstEmployeeOrder() {
    this.lstUser.sort(function (a, b) {
      return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
    });
  }

  tablesRefresh() {
    this.employeeTable.renderRows();
    this.employeeProjectTable.renderRows();
  }


  isAllSelected(table: number) {
    var selection = table == 0 ? this.employeeSelection : this.employeeProjectSelection;
    var list = table == 0 ? this.lstUser : this.lstEmployeeProject;
    const numSelected = selection.selected.length;
    const numRows = list.length;
    return numSelected === numRows;
  }

  masterToggle(table: number) {
    var selection = table == 0 ? this.employeeSelection : this.employeeProjectSelection;
    var list = table == 0 ? this.lstUser : this.lstEmployeeProject;
    this.isAllSelected(table) ?
      selection.clear() :
      list.forEach(row => selection.select(row));
  }

  checkboxLabel(row?: User, table?: number): string {
    var selection = table == 0 ? this.employeeSelection : this.employeeProjectSelection;
    if (!row) {
      return `${this.isAllSelected(table) ? 'select' : 'deselect'} all`;
    }
    return `${selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name}`;
  }

  onSubmit() {
    if (this.validationForm.invalid) {
      return;
    }

    if (this.lstEmployeeProject.length == 0) {
      if (this.tabIndex == 0) {
        const tabCount = 2;
        this.tabIndex = (this.tabIndex + 1) % tabCount;
      }

      this.responseError = "No resources selected for this project";

      return;
    }

    this.save();
  }

  save() {
    if (this.entProject.id == 0) { // Add
      this.projectData.add(this.entProject).subscribe(data => {

        if (this.lstAdd.length != 0) {
          this.projectUsersData.add(data.id, this.lstAdd).subscribe(data => {
            this.redirect();
          }, error => this.responseError == error.error.message);
        }

        SessionManager.SetSession('projectToast', '1');
        this.router.navigate(['/manager/project']);
      }, error => this.responseError = error.error.message);
    } else { // Edit
      this.projectData.update(this.entProject).subscribe(data => {

        if (this.lstAdd.length != 0) {
          this.projectUsersData.add(this.entProject.id, this.lstAdd).subscribe(data => {
            this.redirect();
          }, error => this.responseError == error.error.message);
        }

        if (this.lstDelete.length != 0) {
          this.projectUsersData.delete(this.entProject.id, this.lstDelete).subscribe(data => {
            this.redirect();
          });
        }

        if (this.lstAdd.length == 0 && this.lstDelete.length == 0) {
          this.redirect();
        }

      }, error => this.responseError = error.error.message);
    }
  }

  redirect() {
    SessionManager.SetSession('projectToast', '1');
    this.router.navigate(['/manager/project']);
  }

  cancelClick() {
    this.router.navigate(['/manager/project']);
  }

}
