import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { Project } from '../models/project.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class ProjectService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.projectEndPoint; //'https://localhost:44325/api/project';


    getAll(page: number, search: string, active?: boolean, userId?: number, customerId?: number) {
        if (search == undefined) { search = ""; }
        if (active == undefined || active == null) { active = false; }
        if (userId == undefined || userId == null) { userId = 0; }
        if (customerId == undefined || customerId == null) { customerId = 0; }

        return this.http.get<ResponseData>(this.URI + "?page=" + page + "&pageSize=" + AppSettings.pageSize + "&search=" + search + '&active=' + active + '&userId=' + userId + '&customerId=' + customerId).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<Project>(this.URI + '/' + Id).pipe(
            map(data => new Project().deserialize(data))
        );
    }

    add(entProject: Project) {
        return this.http.post<Project>(this.URI, entProject);
    }

    update(entProject: Project) {
        return this.http.put<Project>(this.URI + '/' + entProject.id, entProject);
    }

    delete(Id: number) {
        return this.http.delete<Project>(this.URI + '/' + Id);
    }
}
