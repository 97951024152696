import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEventType } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from 'src/app/utils/session-manager';

// Settings 
import { AppSettings } from 'src/app/utils/settings';

//Dialog
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HylaDialogComponent } from 'src/app/components/hyla-dialog/hyla-dialog.component';

// File Storage
import { FileStorageService } from 'src/app/services/fileStorage.service';

// Invoice
import { Invoice } from 'src/app/models/invoice.model';
import { InvoiceService } from 'src/app/services/invoice.service';

// Purchase Order
import { PurchaseOrder } from 'src/app/models/purchaseOrder.model';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder.service';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent implements OnInit {
  columns = [
    { prop: 'number', name: 'Number', maxWidth: 10 },
    { prop: 'project.client.name', name: 'Customer', pipe: 'customerPo', minWidth: 15 },
    { prop: 'project.projectName', name: 'Project', pipe: 'project', minWidth: 30 },
    { prop: 'emissionDate', name: 'Emission Date', pipe: 'date' },
    { prop: 'totalValue', name: 'Total Value', pipe: 'currency' },
    { prop: 'active', name: 'Active', pipe: 'active', maxWidth: 12 }
  ];

  dialogDelete = {
    col: 'number',
    data: {
      title: 'Confirm deletion', message: 'Do you want to delete the purchase order', saveButton: 'Yes', cancelButton: 'No'
    }
  };

  modelActive: boolean = true;

  userRole: any;
  userType: any;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private fileStorageData: FileStorageService,
    private invoiceData: InvoiceService,
    private purchaseOrderData: PurchaseOrderService) { }

  // Invoice Variables
  public lstInvoice: Invoice[];

  // Purchase Order Variables
  public lstPurchaseOrder: PurchaseOrder[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;
    this.userType = jwt_decode(localStorage.getItem('currentUser')).Type;

    if (this.userRole == 3 && this.userType == 1) {
      this.router.navigate(['/manager']);
    }

    if (SessionManager.SessionExist('purchaseOrderPage')) {
      this.gridPaging[0] = SessionManager.GetSession('purchaseOrderPage');
    }

    if (SessionManager.SessionExist('purchaseOrderSearch')) {
      this.gridSearch = SessionManager.GetSession('purchaseOrderSearch');
    }

    if (SessionManager.SessionExist('purchaseOrderToast')) {
      SessionManager.DeleteSession('purchaseOrderToast');
      AppSettings.openSnackBar(this._snackBar, 'Purchase Order successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }

    this.purchaseOrderData.getAll(pageInfo.pageIndex, pageInfo.search, this.modelActive).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstPurchaseOrder = response.data.map(data => new PurchaseOrder().deserialize(data));
    });
  }

  addClick() {
    this.router.navigate(['/manager/purchaseorder/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('purchaseOrderPage', this.gridPaging[0].toString());
    SessionManager.SetSession('purchaseOrderSearch', this.gridSearch);
    this.router.navigate(['/manager/purchaseorder/edit/' + value])
  }

  onDelete(value: any) {
    this.purchaseOrderData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

  downloadClick(entPurchaseOrder: PurchaseOrder) {
    AppSettings.viewFirstFile(this.fileStorageData, HttpEventType, 1, entPurchaseOrder);
  }

  receiptClick(entPurchaseOrder: PurchaseOrder) {
    this.invoiceData.getAll(-1, '', entPurchaseOrder.id).subscribe(response => {
      this.lstInvoice = response.data.map(data => new Invoice().deserialize(data));
      this.openDialog(entPurchaseOrder);
    });
  }

  openDialog(value: PurchaseOrder) {

    const dialogRef = this.dialog.open(HylaDialogComponent, {
      data: {
        title: 'PO Number: ' + value.number,
        receipt: true,
        entReceipt: {
          POTotal: value.totalValue,
          Inv: this.lstInvoice.sort(function (a, b) {
            return (a.emissionDate > b.emissionDate) ? 1 : ((b.emissionDate > a.emissionDate) ? -1 : 0);
          }),
          InvSumOfValue: this.invSum(value.totalValue),
          emissionDate: value.emissionDate,
        },
        cancelButton: 'Close'
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // this.dataTimesheetBalance(value);
        // this.onSave();

      }
    });
  }

  invSum(totalValue: number): any {
    var sum = 0;
    this.lstInvoice.forEach(invoice => {
      sum += invoice.totalValue;
    });
    return sum;
  }

  onActiveToggleChange(value: boolean) {
    this.modelActive = value;
    this.getData();
  }

}
