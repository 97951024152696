<div class="container-fluid">
    <div class="row">
        <div class="col-md-2 col-sm-6">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Employee</mat-label>
                <mat-select [(value)]="modelUser" [disabled]="userControl" (selectionChange)="onUserChange()">
                    <mat-option *ngFor="let user of lstUser" [value]="user.id">{{ user.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-2 col-sm-6">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Month</mat-label>
                <mat-select [(value)]="modelMonth" (selectionChange)="onUserChange()">
                    <mat-option [value]="1">January</mat-option>
                    <mat-option [value]="2">February</mat-option>
                    <mat-option [value]="3">March</mat-option>
                    <mat-option [value]="4">April</mat-option>
                    <mat-option [value]="5">May</mat-option>
                    <mat-option [value]="6">June</mat-option>
                    <mat-option [value]="7">July</mat-option>
                    <mat-option [value]="8">August</mat-option>
                    <mat-option [value]="9">September</mat-option>
                    <mat-option [value]="10">October</mat-option>
                    <mat-option [value]="11">November</mat-option>
                    <mat-option [value]="12">December</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-2 col-sm-6">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Year</mat-label>
                <mat-select [(value)]="modelYear" (selectionChange)="onUserChange()">
                    <mat-option *ngFor="let year of year" [value]="year">{{ year }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-6">
            <button mat-flat-button color="primary" class="button" (click)="getData()">Search</button>

        </div>
    </div>
    <div class="row" *ngIf="projectControl">

        <div class="col col-md-2">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Project</mat-label>
                <mat-select [(value)]="modelProject" (selectionChange)="onProjectChange()" multiple>
                    <!-- <mat-option [value]="0">All</mat-option> -->
                    <mat-option *ngFor="let project of Project" [value]="projects(project, 2)">{{ project }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col col-md-2">
          <button mat-icon-button color="primary" aria-label="save_alt" class="button" (click)="getDataExcel()">
            <mat-icon>save_alt</mat-icon>
            Excel
           </button>
        </div>
    </div>
</div>
<div id="print" class="display-none">
    <div class="row" style="padding-top: 20px; padding-bottom: 80px;">
        <div class="col-sm-3">
            <img src="../../../assets/images/atomosHylaLogo.svg" width="300px">
        </div>
        <div class="col-sm-9">
            <h4 style="margin: 0; font-size: 18px;">Monthly Balance</h4>
            <div class="row" style="color: rgb(126, 134, 140);">
                <div class="col-md-2 col-sm-3" style="display: grid; font-weight: 500; padding: 0px;">
                    <label>Employee: </label>
                    <label>Month/Year: </label>
                    <label>Project: </label>
                </div>
                <div class="col-md-10 col-sm-6" style="display: grid;  padding: 0px;">
                    <label *ngIf="lstTimesheet">{{ entUser.name }}</label>
                    <label>{{ modelMonth }}/{{ modelYear }}</label>
                    <div>
                        <label *ngFor="let projectId of modelProject">{{ projectName(projectId) }}{{ modelProject.length
                            == modelProject.indexOf(projectId) + 1 ? '' : ',' }}&nbsp;&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="information">
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <mat-card class="card-header">
                    <h5>Hours Type</h5>
                </mat-card>
                <mat-card class="card-body">
                    <table>
                        <tbody>
                            <tr>
                                <th>Normal Hours</th>
                                <td>{{ NormalHours == undefined ? 0 : NormalHours }}</td>
                            </tr>
                            <tr>
                                <th>Extra Hours</th>
                                <td>{{ ExtraHours == undefined ? 0 : ExtraHours }}</td>
                            </tr>
                            <tr>
                                <th>Warning Hours</th>
                                <td>{{ WarningHours == undefined ? 0 : WarningHours }}</td>
                            </tr>
                            <tr>
                                <th>Basket Hours</th>
                                <td>{{ BasketHours == undefined ? 0 : BasketHours }}</td>
                            </tr>
                            <tr>
                                <th>Licence Hours</th>
                                <td>{{ LicenseHours == undefined ? 0 : LicenseHours }}</td>
                            </tr>
                            <tr>
                                <th>Vacation Hours</th>
                                <td>{{ VacationHours == undefined ? 0 : VacationHours }}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card>
            </div>
            <div class="col-md-4 col-sm-6">
                <mat-card class="card-header">
                    <h5>By Hours Type</h5>
                </mat-card>
                <mat-card class="card-body" style="height: 171px; padding: 6px 20px;">
                    <div style="height: 150px; width: 314px; margin: auto;">
                        <canvas id="hoursByTypeChart"></canvas>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <mat-card class="card-header">
                    <h5>Projects</h5>
                </mat-card>
                <mat-card class="card-body" style="height: 162px;">
                    <table>
                        <tbody>
                            <tr *ngFor="let project of Projects">
                                <th>{{ project[0] }}</th>
                                <td>{{ project[1] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card>
            </div>
            <div class="col-md-4 col-sm-6">
                <mat-card class="card-header">
                    <h5>By Projects</h5>
                </mat-card>
                <mat-card class="card-body" style="min-height: 162px; padding: 6px 20px;">
                    <div style="height:150px; width:314px; margin: auto;">
                        <canvas id="hoursByProjectChart"></canvas>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <mat-card class="card-header">
                    <h5>Activities</h5>
                </mat-card>
                <mat-card class="card-body">
                    <table>
                        <tbody>
                            <tr>
                                <th>Project Management</th>
                                <td>{{ ProjectManagement }}</td>
                            </tr>
                            <tr>
                                <th>Pre-Sales</th>
                                <td>{{ PreSales }}</td>
                            </tr>
                            <tr>
                                <th>Design</th>
                                <td>{{ Design }}</td>
                            </tr>
                            <tr>
                                <th>Development</th>
                                <td>{{ Development }}</td>
                            </tr>
                            <tr>
                                <th>Internal Testing</th>
                                <td>{{ InternalTesting }}</td>
                            </tr>
                            <tr>
                                <th>SAT</th>
                                <td>{{ SAT }}</td>
                            </tr>
                            <tr>
                                <th>Assited Operation</th>
                                <td>{{ AssitedOperation }}</td>
                            </tr>
                            <tr>
                                <th>Maintenance Support</th>
                                <td>{{ MaintenanceSupport }}</td>
                            </tr>
                            <tr>
                                <th>Meeting</th>
                                <td>{{ Meeting }}</td>
                            </tr>
                            <tr>
                                <th>Other</th>
                                <td>{{ Other }}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card>
            </div>
            <div class="col-md-4 col-sm-6">
                <mat-card class="card-header">
                    <h5>By Activities</h5>
                </mat-card>
                <mat-card class="card-body" style="height: 290px; padding: 46px 20px;">
                    <div style="height:150px; width:314px; margin: auto;">
                        <canvas id="HoursByActivitiesChart"></canvas>
                    </div>
                </mat-card>
            </div>
        </div>
        <div id="registered-hours" class="row" style="display: grid;">
            <h4 style="margin-left: 15px; font-size: 18px;">Registered Hours</h4>
            <div class="col col-md-8">
                <div class="row">
                    <mat-card class="card-body timesheet-card" *ngFor="let timesheet of lstTimesheet"
                        [ngClass]="{ 'Weekend': (timesheet.registeredDate.getDay() == 6) || (timesheet.registeredDate.getDay() == 0) }"
                        style="border-radius: 4px !important;">
                        <table>
                            <tbody>
                                <tr>
                                    <th style="width: 50px;">Date:</th>
                                    <td style="width: 175px;">{{ timesheet.registeredDate | date: 'dd/MM/yyyy' }}
                                        <small style="margin-left: 5px;">({{ timesheet.registeredDate | weekday
                                            }})</small>
                                    </td>
                                    <th style="width: 60px;">Hours:</th>
                                    <td style="width: 105px;">{{ timesheet.registeredHours }}</td>
                                    <th style="width: 90px;">Hours Type:</th>
                                    <td>{{ timesheet.hourType | hourType }}</td>
                                    <td>
                                        <div style="height: 10px; width: 10px; border-radius: 10px; float: right;"
                                            [ngClass]="{ 'bg-primary-green': timesheet.hourType == 1, 'bg-orange': timesheet.hourType == 2, 'bg-red': timesheet.hourType == 3, 'bg-blue': timesheet.hourType == 4, 'bg-white': timesheet.hourType == 5, 'bg-lightblue': timesheet.hourType == 6 }">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                                <tr>
                                    <th style="width: 65px;">Project:</th>
                                    <td style="width: 325px;">{{ projectName(timesheet.projectId) }}</td>
                                    <th style="width: 105px;">Activity Type:</th>
                                    <td>{{ timesheet.activityType | activityType }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                                <tr>
                                    <th style="width: 82px;">Comment:</th>
                                    <td>{{ timesheet.comment }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>
