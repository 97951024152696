import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { FileStorage } from '../models/fileStorage.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileStorageService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.fileStorageEndPoint; //'https://localhost:44325/api/filestorage';

    /**
     * 
     * @param registerType 1: Purchase Order, 2: Invoice, 3: Issue, 4: Bill
     */
    getAll(registerType: number, referenceId: number) {
        return this.http.get<FileStorage[]>(this.URI + '?' + 'registerType=' + registerType + '&' + 'referenceId=' + referenceId).pipe(
            //map(data => new FileStorage().deserialize(data))
            map(data => data.map(data => new FileStorage().deserialize(data)))
        );
    }

    getById(Id: number) {
        return this.http.get<FileStorage>(this.URI + '/' + Id).pipe(
            map(data => new FileStorage().deserialize(data))
        );
    }

    viewFileById(id: number): Observable<HttpEvent<Blob>> {
        return this.http.request(new HttpRequest(
            'GET',
            this.URI + '/ViewFileById/' + id,
            null,
            {
                reportProgress: true,
                responseType: 'blob'
            }
        ));
    }

    viewFirstFile(registerType: number, referenceId: number): Observable<HttpEvent<Blob>> {
        return this.http.request(new HttpRequest(
            'GET',
            this.URI + '/ViewFirstFile/' + registerType + '/' + referenceId,
            null,
            {
                reportProgress: true,
                responseType: 'blob'
            }
        ));
    }

    add(entFileStorange: FileStorage) {
        return this.http.post<FileStorage>(this.URI, entFileStorange);
    }

    update(entFileStorange: FileStorage) {
        return this.http.put<FileStorage>(this.URI + '/' + entFileStorange.id, entFileStorange);
    }

    delete(id: number) {
        return this.http.delete<FileStorage>(this.URI + '/' + id);
    }

    deleteByReferenceId(referenceId: number) {
        return this.http.delete<FileStorage>(this.URI + '/DeleteByReferenceId/' + referenceId);
    }
}