<div class="login-bg" style="background-image: url('../../../assets/images/login_background.jpg');">
    <div class="mr-auto ml-auto full-height d-flex align-items-center">
        <div class="vertical-align full-height">
            <div class="table-cell">
                <mat-card style="width: 370px; margin: auto; padding: 45px 50px;">
                    <img alt="Atomos Hyla Logo" src="../../../assets/images/atomosHylaLogo.svg">
                    <form [formGroup]="validation" style="padding-top: 30px; text-align: center;">
                        <label style=" color: rgb(126, 134, 140);">Please enter your information to login</label>
                        <div class="row">
                            <div class="col">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>E-mail</mat-label>
                                    <input matInput placeholder="name@atomoshyla.com" formControlName="email" [(ngModel)]="entUser.email">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field class="example-full-width box-shadow-none" appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input matInput type="password"  formControlName="password" [(ngModel)]="entUser.password">
                                  </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="error" *ngIf="error">
                                    <mat-label>{{ error }}</mat-label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button mat-flat-button color="primary" style="float: right" (click)="Login()">Login</button>
                            </div>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</div>