import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-template',
  template: '<router-outlet></router-outlet>'
})
export class NoTemplateComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.router.url == '/') {
      window.location.href = 'https://www.atomoshyla.com/';
    }
  }

}
