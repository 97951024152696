import { Location } from '../models/location.model';
import { Deserializable } from './deserializable.model';

// export class User.Model {
// }

export class User implements Deserializable {
  public id: number;
  public email: string;
  public password: string;
  public type: number;
  public role: number;
  public name: string;
  public birthDate: Date;
  public admissionDate: Date;
  public workingHours: number;
  public locationId: number;
  public dismissDate: Date;
  public location: Location;
  public timesheet: any[];

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    newObj.birthDate = new Date(Number(this.birthDate.toString().substr(0, 4)), Number(this.birthDate.toString().substr(5, 2)) - 1, Number(this.birthDate.toString().substr(8, 2)));
    newObj.admissionDate = new Date(Number(this.admissionDate.toString().substr(0, 4)), Number(this.admissionDate.toString().substr(5, 2)) - 1, Number(this.admissionDate.toString().substr(8, 2)));

    if (this.dismissDate != null) {
      newObj.dismissDate = new Date(Number(this.dismissDate.toString().substr(0, 4)), Number(this.dismissDate.toString().substr(5, 2)) - 1, Number(this.dismissDate.toString().substr(8, 2)));
    }

    return newObj;
  }
}
