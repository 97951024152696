import { Component, OnInit } from '@angular/core';
import { HylaDialogComponent } from 'src/app/components/hyla-dialog/hyla-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as jwt_decode from 'jwt-decode';

// Timesheet
import { Timesheet } from '../../models/timesheet.model';
import { TimesheetService } from 'src/app/services/timesheet.service';

// Timesheet Balance 
import { TimesheetBalance } from '../../models/timesheetBalance.model';
import { TimesheetBalanceService } from 'src/app/services/timesheetBalance.service';

// User
import { User } from '../../models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-annual-report',
  templateUrl: './annual-report.component.html',
  styleUrls: ['./annual-report.component.scss']
})
export class AnnualReportComponent implements OnInit {
  modelUser: number;
  userId: any;
  userRole: number;
  modelYear: number = new Date().getFullYear();
  year: number[] = [(new Date().getFullYear() - 1), new Date().getFullYear(), (new Date().getFullYear() + 1)];
  lstMonths: number[];
  businessDay: number;

  constructor(public dialog: MatDialog,
    private timesheetData: TimesheetService,
    private timesheetBalanceData: TimesheetBalanceService,
    private userData: UserService) { }

  // Timesheet Balance Variables
  public lstTimesheetBalance: TimesheetBalance[];

  // User Variables
  public lstUser: User[];
  public entUser: User;

  ngOnInit(): void {
    this.userId = jwt_decode(localStorage.getItem('currentUser')).userId;
    this.userRole = jwt_decode(localStorage.getItem('currentUser')).role;

    this.getUser();
  }

  getUser() {
    this.userData.getAll(-1, '').subscribe(response => {
      this.lstUser = response.data.map(data => new User().deserialize(data)).filter(x => x.type == 1 && (x.dismissDate == undefined || x.dismissDate.getFullYear() >= this.year[0]));

      this.modelUser = parseInt(this.userId);
    });
  }

  getData() {
    this.timesheetBalanceData.getAll(this.modelUser, this.modelYear).subscribe(response => {
      this.lstTimesheetBalance = response;

      this.lstMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    });
  }

  lastDayMonth(date: Date) {
    var data = date;
    var Month = data.getMonth();

    this.businessDay = 0;

    while (data.getMonth() == Month) {

      if (data.getDay() >= 1 && data.getDay() <= 5) {
        this.businessDay++;
      }

      data.setDate(data.getDate() + 1);
    }

    data.setDate(data.getDate() - 1);

    return data;
  }

  openDialog(month: number) {
    var data = { title: '', message: '', saveButton: '', cancelButton: '', table: false, dataSource: [] };
    this.entUser = this.lstUser.find(x => x.id == this.userId);

    this.timesheetData.getAll(-1, '', new Date(this.modelYear, month - 1, 1), this.lastDayMonth(new Date(this.modelYear, month - 1, 1)), this.modelUser).subscribe(response => {
      data.title = 'Timesheet view';
      data.table = true;
      data.dataSource = response.data.map(data => new Timesheet().deserialize(data));
      data.cancelButton = 'Close';

      const dialogRef = this.dialog.open(HylaDialogComponent, {
        data: {
          title: data.title,
          message: data.message,
          table: data.table,
          dataSource: data.dataSource,
          saveButton: data.saveButton,
          cancelButton: data.cancelButton
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      });
    });

  }

  gridInformation(type: number, month: number) {
    var Information = 0;
    var timesheetBalance = this.lstTimesheetBalance.find(x => x.month == month);

    if (timesheetBalance != undefined) {
      switch (type) {
        case 1: {
          Information = timesheetBalance.hoursNormal;
          break;
        }
        case 2: {
          Information = timesheetBalance.hoursExtra;
          break;
        }
        case 3: {
          Information = timesheetBalance.hoursWarning;
          break;
        }
        case 4: {
          Information = timesheetBalance.hoursBasket;
          break;
        }
        case 5: {
          Information = timesheetBalance.hoursLicense;
          break;
        }
        case 6: {
          Information = timesheetBalance.hoursVacation;
          break;
        }
        case 7: {
          Information = (timesheetBalance.hoursNormal + timesheetBalance.hoursBasket + timesheetBalance.hoursLicense + timesheetBalance.hoursVacation);
          break;
        }
      }
    }

    return Information;
  }

  extraHourInformation(type: number, month: number) {
    var Information = 0;
    var extraHours = this.lstTimesheetBalance.find(x => x.month == month);

    if (extraHours != undefined) {
      if (type == 1) {
        Information = extraHours.hoursExtraPrevious;
      } else {
        Information = extraHours.hoursExtraTotal;
      }
    }

    return Information;
  }

}
