import { Deserializable } from './deserializable.model';

// export class Timesheet.Model {
// }

export class ResponseData implements Deserializable {
  public page: number;
  public totalRecords: number;
  public data: any[];

  deserialize(input: any): this {
    var newObj = Object.assign(this, input);
    return newObj;
  }
}
