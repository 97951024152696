import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserTask } from 'src/app/models/userTask.model';
import { UserTaskService } from 'src/app/services/userTask.service';
import { SessionManager } from 'src/app/utils/session-manager';
import { AppSettings } from 'src/app/utils/settings';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-my-task-list',
  templateUrl: './my-task-list.component.html',
  styleUrls: ['./my-task-list.component.scss']
})
export class MyTaskListComponent implements OnInit {
  columns = [
    { prop: 'title', name: 'Title', maxWidth: 35 },
    { prop: 'description', name: 'Description', maxWidth: 38 },
    { prop: 'dueDate', name: 'Due Date', pipe: 'date', maxWidth: 15 },
    { prop: 'done', name: 'Done', pipe: 'active', maxWidth: 12 }
  ];

  dialogDelete = {
    col: 'title',
    data: {
      title: 'Confirm deletion', message: 'Do you want to delete the task', saveButton: 'Yes', cancelButton: 'No'
    }
  };
  
  modelDone: number = 1;

  constructor(private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private userTaskData: UserTaskService) { }

  // Task Variables
  public lstUserTask: UserTask[];

  public gridPaging = [0, 0];
  public gridSearch: string = '';

  ngOnInit(): void {
    if (SessionManager.SessionExist('userTaskPage')) {
      this.gridPaging[0] = SessionManager.GetSession('userTaskPage');
    }

    if (SessionManager.SessionExist('userTaskSearch')) {
      this.gridSearch = SessionManager.GetSession('userTaskSearch');
    }

    if (SessionManager.SessionExist('userTaskToast')) {
      SessionManager.DeleteSession('userTaskToast');
      AppSettings.openSnackBar(this._snackBar, 'Task successfully saved', 5, AppSettings.Toast_Color_Success);
    }
  }

  getData(pageInfo?: any) {
    var userId = jwt_decode(localStorage.getItem('currentUser')).userId;

    if (pageInfo == undefined) {
      pageInfo = { pageIndex: this.gridPaging[0], search: this.gridSearch };
    }
    
    this.userTaskData.getAllByUserId(userId, pageInfo.pageIndex, pageInfo.search, this.modelDone).subscribe(response => {
      this.gridPaging[0] = response.page;
      this.gridPaging[1] = response.totalRecords;
      this.gridSearch = pageInfo.search;
      this.lstUserTask = response.data.map(data => new UserTask().deserialize(data));
    });
  }

  onDoneToggleChange(value: boolean) {
      this.modelDone = value ? 1 : 0;
      this.getData();
  }

  addClick() {
    this.router.navigate(['/manager/mytask/new']);
  }

  onEdit(value: any) {
    SessionManager.SetSession('userTaskPage', this.gridPaging[0].toString());
    SessionManager.SetSession('userTaskSearch', this.gridSearch);
    this.router.navigate(['/manager/mytask/edit/' + value])
  }

  onDelete(value: any) {
    this.userTaskData.delete(value).subscribe(data => { this.gridSearch = ''; this.getData(); }, error => AppSettings.openSnackBar(this._snackBar, error.error.message, 10, AppSettings.Toast_Color_Error));
  }

}
