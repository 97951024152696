import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { History } from '../models/history.model';

@Injectable({ providedIn: 'root' })
export class HistoryService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.historyEndPoint; //'https://localhost:44325/api/history';

    getByIssueId(issueId: number) {
        return this.http.get<History[]>(this.URI + '?issueId=' + issueId).pipe(
            map(data => data.map(data => new History().deserialize(data)))
        );
    }

    // getById(Id: number) {
    //     return this.http.get<History>(this.URI + '/' + Id).pipe(
    //         map(data => new History().deserialize(data))
    //     );
    // }

    add(entHistory: History) {
        return this.http.post<History>(this.URI, entHistory);
    }

    // update(entHistory: History) {
    //     return this.http.put<History>(this.URI + '/' + entHistory.id, entHistory);
    // }

    delete(Id: number) {
        return this.http.delete<History>(this.URI + '/' + Id);
    }
}