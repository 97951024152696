import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserTask } from 'src/app/models/userTask.model';
import { UserTaskService } from 'src/app/services/userTask.service';
import { SessionManager } from 'src/app/utils/session-manager';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-my-task-detail',
  templateUrl: './my-task-detail.component.html',
  styleUrls: ['./my-task-detail.component.scss']
})
export class MyTaskDetailComponent implements OnInit {
  public validationForm: FormGroup;
  responseError: any;
  entUserTaskValidation: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private userTaskData: UserTaskService) { }

  // User Task Variables
  public entUserTask: UserTask;

  ngOnInit(): void {
    this.entUserTask = new UserTask();

    this.validators();
    this.getData();
  }

  validators() {
    this.validationForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', null),
      date: new FormControl('', null),
      done: new FormControl('', null)
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.userTaskData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entUserTask = response;
        this.entUserTaskValidation = JSON.parse(JSON.stringify(response));

      });
    } else {
      this.entUserTask.id = 0;
      this.entUserTask.userId = jwt_decode(localStorage.getItem('currentUser')).userId;
    }
  }

  onSubmit() {
    this.responseError = '';
    var currentDate = new Date();

    if (this.validationForm.invalid) {
      return;
    }

    if ((this.entUserTask.id == 0 && this.entUserTask.dueDate != undefined && this.entUserTask.dueDate < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) || (this.entUserTask.id > 0 && this.entUserTask.dueDate != undefined && this.entUserTask.dueDate != this.entUserTaskValidation.dueDate && this.entUserTask.dueDate < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))) {        
      this.responseError = "The due date cannot be less than the current date."
      return;
    }

    this.save();
  }

  save() {
    if (this.entUserTask.id == 0) {
      this.userTaskData.add(this.entUserTask).subscribe(data => {

        this.redirect();
      }, error => this.responseError = error.error.message);
    } else {
      this.userTaskData.update(this.entUserTask).subscribe(data => {
        this.redirect();
      }, error => this.responseError = error.error.message);
    }
  }

  redirect() {
    SessionManager.SetSession('userTaskToast', '1');
    this.router.navigate(['/manager/mytask']);
  }

  cancelClick() {
    this.router.navigate(['/manager/mytask']);
  }

}
