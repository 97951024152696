<div class="container-fluid">
    <form [formGroup]="validationForm">
        <div class="row">
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Customer</mat-label>
                    <mat-select [(value)]="modelClient" (selectionChange)="onCustomerChange()" formControlName="client">
                        <mat-option *ngFor="let client of lstClient" [value]="client">{{ client.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Project</mat-label>
                    <mat-select [(value)]="modelProject" [disabled]="projectControl" formControlName="project" multiple>
                        <mat-option *ngFor="let project of lstProject" [value]="project">{{ project.projectName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="dp1" formControlName="startDate" [(ngModel)]="modelStartDate">
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="dp2" formControlName="endDate" [(ngModel)]="modelEndDate">
                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                    <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col" style="padding-top: 14px;">
                <button mat-flat-button color="primary" (click)="getData()">Search</button>
            </div>
        </div>
    </form>
</div>

<div id="print" *ngIf="lstProjectView.length > 0">
    <div class="row" style="padding-top: 20px; padding-bottom: 80px;">
        <div class="row">
            <div class="col-sm-4 img">
                <img src="../../../assets/images/atomosHylaLogo.svg" width="300px">
            </div>

            <div class="col-sm-8 content">
                <h2>{{ modelClient.name }}</h2>
                <div class="row" style="color: rgb(126, 134, 140);">
                    <div class="col-md-2 col-sm-3" style="display: grid; font-weight: 500; padding: 0;">
                        <label>Projects: </label>
                        <label>Date Range: </label>
                    </div>

                    <div class="col-md-10 col-sm-6" style="display: grid; padding: 0;">
                        <div>
                            <label *ngFor="let project of modelProject">{{ project.projectName
                                }}{{ modelProject.length == modelProject.indexOf(project) + 1 ? '' : ',' }}&nbsp;&nbsp;</label>
                        </div>
                        <label>{{ modelStartDate | date:'dd/MM/yyyy' }} - {{ modelEndDate | date:'dd/MM/yyyy' }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-11" style="height: 100%;">
            <mat-card>
                <h3>Project View</h3>

                <table *ngIf="lstProjectView">
                    <tr class="header">
                        <th>Name</th>
                        <th style="width: 11%;">Total Hours</th>
                        <th style="width: 11%;">Total Days</th>
                    </tr>

                    <tr *ngFor="let resource of lstProjectView">
                        <!-- Project -->
                        <td *ngIf="!resource.children" class="epic">{{ resource.name }}</td>
                        <td *ngIf="!resource.children" class="epic">{{ round(resource.totalHours, 2) }}</td>
                        <td *ngIf="!resource.children" class="epic">{{ round(resource.id, 0) }}</td>

                        <!-- User -->
                        <td *ngIf="resource.children" style="padding-left: 40px;">{{ resource.name }}</td>
                        <td *ngIf="resource.children">{{ round(resource.totalHours, 2) }}</td>
                        <td *ngIf="resource.children">{{ round((resource.totalHours / 8), 2) }}</td>
                    </tr>

                    <tr>
                        <td style="border-top: solid 1px lightgrey;"></td>
                        <td style="border-top: solid 1px lightgrey; font-weight: 500;">{{ totalHours }}</td>
                        <td style="border-top: solid 1px lightgrey; font-weight: 500;">{{ totalDays }}</td>
                    </tr> 
                </table>
            </mat-card>
        </div>
    </div>

    <div class="row" style="margin-top: 30px;">
        <div class="col-11">
            <mat-card>
                <h3>Resource View</h3>

                <table *ngIf="lstResourceView">
                    <tr class="header">
                        <th>Name</th>
                        <th>Total Hours</th>
                        <th style="width: 11%;">Total Days</th>
                    </tr>

                    <tr *ngFor="let resource of lstResourceView">
                        <td *ngIf="!resource.children" class="epic">{{ resource.name }}</td>
                        <td *ngIf="!resource.children" class="epic">{{ round(resource.totalHours, 2) }}</td>
                        <td *ngIf="!resource.children" class="epic">{{ round(resource.id, 1) }}</td>

                        <td *ngIf="resource.children" style="padding-left: 40px;">{{ resource.name }}</td>
                        <td *ngIf="resource.children">{{ round(resource.totalHours, 2) }}</td>
                        <td *ngIf="resource.children">{{ round((resource.totalHours / 8), 2) }}</td>
                    </tr>
                </table>
            </mat-card>
        </div>
    </div>
</div>