<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Employee</mat-label>
                        <mat-select formControlName="employee" [(ngModel)]="entTimesheet.userId"
                            (selectionChange)="onSelectUser()">
                            <mat-option *ngFor="let user of lstUser" [value]="user.id">{{ user.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Project</mat-label>
                        <mat-select formControlName="project" [(ngModel)]="entTimesheet.projectId">
                            <mat-option *ngFor="let project of lstProjectUsers" [value]="project.projectId">
                                {{ projectName(project.projectId) }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Hour Type</mat-label>
                        <mat-select formControlName="hourType" [(ngModel)]="entTimesheet.hourType"
                            (selectionChange)="onSelectHourType()">
                            <mat-option [value]="1">Normal</mat-option>
                            <mat-option [value]="3">Warning Hour</mat-option>
                            <mat-option [value]="5">License</mat-option>
                            <mat-option [value]="6">Vacation</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Activity Type</mat-label>
                        <mat-select formControlName="activityType" [(ngModel)]="entTimesheet.activityType"
                            [disabled]="entTimesheet.hourType == 6">
                            <mat-option [value]="1">Project Management</mat-option>
                            <mat-option [value]="2">Pre-Sales</mat-option>
                            <mat-option [value]="3">Design</mat-option>
                            <mat-option [value]="4">Development</mat-option>
                            <mat-option [value]="5">Internal Testing</mat-option>
                            <mat-option [value]="6">SAT</mat-option>
                            <mat-option [value]="7">Assited Operation</mat-option>
                            <mat-option [value]="8">Maintenance Support</mat-option>
                            <mat-option [value]="10">Meeting</mat-option>
                            <mat-option [value]="9">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Start Date</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="startDate" [(ngModel)]="modelStartDate">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>End Date</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="endDate" [(ngModel)]="modelEndDate">
                        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Registered Hours</mat-label>
                        <input matInput type="number" formControlName="registeredHours"
                            [(ngModel)]="entTimesheet.registeredHours">
                    </mat-form-field>
                </div>
                <div class="col col-lg-7 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Comment</mat-label>
                        <textarea matInput formControlName="comment" [(ngModel)]="entTimesheet.comment"></textarea>
                    </mat-form-field>
                </div>
                <div class="col col-lg-1 col-md-6 allowance">
                    <mat-label>Sold Vacation</mat-label>
                    <mat-slide-toggle formControlName="soldVacation" [(ngModel)]="modelSoldVacation"
                        [disabled]="entTimesheet.hourType != 6 || disabledVacation()"></mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()" style="float: right;">Save</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>