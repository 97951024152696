import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { PurchaseOrder } from '../models/purchaseOrder.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class PurchaseOrderService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.purchaseOrderEndPoint; //'https://localhost:44325/api/purchaseorder';

    getAll(page: number, search: string, active?: boolean, invoiceId?: number, projectId?: number) {
        var information = '';
        if (search == undefined) { search = ""; }
        if (active == undefined) { active == false; }
        if (invoiceId == undefined || invoiceId == null) { invoiceId = 0; }
        if (projectId == undefined) { projectId = 0; }

        return this.http.get<ResponseData>(this.URI + "?page=" + page + "&pageSize=" + AppSettings.pageSize + "&search=" + search + '&active=' + active + '&invoiceId=' + invoiceId + '&projectId=' + projectId).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<PurchaseOrder>(this.URI + '/' + Id).pipe(
            map(data => new PurchaseOrder().deserialize(data))
        );
    }

    getBalance(Id: number) {
        return this.http.get<number>(this.URI + '/GetPOBalance/' + Id);
    }

    add(entPurchaseOrder: PurchaseOrder) {
        return this.http.post<PurchaseOrder>(this.URI, entPurchaseOrder);
    }

    update(entPurchaseOrder: PurchaseOrder) {
        return this.http.put<PurchaseOrder>(this.URI + '/' + entPurchaseOrder.id, entPurchaseOrder);
    }

    delete(Id: number) {
        return this.http.delete<PurchaseOrder>(this.URI + '/' + Id);
    }
}