import { Deserializable } from './deserializable.model';

// export class Location.Model {
// }

export class Location implements Deserializable {
  public id: number;
  public locationName: string;
  public address: string;
  public number: number;
  public complement: string;
  public city: string;
  public state: string;
  public zipCode: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
