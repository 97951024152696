import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../utils/settings';

import { User } from '../models/user.model';
import { ResponseData } from '../models/responseData.model';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }
    public URI = AppSettings.baseURL + AppSettings.userEndPoint; //'https://localhost:44325/api/user';

    getAll(page: number, search: string, active?: boolean) {
        if (search == undefined) { search = ""; }
        if (active == undefined || active == null) { active = false; }

        return this.http.get<ResponseData>(this.URI + "?page=" + page + "&pageSize=" + AppSettings.pageSize + "&search="+ search + '&active=' + active).pipe(
            map(data => new ResponseData().deserialize(data))
        );
    }

    getById(Id: number) {
        return this.http.get<User>(this.URI + '/' + Id).pipe(
            map(data => new User().deserialize(data))
        );
    }

    add(entUser: User) {
        entUser.password = AppSettings.Encrypt(entUser.password);
        return this.http.post<User>(this.URI, entUser);
    }

    update(entUser: User) {
        if (entUser.password.length > 0 )
        {
            entUser.password = AppSettings.Encrypt(entUser.password);
        }
        return this.http.put<User>(this.URI + '/' + entUser.id, entUser);
    }

    delete(Id: number) {
        return this.http.delete<User>(this.URI + '/' + Id);
    }
}
