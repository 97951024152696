import { Deserializable } from './deserializable.model';

export class FileStorage implements Deserializable {
  public id: number;
  public registerType: number;
  public referenceId: number;
  public file: Blob;
  public fileName: string;
  public fileMime: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
