<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body" *ngIf="lstLocation">
        <form [formGroup]="validationForm">
            <mat-tab-group>
                <mat-tab label="User">
                    <div class="row" style="padding-top: 20px;">
                        <div class="col col-lg-4 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Full Name</mat-label>
                                <input matInput formControlName="name" [(ngModel)]="entUser.name">
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-4 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput type="email" formControlName="email" [(ngModel)]="entUser.email">
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-4 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput type="password" formControlName="password"
                                    [(ngModel)]="entUser.password">
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-3 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Location</mat-label>
                                <mat-select formControlName="locationId" [(ngModel)]="entUser.locationId">
                                    <mat-option *ngFor="let project of lstLocation" [value]="project.id">
                                        {{ project.locationName }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-3 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Weekly Working Hours</mat-label>
                                <input matInput type="number" formControlName="workingHours"
                                    [(ngModel)]="entUser.workingHours">
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-3 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Birth Date</mat-label>
                                <input matInput [matDatepicker]="dp1" formControlName="birthDate"
                                    [(ngModel)]="entUser.birthDate">
                                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                                <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-3 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Admission Date</mat-label>
                                <input matInput [matDatepicker]="dp2" formControlName="admissionDate"
                                    [(ngModel)]="entUser.admissionDate">
                                <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                                <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-4 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Dismiss Date</mat-label>
                                <input matInput [matDatepicker]="dp3" formControlName="dismissDate"
                                    [(ngModel)]="entUser.dismissDate">
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-4 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="type" [(ngModel)]="entUser.type">
                                    <mat-option [value]="1">Employee</mat-option>
                                    <mat-option [value]="2">Contributor</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col col-lg-4 col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Role</mat-label>
                                <mat-select formControlName="role" [(ngModel)]="entUser.role">
                                    <mat-option [value]="1">Administrator</mat-option>
                                    <mat-option [value]="2">Coordinator</mat-option>
                                    <mat-option [value]="3">User</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="error" *ngIf="responseError">
                                <mat-label>{{ responseError }}</mat-label>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Vacation" [disabled]="entUser.id == 0">
                    <div class="col-12" style="padding-bottom: 20px;">
                        <h2>Admission Date: <label>{{ entUser.admissionDate | date: 'dd/MM/yyyy' }}</label></h2>
                        <mat-table [dataSource]="lstVacationControl">

                            <!-- Admission Date
                            <ng-container matColumnDef="Admission Date">
                                <mat-header-cell *matHeaderCellDef>
                                    Admission Date
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{ entUser.admissionDate | date: 'dd/MM/yyyy' }}
                                </mat-cell>
                            </ng-container> -->

                            <!--Columns-->
                            <ng-container *ngFor="let col of colNames" [matColumnDef]="col.name">
                                <mat-header-cell *matHeaderCellDef>{{ col.name }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{ col.pipe == "date" ? (element[col.prop] | date: 'dd/MM/yyyy') : col.pipe ==
                                    "weekday" ? (element[col.prop] | weekday) : element[col.prop] }}
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()">Save</button>
                    <button mat-button (click)="cancelClick()">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>