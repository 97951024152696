import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';

// Session Storage
import { SessionManager } from '../../../utils/session-manager';

// Timesheet
import { Timesheet } from '../../../models/timesheet.model';
import { TimesheetService } from 'src/app/services/timesheet.service';

// Project
import { Project } from '../../../models/project.model';
import { ProjectService } from 'src/app/services/project.service';

// Project Users
import { ProjectUsers } from '../../../models/projectUsers.model';
import { ProjectUsersService } from 'src/app/services/projectUsers.service';

@Component({
  selector: 'app-timesheet-detail',
  templateUrl: './timesheet-detail.component.html',
  styleUrls: ['./timesheet-detail.component.scss']
})
export class TimesheetDetailComponent implements OnInit {
  public validationForm: FormGroup;
  responseError: any;
  commentValidation: boolean = false;
  userName: string;
  returnUrl: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private timesheetData: TimesheetService,
    private projectData: ProjectService,
    private projectUsersData: ProjectUsersService) { }

  // Timesheet Variables
  public entTimesheet: Timesheet;

  // Project Variables
  public lstProject: Project[];
  public lstProjectUsers: ProjectUsers[];

  ngOnInit(): void {
    this.entTimesheet = new Timesheet();
    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    var entUser = jwt_decode(localStorage.getItem('currentUser'));
    this.entTimesheet.userId = parseInt(entUser.userId);
    this.userName = entUser.unique_name;

    this.getData();
    this.validators();
  }

  validators() {
    this.validationForm = new FormGroup({
      employee: new FormControl('', [Validators.required]),
      project: new FormControl('', [Validators.required]),
      registeredDate: new FormControl('', [Validators.required]),
      registeredHours: new FormControl('', [Validators.required]),
      activityType: new FormControl('', [Validators.required]),
      hourType: new FormControl('', [Validators.required]),
      comment: new FormControl('', null)
    });
  }

  getData() {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.timesheetData.getById(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(response => {
        this.entTimesheet = response;

      });
    } else {
      this.entTimesheet.id = 0;

      if (this.route.snapshot.paramMap.get('date') != "undefined") {
        this.entTimesheet.registeredDate = new Date(this.route.snapshot.paramMap.get('date'));
      } else {
        this.entTimesheet.registeredDate = new Date();
      }
    }

    this.projectData.getAll(-1, '', false, this.entTimesheet.userId).subscribe(response => {
      this.lstProject = response.data.map(data => new Project().deserialize(data));

      this.projectUsersData.getAll(0, this.entTimesheet.userId).subscribe(response => {
        if (response.length > 0) {
          this.lstProjectUsers = response.filter(x => x.project.active == true);
        } else {
          this.lstProjectUsers = [];
        }
      });
    });
  }

  projectName(projectId: number) {
    return this.lstProject.find(x => x.id == projectId).projectName;
  }

  onSubmit() {
    this.commentValidation = false;
    var registerHoursValidation = this.entTimesheet.registeredHours / 0.5;

    if (this.validationForm.invalid) {
      return;
    }

    if (!Number.isInteger(registerHoursValidation)) {
      this.responseError = "It is not possible to register this fraction of hour. The only fraction accepted is .5";
      return;
    }

    if ((this.entTimesheet.hourType == 2 && this.entTimesheet.comment == undefined) || (this.entTimesheet.hourType == 2 && this.entTimesheet.comment == '') || (this.entTimesheet.hourType == 5 && this.entTimesheet.comment == undefined) || (this.entTimesheet.hourType == 5 && this.entTimesheet.comment == '')) {
      this.responseError = "Comment is required if hour type equal to extra hour or license";
      return;
    }

    this.save();
  }

  save() {
    if (this.entTimesheet.id == 0) { // Add
      this.timesheetData.add(this.entTimesheet).subscribe(data => {

        this.redirect();
      }, error => this.responseError = error.error.message);
    } else { // Edit
      this.timesheetData.update(this.entTimesheet).subscribe(data => {
        this.redirect();
      }, error => this.responseError = error.error.message);
    }
  }

  redirect() {
    // SessionManager.SetSession('timesheetToast', '1');
    // this.router.navigate(['/manager/timesheet']);
    this.router.navigate([this.returnUrl]);
  }
  
  cancelClick() {
    this.router.navigate([this.returnUrl]);
  }

}
