<div style="padding-right: 1.5rem; padding-left: 1.5rem;">
    <mat-card class="card-body" *ngIf="entInvoice.id != 0 ? lstProject : lstClient">
        <form [formGroup]="validationForm">
            <div class="row">
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Customer</mat-label>
                        <mat-select formControlName="clientId" [(ngModel)]="modelClient"
                            (selectionChange)="onCustomerChange()">
                            <mat-option *ngFor="let client of lstClient" [value]="client.id">{{ client.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Project</mat-label>
                        <mat-select formControlName="projectId" [(ngModel)]="modelProject"
                            (selectionChange)="onProjectChange()">
                            <mat-option *ngFor="let Project of lstProject" [value]="Project.id">
                                {{ Project.projectName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Purchase Order Number</mat-label>
                        <mat-select formControlName="poId" [(ngModel)]="entInvoice.poid">
                            <mat-option *ngFor="let purchase of lstPurchaseOrder" [value]="purchase.id">
                                {{ purchase.number }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Invoice Number</mat-label>
                        <input matInput type="number" formControlName="number" [(ngModel)]="entInvoice.number">
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Emission Date</mat-label>
                        <input matInput [matDatepicker]="dp1" formControlName="emissionDate"
                            [(ngModel)]="entInvoice.emissionDate">
                        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                        <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Invoice Type</mat-label>
                        <mat-select formControlName="invoiceType" [(ngModel)]="entInvoice.invoiceType">
                            <mat-option [value]="1">Service</mat-option>
                            <mat-option [value]="2">Debit</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Total Value</mat-label>
                        <input matInput type="number" formControlName="totalValue" [(ngModel)]="entInvoice.totalValue">
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Net Value</mat-label>
                        <input matInput type="number" formControlName="netValue" [(ngModel)]="entInvoice.netValue">
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Received Forecast</mat-label>
                        <input matInput [matDatepicker]="dp2" formControlName="receivedForecast"
                            [(ngModel)]="entInvoice.receivedForecast">
                        <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                        <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Received Date</mat-label>
                        <input matInput [matDatepicker]="dp3" formControlName="receivedDate"
                            [(ngModel)]="entInvoice.receivedDate">
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-lg-4 col-md-6" style="padding-top: .5rem;" *ngIf="entFileStorage.id > 0">
                    <button mat-raised-button (click)="downloadClick()" style="background-color:#ffcc00; color: #fff;">
                        <mat-icon>cloud_download</mat-icon> View Purchase Order File
                    </button>
                </div>
                <div class="col col-lg-4 col-md-6" style="padding-top: 15px;" *ngIf="userType == 1">
                    <button mat-button class="addFile" (click)="file.click()">
                        <mat-icon>file_upload</mat-icon>
                        Choose Files
                    </button>
                    <div style="height: 36px; padding-top: 10px; margin-left: 132px;">
                        <label id="fileName"></label>
                    </div>
                    <input hidden #file type="file" class="" multiple (change)="upload(file.files)"
                        accept="application/pdf">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="error" *ngIf="responseError">
                        <mat-label>{{ responseError }}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" (click)="onSubmit()" *ngIf="userType == 1">Save</button>
                    <button mat-button (click)="cancelClick()">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>