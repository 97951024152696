import {Deserializable} from './deserializable.model';

export class TimesheetBalance implements Deserializable {
    public id: number;
    public userId: number;
    public month: number;
    public year: number;
    public hoursNormal: number;
    public hoursExtra: number;
    public hoursWarning: number;
    public hoursBasket: number;
    public hoursLicense: number;
    public hoursVacation: number;
    public hoursExtraPrevious: number;
    public hoursExtraTotal: number;
    public workingHours: number;

    deserialize(input: any): this {
      return Object.assign(this, input);
    }
  }
