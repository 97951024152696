import { Deserializable } from './deserializable.model';

export class Supplier implements Deserializable {
  public id: number;
  public name: string;
  public fiscalName: string;
  public taxNumber: string;
  public municipalTaxNumber: string;
  public stateTaxNumber: string;
  public address: string;
  public number: number;
  public complement: string;
  public city: string;
  public state: string;
  public zipCode: string;
  public email: string;
  public contact: string;
  public phone: string;
  public observation: string;

deserialize(input: any): this {
  return Object.assign(this, input);
}
}
