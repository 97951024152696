import * as CryptoJS from 'crypto-js';
import { MatSnackBar } from '@angular/material/snack-bar';

export class AppSettings {
  public static get baseURL(): string { return "https://www.atomoshyla.com.br/api/api"; }
  //  public static get baseURL(): string { return "https://localhost:44325/api"; }
  // public static get baseURL(): string { return "https://localhost:44361/api"; }
  //public static get baseURL(): string { return "https://localhost:44338/api"; }

  public static get billEndPoint(): string { return "/bill"; }
  public static get clientEndPoint(): string { return "/client"; }
  public static get epicEndPoint(): string { return "/epic"; }
  public static get fileManagementEndPoint(): string { return "/filemanagement" }
  public static get fileStorageEndPoint(): string { return "/filestorage" }
  public static get holidaysEndPoint(): string { return "/holidays" }
  public static get historyEndPoint(): string { return "/history" }
  public static get invoiceEndPoint(): string { return "/invoice"; }
  public static get issueEndPoint(): string { return "/issue"; }
  public static get locationEndPoint(): string { return "/location"; }
  public static get notificationEndPoint(): string { return "/notification"; }
  public static get projectEndPoint(): string { return "/project"; }
  public static get projectUsersEndPoint(): string { return "/projectusers"; }
  public static get purchaseOrderEndPoint(): string { return "/purchaseorder"; }
  public static get sprintEndPoint(): string { return "/sprint"; }
  public static get supplierEndPoint(): string { return "/supplier"; }
  public static get timesheetEndPoint(): string { return "/timesheet"; }
  public static get timesheetBalanceEndPoint(): string { return "/timesheetbalance"; }
  public static get userEndPoint(): string { return "/user"; }
  public static get userTaskEndPoint(): string { return "/usertask"; }
  public static get vacationControlEndPoint(): string { return "/vacationcontrol"; }

  public static get pageSize(): number { return 25; }

  // Toast Colors
  public static get Toast_Color_Success(): string { return 'lightgray-bg' }
  public static get Toast_Color_Error(): string { return 'lightorange-bg' }

  //'@tomo$Hyla55'
  public static get encryptKey(): string { return "@tomo$Hyla55Bras"; }

  /**
   * Project Activities Validation
   */
  public static activitiesStatus: any[] = [{
    currentStatus: 0,
    nextStatus: [1]
  },
  {
    currentStatus: 1,
    nextStatus: [0, 2]
  },
  {
    currentStatus: 2,
    nextStatus: [1, 3, 4]
  },
  {
    currentStatus: 3,
    nextStatus: [1, 2]
  },
  {
    currentStatus: 4,
    nextStatus: [2]
  }];

  /**
   * Encrypt
   * @param value
   */
  public static Encrypt(value: string): string {
    var key = CryptoJS.enc.Utf8.parse(AppSettings.encryptKey);
    var iv = CryptoJS.enc.Utf8.parse(AppSettings.encryptKey);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  /**
   * SnackBar
   * @param _snackBar
   * @param message
   * @param duration
   * @param color
   */
  public static openSnackBar(_snackBar: MatSnackBar, message: string, duration: number, color: string) {
    _snackBar.open(message, 'close', {
      duration: duration * 1000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: [color]
    });
  }

  /**
   * View File
   * @param fileData
   * @param HttpEventType
   * @param registerType
   * @param entity
   */
  public static viewFileById(fileData: any, HttpEventType: any, id: number) {
    fileData.viewFileById(id).subscribe(data => {
      switch (data.type) {
        case HttpEventType.Response:
          const downloadedFile = new Blob([data.body], { type: data.body.type });
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
          break;
      }
    });
  }

  /**
   *
   * @param registerType 1: Purchase Order, 2: Invoice, 3: Issue, 4: Bill
   */
  public static viewFirstFile(fileData: any, HttpEventType: any, registerType: number, entity: any) {
    /** registerType
     * 1 = Purchase Order
     * 2 = Invoice
     * 3 = Issue
     * 4 = Bill
     */
    fileData.viewFirstFile(registerType, entity.id).subscribe(data => {
      switch (data.type) {
        case HttpEventType.Response:
          const downloadedFile = new Blob([data.body], { type: data.body.type });
          var url = URL.createObjectURL(downloadedFile);
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.href = url;
          a.download = data.headers.get('FileName');
          a.target = '_blank';
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          break;
      }
    });
  }
}
