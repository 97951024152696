<div style="background: #fff; height: 100%;">
    <div class="profile-info bg-primary-gray">
        <div class="row" *ngIf="userName">
            <div class="col-12" style="text-align: center; padding: 0px;">
                <h3>{{ userName }}</h3>
            </div>
            <div class="col-12" style="text-align: center; padding: 0px;">
                <a [routerLink]="['/manager/profile']">Edit Profile</a>
            </div>
        </div>
        <div class="logo">
            <img alt="Atomos Hyla Logo" src="../../../../assets/images/logo-sm.png">
        </div>
    </div>
    <mat-accordion>
        <mat-nav-list *ngIf="userType == 1">
            <mat-list-item [routerLink]="['/manager/dashboard']">
                <mat-icon mat-list-icon class="sidenav-subheader-icon">home</mat-icon>Dashboard
            </mat-list-item>
            <mat-list-item [routerLink]="['/manager/mytask']">
                <mat-icon mat-list-icon class="sidenav-subheader-icon">list_alt</mat-icon>My Tasks
                <!-- 
                    content_paste
                    list_alt
                    checklist
                 -->
            </mat-list-item>
        </mat-nav-list>
        <mat-expansion-panel *ngIf="userType == 1">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon mat-list-icon class="sidenav-subheader-icon">date_range</mat-icon>Timesheet
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <mat-list>
                    <mat-list-item [routerLink]="['/manager/timesheet']">Timesheet</mat-list-item>
                    <mat-list-item [routerLink]="['/manager/bulkadd']" *ngIf="userRole == 1 || userRole == 2">Bulk Add
                    </mat-list-item>
                    <mat-list-item [routerLink]="['/manager/monthlyclosure']" *ngIf="userRole == 1 || userRole == 2">
                        Monthly
                        Closure</mat-list-item>
                    <mat-list-item [routerLink]="['/manager/monthreport']">Month
                        Report</mat-list-item>
                    <mat-list-item [routerLink]="['/manager/annualreport']">Annual Report</mat-list-item>
                </mat-list>
            </mat-nav-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon mat-list-icon class="sidenav-subheader-icon">design_services</mat-icon>Project
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <mat-list>
                    <mat-list-item [routerLink]="['/manager/project']">Project</mat-list-item>
                    <mat-list-item [routerLink]="['/manager/resourceeffort']" *ngIf="userRole == 1 || userRole == 2">
                        Resource Effort</mat-list-item>
                </mat-list>
            </mat-nav-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header *ngIf="userRole == 1 || userType == 2">
                <mat-panel-title>
                    <mat-icon mat-list-icon class="sidenav-subheader-icon">account_balance_wallet</mat-icon>Accounting
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <mat-list>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header style="padding-left: 48px;" *ngIf="userRole == 1 || userType == 2">
                            <mat-panel-title>Receivable</mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-nav-list style="padding-left: 17px;">
                            <mat-list>
                                <mat-list-item [routerLink]="['/manager/invoice']"
                                    *ngIf="userRole == 1 || userType == 2">
                                    Invoice
                                </mat-list-item>
                                <mat-list-item [routerLink]="['/manager/purchaseorder']"
                                    *ngIf="userRole == 1 || userType == 2">Purchase
                                    Order</mat-list-item>
                                <mat-list-item [routerLink]="['/manager/customer']"
                                    *ngIf="userRole == 1 || userType == 2">
                                    Customer
                                </mat-list-item>
                                <!-- <mat-list-item [routerLink]="['/manager/supplier']" *ngIf="userRole == 1 || userType == 2">
                                Supplier
                            </mat-list-item> -->
                            </mat-list>
                        </mat-nav-list>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header style="padding-left: 48px;" *ngIf="userRole == 1 || userType == 2">
                            <mat-panel-title>Payable</mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-nav-list style="padding-left: 35px;">
                            <mat-list>
                                <mat-list-item [routerLink]="['/manager/bill']" *ngIf="userRole == 1 || userType == 2">
                                    Bill
                                </mat-list-item>
                                <mat-list-item [routerLink]="['/manager/supplier']"
                                    *ngIf="userRole == 1 || userType == 2">
                                    Supplier
                                </mat-list-item>
                            </mat-list>
                        </mat-nav-list>
                    </mat-expansion-panel>
                </mat-list>
            </mat-nav-list>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="userType == 1 && (userRole == 1 || userRole == 2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon mat-list-icon class="sidenav-subheader-icon">settings</mat-icon>Settings
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <mat-list>
                    <mat-list-item [routerLink]="['/manager/location']">Location</mat-list-item>
                    <mat-list-item [routerLink]="['/manager/holidays']">Holidays</mat-list-item>
                    <mat-list-item [routerLink]="['/manager/user']" *ngIf="userRole == 1">User</mat-list-item>
                    <mat-list-item [routerLink]="['/manager/notification']" *ngIf="userRole == 1">Notification
                    </mat-list-item>
                </mat-list>
            </mat-nav-list>
        </mat-expansion-panel>
        <mat-nav-list>
            <mat-list-item [routerLink]="['/manager/login']">
                <mat-icon mat-list-icon class="sidenav-subheader-icon" (click)="logOut()">login
                </mat-icon>Logout
            </mat-list-item>
        </mat-nav-list>
    </mat-accordion>
</div>