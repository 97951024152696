import { Component, OnInit } from '@angular/core';

import { Client } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';

import { Project } from 'src/app/models/project.model';
import { ProjectService } from 'src/app/services/project.service';

import { TimesheetService } from 'src/app/services/timesheet.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-resource-effort',
  templateUrl: './resource-effort.component.html',
  styleUrls: ['./resource-effort.component.scss']
})
export class ResourceEffortComponent implements OnInit {
  public validationForm: FormGroup;

  modelClient: Client;
  modelProject: Project[];
  modelStartDate: Date;
  modelEndDate: Date;

  projectControl: boolean = true;

  totalHours: number = 0;
  totalDays: number = 0;

  constructor(private clientData: ClientService,
    private projectData: ProjectService,
    private timesheetData: TimesheetService) { }

  public lstClient: Client[];
  public lstProject: Project[];

  lstProjectView = [];
  lstResourceView = [];

  ngOnInit(): void {
    this.clientData.getAll(0, '').subscribe(response => {
      this.lstClient = response.data.map(data => new Client().deserialize(data));

    });

    this.Validators();
  }

  Validators() {
    this.validationForm = new FormGroup({
      client: new FormControl('', [Validators.required]),
      project: new FormControl('', [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
    });
  }

  onCustomerChange() {
    this.projectData.getAll(0, '', false, 0, this.modelClient.id).subscribe(response => {
      this.lstProject = response.data.map(data => new Project().deserialize(data));

      this.projectControl = false;
    });
  }

  getData() {
    this.lstProjectView = [];
    this.lstResourceView = [];
    this.totalHours = 0;
    this.totalDays = 0;

    this.modelProject.forEach(entProject => {
      this.timesheetData.getAllByProjectUser(entProject.id, 0, this.modelStartDate, this.modelEndDate).subscribe(lstTimesheet => {
        var totalHours = 0;

        lstTimesheet.forEach(entTimesheet => {
          totalHours += entTimesheet.registeredHours;

          // Project View
          if (this.lstProjectView.find(x => !x.children && x.id == entProject.id) == undefined) { // Add Project
            this.lstProjectView.push({ id: entProject.id, children: false, name: entProject.projectName, totalHours: entTimesheet.registeredHours });
          } else { // Sum hours
            this.lstProjectView.find(x => !x.children && x.id == entProject.id).totalHours += entTimesheet.registeredHours;
          }

          if (this.lstProjectView.find(x => x.children && x.parentId == entProject.id && x.name == entTimesheet.user.name) == undefined) { // Add user
            this.lstProjectView.splice(this.lstProjectView.indexOf(this.lstProjectView.find(x => !x.children && x.id == entProject.id)) + 1, 0, { children: true, name: entTimesheet.user.name, totalHours: entTimesheet.registeredHours, parentId: entTimesheet.projectId });
          } else { // Sum hours
            this.lstProjectView.find(x => x.children && x.parentId == entProject.id && x.name == entTimesheet.user.name).totalHours += entTimesheet.registeredHours;
          }


          // Resource View
          if (this.lstResourceView.find(x => !x.children && x.id == entTimesheet.userId) == undefined) { // Add user
            this.lstResourceView.push({ id: entTimesheet.userId, children: false, name: entTimesheet.user.name, totalHours: entTimesheet.registeredHours });
          } else { // Sum hours
            this.lstResourceView.find(x => !x.children && x.id == entTimesheet.userId).totalHours += entTimesheet.registeredHours;
          }

          if (this.lstResourceView.find(x => x.children && x.parentId == entTimesheet.userId && x.name == entProject.projectName) == undefined) { // Add project
            this.lstResourceView.splice(this.lstResourceView.indexOf(this.lstResourceView.find(x => !x.children && x.id == entTimesheet.userId)) + 1, 0, { children: true, name: entProject.projectName, totalHours: entTimesheet.registeredHours, parentId: entTimesheet.userId });
          } else { // Sum hours
            this.lstResourceView.find(x => x.children && x.parentId == entTimesheet.userId && x.name == entProject.projectName).totalHours += entTimesheet.registeredHours;
          }

        });

        for (let i = 0; i < this.lstProjectView.length; i++) {
          const element = this.lstProjectView[i];

          if (!element.children && element.id == entProject.id) {
            this.totalHours += Math.ceil(totalHours);
            this.totalDays += Math.ceil(totalHours/8);
            break;
          }
        }
      });
    });
  }

  round(value: number, type: number) {
    var information = 0;
    if (type == 0) {
      this.lstProjectView.filter(x => x.children && x.parentId == value).forEach(x => information += Math.ceil(x.totalHours/8));
    } else if (type == 1) {
      this.lstResourceView.filter(x => x.children && x.parentId == value).forEach(x => information += Math.ceil(x.totalHours/8));
    } else {
      information = Math.ceil(value);
    }
    return information;
  }
}
